<template>
  <div>
    <a-modal :getContainer='getContainer' :zIndex='1001' :maskClosable='false' :visible="show" width='1070px' title="选择图片" @ok="handleOk" @cancel='handleCancel'>
      <a-spin :spinning='loading'>
        <div class='modal-content'>
          <div class='tab-box'>
            <div class='group-title'>
              <div>分组</div>
              <div class='search-box'>
                <a-input placeholder="搜索分组" v-model="name" />
                <a-button type='link' @click='onSearch' icon='search'></a-button>
                <a-button type='link' @click='reset' icon='sync'></a-button>
              </div>
            </div>

            <div class='tab-item' :class='{active:currentGroupId === item.id}' v-for='item in groupList' :key='item.id' @click='changeTab(item.id,item.officeFlag)'>{{item.name}}</div>
          </div>
          <div style='flex:1;margin-left:16px;' class='flex-column'>
            <div class='flex-a-c' style='justify-content: flex-end;'>
              <a-button type='primary' @click='$router.push("/content/resource")'>图片管理</a-button>
              <a-button type='link' @click="init()">刷新</a-button>
            </div>
            <div class='img-box' v-viewer v-if='list.length'>
              <div v-for='(img) in list' :key='img.shopMaterialId' class='img-item'>
                <base-img width='120' :src='img.picPath' class='img'></base-img>
                <a-checkbox class='img-name' @change="onSelectImg($event,img)" :checked="selectImgData.findIndex(x=>x === img.picPath) !== -1">
                  {{img.name}}
                </a-checkbox>
              </div>
            </div>
            <div v-else style='margin-top: 30px'>
              <a-empty description='暂无图片素材' />
            </div>
            <div class='pagination-box'>
              <div class='flex-a-c'>
                <div class='select-num-box'>已选<span>{{selectImgData.length}}</span>个</div>
                <div class='max-num-box' v-if='multiple'>，最多可选<span>{{max}}</span>个</div>
              </div>
              <a-pagination size="small" v-model="current" :pageSize='pageSize' :total="total" @change='changePage' @showSizeChange="onShowSizeChange" />
            </div>
          </div>
        </div>

      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { shop } from '@/api'
const deepClone = (obj) => {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // 判断obj子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          // 如果不是，简单复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
export default {
  name: 'selectImgDialog',
  props: {
    show: {
      type: Boolean,
      default () {
        return false
      }
    },
    initSelectData: {
      type: Array,
      default () {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default () {
        return false
      }
    },
    max: {
      type: Number,
      default () {
        return 9
      }
    },
    idName: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      currentGroupId: '',
      current: 1,
      pageSize: 12,
      total: 0,
      list: [],
      groupList: [],
      selectImgData: [],
      officeFlag: false,
      name: ''
    }
  },
  watch: {
    initSelectData: {
      handler (val) {
        this.selectImgData = deepClone(val)
      },
      immediate: true,
      deep: true
    }
  },
  async mounted () {
    this.loading = true
    await this.init()
    this.selectImgData = Object.assign([], this.initSelectData)
    this.loading = false
  },
  methods: {
    getContainer () {
      return document.getElementById(this.idName)
    },
    handleCancel () {
      this.$emit('change', this.initSelectData)
    },
    handleOk () {
      this.$emit('change', this.selectImgData)
    },
    async init () {
      this.name = ''
      this.current = 1
      await this.getGroup()
      if (this.currentGroupId) {
        this.getResourcePage()
      }
    },
    async reset () {
      this.name = ''
      this.current = 1
      this.currentGroupId = ''
      await this.getGroup()
      if (this.currentGroupId) {
        this.getResourcePage()
      }
    },
    async onSearch () {
      this.current = 1
      this.currentGroupId = ''
      await this.getGroup()
      if (this.currentGroupId) {
        this.getResourcePage()
      }
    },
    changePage (e) {
      this.current = e
      this.getResourcePage()
    },
    onShowSizeChange (current, pageSize) {
      this.current = current
      this.pageSize = pageSize
      this.getResourcePage()
    },
    async getResourcePage () {
      this.loading = true
      const { data, msg, code, page } = await shop.getResourcePage({
        current: this.current,
        size: this.pageSize,
        groupId: this.currentGroupId,
        officeFlag: this.officeFlag
      })
      this.loading = false
      if (code === '00000') {
        this.list = data
        this.total = page.total
        this.current = page.pageNo
        this.pageSize = page.pageSize
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    async getGroup () {
      const { data, msg, code } = await shop.getSourceGroup({
        type: 0,
        name: this.name
      })
      if (code === '00000') {
        this.groupList = data
        if (!this.currentGroupId && data.length) {
          this.currentGroupId = data[0].id
          this.officeFlag = data[0].officeFlag
        }
      } else {
        this.$message.error(msg || '获取分组数据失败')
      }
    },
    changeTab (id, officeFlag) {
      this.currentGroupId = id
      this.officeFlag = officeFlag
      this.current = 1
      this.getResourcePage()
    },
    onSelectImg (e, obj) {
      if (this.multiple) {
        const index = this.selectImgData.findIndex((x) => x === obj.picPath)
        // 选中
        if (e.target.checked) {
          // 不存在  --> 插入
          if (this.selectImgData.length >= this.max) {
            return this.$message.error(`最多不得超过${this.max}个`)
          }
          this.selectImgData.push(obj.picPath)
        } else {
          this.selectImgData.splice(index, 1)
        }
      } else {
        this.selectImgData = [obj.picPath]
      }
    }
  }
}
</script>

<style lang="less" scoped>
.modal-content {
  display: flex;
}
.group-title {
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 12px;
  color: #000;
  position: sticky;
  top: 0;
  background: #fff;
}
.tab-box {
  width: 200px;
  max-height: 400px;
  padding: 0 12px;
  overflow: auto;
  border-right: 1px solid #ddd;
  .tab-item {
    line-height: 28px;
    border-radius: 4px;
    color: #666;
    cursor: pointer;
    overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    &.active {
      color: @main-color;
    }
    &:hover {
      color: @main-color;
    }
  }
}
.pagination-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .select-num-box {
    padding-left: 6px;
    span {
      color: @main-color;
      font-weight: 500;
      padding: 4px;
      font-size: 18px;
    }
  }
  .max-num-box {
    span {
      color: @main-color;
      font-weight: 500;
      padding: 4px;
      font-size: 18px;
    }
  }
}
.img-box {
  margin: 24px 0;
  display: flex;
  flex-wrap: wrap;
  flex:1;
  .img-item {
    position: relative;
    margin: 0px 6px 12px;
    width: 120px;
    .img-name {
      font-size: 12px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      cursor: pointer;
      margin-top: 4px;
    }

    .img {
      width: 120px;
      height: 120px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      ::v-deep img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.viewer-image {
  display: none;
}
.flex-a-c {
  display: flex;
  align-items: center;
}
.flex-j-b {
  display: flex;
  justify-content: center;
}
.search-box {
  display: flex;
  align-items: center;
  margin-top: 12px;
  button {
    padding: 0;
    margin-left: 4px;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
