<template>
  <div class='select-shop-page'>
    <app-header/>
    <div class='page-content'>
      <div class='head-row'>
        <div class='user-info'>
          <img v-if="!info.headImg" src="@/assets/default-img.png" class='head-img' alt="">
          <div>
            <div>{{info.nickName}}</div>
            <!-- <div>{{info.loginName}}</div> -->
          </div>
        </div>
        <a-button type="primary" @click='createShop'>
          创建店铺
        </a-button>
      </div>
      <div class='shop-list'>
        <!-- <div class='search-box'>
          <a-input style="width:260px" ref="userNameInput" v-model="search" placeholder="搜索店铺">
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button type="link" @click='searchFn'>
            搜索
          </a-button>
        </div> -->
        <div class='list' v-if="loadSuccess && list.length">
          <div class='item' v-for="(item,index) in list" :key="item.shopId + index" @click.prevent='selectShop(item)'>
            <div class='item-name'>{{item.name}}</div>
            <div class='item-subject'>主体信息：{{item.authType?item.authType==="1"?"企业":item.authType==="2"?"个人":"个体工商户":"未认证"}}</div>
            <div class='item-date'>创建时间：{{item.createTime}}</div>
            <div class='btn-box'>
              <div class='auth-status' :style="{color:shopAuthStates.find(value=>value.value === item.authState).color,borderColor:shopAuthStates.find(value=>value.value === item.authState).color}">
                {{shopAuthStates.find(value=>value.value === item.authState).name}}
              </div>
              <a-button type="link" @click.stop='changeShop(item.shopId)'>修改</a-button>
            </div>
          </div>
        </div>
        <no-data style='margin-top:84px' v-if="loadSuccess && list.length === 0" text="暂无店铺"></no-data>
      </div>
    </div>
  </div>
</template>
<script>
import { user } from '@/api'
import AppHeader from '../views/layout/AppHeader'
import NoData from '@/components/NoData'
import { SHOPID, SHOPAUTHSTATE, SHOPUSERID, SHOPSTAFFID, SHOPROLEID } from '@/constants'
import { setSession, getSession } from '@/utils/session'
import { shopAuthStates } from '@/utils/constants'
import router from '@/router'
import { checkIsPlatform } from '../utils/tools'
export default {
  name: 'SelectShop',
  components: {
    AppHeader,
    NoData
  },
  data: () => ({
    shopAuthStates,
    search: '',
    loadSuccess: false,
    list: [],
    info: {}
  }),
  mounted () {
    this.getData()
    this.getUserInfo()
  },
  methods: {
    async getUserInfo () {
      let { code, data, msg } = await user.getUserInfo(
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {
            userId: getSession(SHOPUSERID)
          }
        }
      )
      if (code === '00000') {
        this.info = data
      } else {
        this.$message.error(msg || '获取用户信息失败')
      }
    },
    async getData () {
      let { code, msg, data } = await user.getMyShopList()
      if (code === '00000') {
        this.loadSuccess = true
        this.list = data.map(item => {
          if (item.authState === undefined) {
            item.authState = 0
          }
          return item
        })
      } else {
        this.$message.error(msg || '网络错误，请重试～')
      }
    },
    createShop () {
      this.$router.push('/create_shop')
    },
    changeShop (id) {
      this.$router.push({ path: '/create_shop', query: { id } })
    },
    async selectShop (item) {
      setSession(SHOPID, item.shopId)
      setSession(SHOPAUTHSTATE, item.authState || 0)
      setSession(SHOPSTAFFID, item.shopStaffId)
      setSession(SHOPROLEID, item.shopRoleId)
      checkIsPlatform()
      await this.$store.dispatch('route/initRoutes')
      await this.$store.dispatch('tabs/delAllTabs')
      router.addRoutes(this.$store.state.route.routes)
      this.$router.replace('/')
    }
  }
}
</script>

<style lang="less" scoped>
  .select-shop-page {
    width: 900px;
    margin: 0 auto;

    ::v-deep .header {
      box-shadow: none;
    }
    .page-content {
      min-height: calc( 100vh - 96px );
      margin: 0 30px 32px;
      padding: 16px 0;
      box-sizing: border-box;
      background: #f5f5f5;
      display: flex;
      flex-direction: column;
      .head-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        padding: 0 24px;
        height: 44px;
        flex-shrink: 0;
        .user-info {
          display: flex;
          align-items: center;
          .head-img {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            margin-right: 14px;
          }
        }
      }
      .shop-list {
        background: #fff;
        margin: 0 8px;
        flex:1;
        .search-box {
          padding: 12px 24px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          padding: 16px;
          .item {
            width: 242px;
            min-height: 140px;
            border: 1px solid #eee;
            border-top: 4px solid @main-color;
            margin: 0 32px 24px 0;
            box-sizing: border-box;
            padding: 0 12px 4px;
            cursor: pointer;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            &:hover {
              box-shadow: 0px 0px 8px 4px rgba(0,0,0,.1);
            }
            &:hover .item-subject{
              color: #333;
            }
            &:hover .item-date{
              color: #333;
            }
            .item-name {
              padding-top: 24px;
              font-weight: bolder;
              font-size: 16px;
              line-height: 16px;
              margin-bottom: 16px;
              color: #000;
            }
            .item-subject,
            .item-date {
              color: #666;
              font-size: 12px;
              line-height: 18px;
              margin-bottom: 8px;
            }
            .btn-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              ::v-deep button {
                padding: 4px;
              }
              .auth-status {
                font-size: 12px;
                border: 1px solid #000;
                padding: 0 4px;
                line-height: 20px;
                font-weight: bold;
                color: #000;
              }
              .auth-status-0 {
                color: #999;
                border-color: #999;
              }
              .auth-status-1 {
                color: #52c41a;
                border-color: #52c41a;
              }
              .auth-status-2 {
                color: #ff9400;
                border-color: #ff9400;
              }
              .auth-status-3 {
                color: #ff4d4f;
                border-color: #ff4d4f;
              }
            }
          }
        }
      }
    }
  }
</style>
