const st = process.env.NODE_ENV === 'development' ? window.localStorage : window.sessionStorage
export const getSession = function (key) {
  let ssVal = st.getItem(key)
  if (ssVal && ssVal.indexOf('autostringify-') === 0) {
    return JSON.parse(ssVal.split('autostringify-')[1])
  } else {
    return ssVal
  }
}

export const setSession = function (key, value) {
  if (typeof value === 'undefined') return false
  if (typeof value !== 'string') {
    value = 'autostringify-' + JSON.stringify(value)
  }
  return st.setItem(key, value)
}

export const removeSession = function (key) {
  return st.removeItem(key)
}
