import { SHOPID, SHOPSTAFFID } from '@/constants'
import { service } from '@/utils/request'
import { getSession } from '@/utils/session'

export default {
  getGoodsList (payload) {
    return service.get('/product/page', {
      params: {
        shopId: getSession(SHOPID),
        shopStaffId: getSession(SHOPSTAFFID),
        ...payload
      }
    })
  },
  productPublish (payload) {
    return service.post('/product/publish', payload)
  },
  getProductDetail (productId) {
    return service.get(`/product/detail/${productId}`)
  },
  productEdit (payload) {
    return service.post('/product/edit', payload)
  },
  updateProductState (payload) {
    return service.postForm('/product/updateState', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  getCollectionPage (payload) {
    return service.get('/collection/product/page', {
      params: {
        shopId: getSession(SHOPID),
        shopStaffId: getSession(SHOPSTAFFID),
        ...payload
      }
    })
  },
  // 被采集商品详情
  getBeCollectedProductDetail (payload) {
    return service.get(`/collection/product/beCollectedProductDetail`, {
      params: payload
    })
  },
  // 被采集商品详情（发布页获取详情）
  getCollectedProductDetail (payload) {
    return service.get('/collection/product/collectedProductDetail', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  // 店铺商品列表
  getShopGoodsList (payload) {
    return service.get('/collection/product/pageSupplyShopProduct', {
      params: payload
    })
  },
  // 获取店铺详情
  getShopDetail (upShopId) {
    return service.get(`/shop/detail/${getSession(SHOPID)}/${upShopId}`)
  },
  // 删除商品
  deleteProduct (payload) {
    return service.postForm('/product/delete', payload)
  },
  copyProduct (payload) {
    return service.postForm('/product/copy', payload)
  },
  productSync (productId) {
    return service.postForm('/product/sync', { productId })
  },
  getProductByIds (payload) {
    return service.get('/product/listProductById', {
      params: payload
    })
  },
  checkNeedVoucher (payload) {
    return service.get('/shopVoucher/needVoucher', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  onceCollect (payload) {
    return service.post('/collection/product/once', {
      ...payload,
      shopStaffId: getSession(SHOPSTAFFID)
    })
  },
  // 根据商品id查询sku及其阶梯价格
  getSkuAndStepPrice (productId) {
    return service.get('/product/listSkuAndStepPrice', {
      params: {
        productId
      }
    })
  },
  // 保存阶梯价格
  async saveStepPrice (payload) {
    const { code, msg } = await service.post('/product/saveStepPrice', payload)
    if (code !== '00000') {
      throw new Error(msg)
    }
  },
  // 根据商品id查询佣金信息
  listCommision (productId) {
    return service.get('/product/listCommision', {
      params: {
        productId
      }
    })
  },
  // 保存佣金信息
  async saveCommision (payload) {
    const { code, msg } = await service.post('/product/saveCommision', payload)
    if (code !== '00000') {
      throw new Error(msg)
    }
  },
  emptyCommision (productId) {
    return service.postForm('/product/emptyCommision', {
      productId
    })
  },
  // 批发市场列表
  getBatchPage (payload) {
    return service.get('/wholesale/product/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  // 获取商品批发详情
  getProductBatchDetail (productId) {
    return service.get('/wholesale/product/detail', {
      params: {
        productId
      }
    })
  },
  // 获取店铺默认收货地址
  async getDefaultShopAttr (shopId) {
    const { data } = await service.get('/shopAddr/send/default/get', {
      params: {
        shopId
      }
    })
    return data
  },
  // 获取店铺所有收货地址
  async getAllAttr (shopId) {
    const { data } = await service.get('/shopAddr/receipt/list', {
      params: {
        shopId
      }
    })
    return data
  },
  // 批发创建订单
  async createBatchOrder (payload) {
    const { code, msg, data } = await service.post('/wholesale/order/confirm', payload)
    if (code !== '00000') {
      throw new Error(msg)
    }
    return data
  },
  // 批发获取支付信息
  async getBatchPayInfo (orderNo) {
    const { data } = await service.get('/wholesale/order/pay/get', {
      params: {
        orderId: orderNo
      }
    })
    return data
  },
  // 批发支付
  async batchPay (payload) {
    const { code, msg } = await service.post('/wholesale/order/pay/confirm', payload)
    if (code !== '00000') throw new Error(msg)
  },
  getGoodsRankPage (payload) {
    return service.get('/productAccessRecord/rank', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  }
}
