<template>
  <div>
    <header class="header">
      <img src="@/assets/icon-head-logo.png" class="system" />
      <div class="account-info" v-if="shopInfo && info.shopId">
        <div class="shop-name">
          <div class='shop-name-text'>{{info.name}}</div>
          <div class="shop-info-box">
            <div class="shop-info-head">
              <img src="@/assets/default-img.png" class='shop-default-img' alt="">
              <div class="shop-info">
                <div class="shop-name-box">
                  <div class="shop-name-box-text">{{info.name}}</div>
                  <div class="link-shop-info-page">
                    <div @click='$router.push("/shop/info")'>店铺信息</div>
                    <icon-svg class="right-arrow" icon-class="icon-right-arrow" />
                  </div>
                </div>
                <div class="authentication-status">
                  {{shopAuthStates.find(item => item.value === info.authState).name}}
                </div>
              </div>
            </div>
            <div class="shop-info-desc">{{info.intro||'暂无简介'}}</div>
            <div class="shop-info-contact">
              <p style='margin-bottom:8px;'>店铺地址：{{info.shopFullAddr||'-'}}</p>
              <div class="tel-box">
                <div style='display:flex;flex-wrap:wrap;margin-bottom:8px;'>
                  <p>客服电话：</p>
                  <p v-if='!info.serviceTel'>-</p>
                  <p v-else v-for='(item,index) in info.serviceTel.split(",")' :key='index'>{{item}}</p>
                </div>
                <div style='display:flex;flex-wrap:wrap'>
                  <p>售后电话：</p>
                  <p v-if='!info.afterServiceTel'>-</p>
                  <p v-else v-for='(item,index) in info.afterServiceTel.split(",")' :key='index'>{{item}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="authentication" :style="{color:shopAuthStates.find(item => item.value === info.authState).color}" @click='$router.push("/shop/authentication")'>
          <icon-svg class="status-icon" icon-class="icon-shop-status" />
          <div class="authentication-text">{{shopAuthStates.find(item => item.value === info.authState).name}}</div>
          <icon-svg class="icon" icon-class="icon-right-arrow" />
        </div>
        <a-button type='link' v-if='info.qrcodeUrl' style='line-height:64px;' @click='showQrcodeModal = true'>小程序二维码</a-button>
        <a-modal v-model="showQrcodeModal" title="小程序二维码" width='360px' :footer='null' :zIndex='10001'>
          <div class='qrcode-box'>
            <base-img :src='info.qrcodeUrl' class="qrcode-img"></base-img>
          </div>
        </a-modal>
      </div>
      <div class="tools r">
        <div class='msg-btn' v-if="shopInfo && info.shopId" @click="goMsgPage">
          <a-badge :count="info.notReadNum">
            <a-icon type="bell" style='fontSize:24px' />
          </a-badge>
        </div>
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            {{nickName}}
            <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="shopInfo" @click="goPage('/select_shop')">
              <a href="javascript:;">切换店铺</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="goPage('/account_setting')">个人账号设置</a>
            </a-menu-item>
            <a-menu-item @click='loginOut'>
              <a href="javascript:;">退出登录</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </header>
  </div>
</template>

<script>
import { NICKNAME, SHOPSTAFFID } from '@/constants'
import { getSession } from '@/utils/session'
import { reactive, toRefs, onMounted, onUnmounted } from '@vue/composition-api'
import { shopAuthStates } from '@/utils/constants'
import { system } from '@/api'
export default {
  name: 'AppHeader',
  setup (props, { root }) {
    const labelCol = { span: 4 }
    const wrapperCol = { span: 18 }
    const accountRules = {}
    const state = reactive({
      shopInfo: true,
      showQrcodeModal: false,
      info: {
        name: '',
        authState: null,
      },
      nickName: getSession(NICKNAME),
      omsSocket: null,
      mmsSocket: null,
      omsPath: process.env.VUE_APP_OMSSOCKET + '/websocket/' + getSession(SHOPSTAFFID),
      mmsPath: process.env.VUE_APP_MMSSOCKET + '/websocket/' + getSession(SHOPSTAFFID),
    })
    async function getData () {
      await root.$store.dispatch('head/getShopInfoRequest')
      state.info = root.$store.getters['head/getShopInfo']
      if (state.info.urgentMsgList.length) {
        const arr = ['info', 'warning', 'error']
        await root.$nextTick()
        state.info.urgentMsgList.forEach((item, index) => {
          setTimeout(() => {
            root.$notification[arr[item.level]]({
              message: item.title,
              description: item.content,
              duration: null,
              onClose: async () => {
                system.readMsg({
                  messageReceiveId: item.messageReceiveId,
                  type: 0,
                })
              },
            })
          }, index * 100)
        })
      }
    }
    function goPage (url) {
      root.$router.push(url)
    }
    function loginOut () {
      root.$confirm({
        title: '提示',
        content: '确定要退出登录？',
        onOk () {
          localStorage.clear()
          root.$router.replace('/login')
        },
      })
    }
    onUnmounted(() => {
      if (state.omsSocket) {
        state.omsSocket.close()
      }
      if (state.mmsSocket) {
        state.mmsSocket.close()
      }
      root.$bus.$off('PageMessage:refresh', callback)
    })
    onMounted(() => {
      let arr = ['/create_shop', '/select_shop']
      if (arr.indexOf(root.$route.path) !== -1) {
        state.shopInfo = false
      } else {
        getData()
        initSocket()
        root.$bus.$on('PageMessage:refresh', callback)
      }
    })
    const callback = () => {
      getNotReadMessageNum()
    }

    async function getNotReadMessageNum () {
      const { data } = await system.getNotReadMessageNum()
      state.info.notReadNum = data
    }
    function initSocket () {
      if (process.env.VUE_APP_NOSOCKET) return
      state.omsPath = process.env.VUE_APP_OMSSOCKET + '/websocket/' + getSession(SHOPSTAFFID)
      state.mmsPath = process.env.VUE_APP_MMSSOCKET + '/websocket/' + getSession(SHOPSTAFFID)
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        state.omsSocket = new WebSocket(state.omsPath)
        state.omsSocket.onopen = openSocket
        state.omsSocket.onerror = errorSocket
        state.omsSocket.onmessage = getMessage
        state.omsSocket.onclose = closeSocket

        state.mmsSocket = new WebSocket(state.mmsPath)
        state.mmsSocket.onopen = openSocket
        state.mmsSocket.onerror = errorSocket
        state.mmsSocket.onmessage = getMessage
        state.mmsSocket.onclose = closeSocket
      }
    }
    function closeSocket () {
      console.log('socket已关闭')
    }
    function openSocket () {
      console.log('socket 链接成功')
    }
    function errorSocket (error) {
      console.log('socket 链接失败')
      console.log(error)
    }
    function getMessage (msg) {
      state.notReadMsgNum++
      const obj = JSON.parse(msg.data)
      const arr = ['info', 'warning', 'error']
      root.$notification[arr[obj.level]]({
        message: obj.title,
        description: obj.content,
        duration: obj.level ? null : 5,
      })
      root.$bus.$emit('PageMessageList:refresh')
    }
    function goMsgPage () {
      root.$router.push('/message_page')
    }
    return {
      shopAuthStates,
      labelCol,
      goMsgPage,
      wrapperCol,
      accountRules,
      ...toRefs(state),
      getData,
      loginOut,
      goPage,
    }
  },
}
</script>

<style lang="less" scoped>
.header {
  min-width: 900px;
  position: relative;
  z-index: 1;
  height: 64px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  box-shadow: 0 3px 10px rgba(123, 160, 173, 0.25);
  color: #666;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  .system {
    width: 210px;
  }
  .tools {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .account-info {
    display: flex;
    padding-left: 26px;
    margin-left: 26px;
    position: relative;
    flex: 1;
    line-height: 64px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 20px;
      width: 1px;
      top: 22px;
      background: #ddd;
    }
    .shop-name {
      .shop-name-text {
        font-weight: bold;
        color: #666;
        cursor: pointer;
      }
      .shop-info-box {
        z-index: 10;
        display: none;
        top: 64px;
        left: 26px;
        background: #f08e61;
        width: 452px;
        position: absolute;
        z-index: 3;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
        border-radius: 2px;
        padding: 16px 0 0;
        box-sizing: border-box;
        color: #fff;
        &::after {
          border-left: 10px solid rgba(0, 0, 0, 0);
          border-right: 10px solid rgba(0, 0, 0, 0);
          border-bottom: 10px solid #f08e61;
          content: '';
          position: absolute;
          width: 0;
          top: -10px;
          left: 24px;
        }
        .shop-info-head {
          display: flex;
          align-items: center;
          color: #fff;
          line-height: 16px;
          padding-bottom: 14px;
          border-bottom: 1px solid #fff;
          margin: 0 16px;
          .shop-default-img {
            height: 52px;
            width: 52px;
            background: #fff;
            border-radius: 50%;
            color: #ddd;
            margin-right: 12px;
          }
          .shop-info {
            flex: 1;
            .shop-name-box {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .shop-name-box-text {
                font-weight: 900;
                margin-bottom: 6px;
                font-size: 14px;
              }
              .link-shop-info-page {
                display: flex;
                cursor: pointer;
                align-items: center;
                .right-arrow {
                  margin-left: 4px;
                }
              }
            }
            .authentication-status {
              width: 4em;
              border: 1px solid #fff;
              line-height: 18px;
              font-size: 12px;
              text-align: center;
              border-radius: 2px;
              font-weight: 900;
            }
          }
        }
        .shop-info-desc {
          line-height: 24px;
          padding: 14px 16px;
        }
        .shop-info-contact {
          padding: 14px 16px;
          line-height: 24px;
          color: #666;
          background: #fff;
          .tel-box {
            display: flex;
            flex-wrap: wrap;
            p {
              margin-right: 8px;
              flex-shrink: 0;
              &:first-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .shop-name:hover .shop-info-box {
      display: block !important;
    }
    .authentication {
      cursor: pointer;
      margin-left: 32px;
      display: flex;
      align-items: center;
      font-weight: 500;
      .authentication-text {
        margin: 0 4px 0 8px;
      }
      .status-icon {
        font-size: 20px;
      }
    }
  }
}
.qrcode-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrcode-img {
  width: 300px;
  height: 300px;
  ::v-deep img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.msg-btn {
  margin-right: 32px;
  cursor: pointer;
}
</style>
