<template>
  <div class="picture-card fix" id='picture-card'>
    <div v-if="!isPreview" v-viewer class="ant-upload-list ant-upload-list-picture-card">
      <draggable v-model="fileListFormat" :options="{handle: draggable ? '.ant-upload-list-item' : '.disabled'}">
        <div class="ant-upload-list-item ant-upload-list-item-done" v-for="(imgItem, index) in fileListFormat" :key="`img${index}`" >
          <div class="ant-upload-list-item-info">
            <span>
              <a class="ant-upload-list-item-thumbnail" href="javascript:;">
                <img :src="imgItem | format" alt="" />
              </a>
            </span>
          </div>
          <a href="javascript:;" class="btn-del" @click="handleDelete(index)">
            <a-icon type="close" />
          </a>
        </div>
      </draggable>
      <div style="float: left;" class='upload-box' v-if="fileList.length < this.max" @click="handleCustomRequest">
        <a-icon class="btn-upload-icon" type="plus" />
        <p class="btn-upload-text">添加图片</p>
      </div>
    </div>
    <div v-else v-viewer  class="ant-upload-list ant-upload-list-picture-card">
      <div class="ant-upload-list-item ant-upload-list-item-done" v-for="(imgItem, index) in fileListFormat" :key="`img${index}`">
        <div class="ant-upload-list-item-info">
          <span>
            <a class="ant-upload-list-item-thumbnail" href="javascript:;">
              <img :src="imgItem | format" alt="" />
            </a>
          </span>
        </div>
      </div>
    </div>
    <select-img-dialog :idName='idName' v-if='showSelectImg' :show='showSelectImg' @change='handleSelectDone' :initSelectData='fileList' :multiple='max>1' :max='max'></select-img-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
// import ossUpload from '@/utils/oss'

export default {
  name: 'PictureCardUpload',

  components: {
    draggable
  },

  props: {
    fileList: Array,
    max: {
      type: Number,
      default: 3
    },
    isPreview: Boolean, // 是否开启预览模式（没有上传按钮）
    draggable: {
      type: Boolean,
      default: false
    },
    idName: {
      type: String,
      default: 'picture-card'
    }
  },

  computed: {
    fileListFormat: {
      get () {
        return this.fileList || []
      },
      set (value) {
        this.$emit('update:fileList', value)
      }
    }
  },

  filters: {
    format: (x) => `${process.env.VUE_APP_IMG}${x}`
  },

  data () {
    return {
      showSelectImg: false
    }
  },

  methods: {
    async handleDelete (index) {
      this.$emit(
        'update:fileList',
        this.fileList.filter((x, i) => i !== index)
      )
    },
    async handleCustomRequest (options) {
      this.showSelectImg = true
    },
    handleSelectDone (data) {
      this.$emit('update:fileList', [...this.fileList, data])
      this.$emit('change', data)
      this.fileListFormat = data
      this.showSelectImg = false
    }
  }
}
</script>

<style lang="less" scoped>
.custom-upload-list {
  display: flex;
}
.picture-card {
  ::v-deep .ant-upload-list-picture-card {
    .ant-upload-list-item:hover .btn-del {
      display: flex;
    }
    .ant-upload-list-item-progress {
      left: 12px;
    }
    .ant-upload-list-item-uploading-text {
      text-align: center;
    }
  }
  .btn-upload-icon {
    font-size: 18px;
  }
  .btn-upload-text {
    margin-top: 3px;
    font-size: 14px;
  }
  .btn-del {
    display: none;
    position: absolute;
    z-index: 2;
    top: -9px;
    right: -9px;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 16px;
    background: hsla(0, 0%, 60%, 0.6);
    border-radius: 10px;
    ::v-deep .anticon {
      position: relative;
      top: 0;
      right: 0;
      color: #fff;
      line-height: 0;
    }
    &:hover {
      background: #333;
    }
  }
}
.upload-box {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
  p {
    font-size: 14px;
  }
}
::v-deep .ant-upload-list-item-info {
  &::before {
    display: none;
  }
}
</style>
