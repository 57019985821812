const registerComponents = (Vue) => {
  Vue.component('c-add-img', () => import('./add-img/Index.vue'))
  Vue.component('c-add-img-operation', () => import('./add-img/Operation.vue'))
  Vue.component('c-menu-list', () => import('./menu-list/Index.vue'))
  Vue.component('c-menu-list-operation', () => import('./menu-list/Operation.vue'))
  Vue.component('c-empty-line', () => import('./empty-line/Index.vue'))
  Vue.component('c-empty-line-operation', () => import('./empty-line/Operation.vue'))
  Vue.component('c-goods-list', () => import('./goods-list/Index.vue'))
  Vue.component('c-goods-list-operation', () => import('./goods-list/Operation.vue'))
  Vue.component('c-text-title', () => import('./text-title/Index.vue'))
  Vue.component('c-text-title-operation', () => import('./text-title/Operation.vue'))
}

export default registerComponents
