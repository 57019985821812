import { render, staticRenderFns } from "./AppDashboard.vue?vue&type=template&id=56d71178&scoped=true"
import script from "./AppDashboard.vue?vue&type=script&lang=js"
export * from "./AppDashboard.vue?vue&type=script&lang=js"
import style0 from "./AppDashboard.vue?vue&type=style&index=0&id=56d71178&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d71178",
  null
  
)

export default component.exports