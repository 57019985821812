<template>
  <a-empty :image="simpleImage" :description="text"/>
</template>
<script>
import { Empty } from 'ant-design-vue'
export default {
  props: {
    text: {
      type: String,
      value: '暂无数据'
    },
    image: {
      type: String,
      value: ''
    }
  },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  }
}
</script>
<style lang="less" scoped>
.no-data {
  text-align: center;
}
</style>
