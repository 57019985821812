import { service } from '@/utils/request'
import { SHOPSTAFFID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  getMyMenus (payload) {
    return service.get('/mmsFunc/listFuncByUser', {
      params: payload
    })
  },
  getAllMenus (payload) {
    return service.get('/mmsFunc/listTree', {
      params: payload
    })
  },
  userSetting (payload) {
    return service.post('/shopUser/save', payload)
  },
  getIndexData (payload) {
    return service.get('/index', {
      params: payload
    })
  },
  getNotReadMessageNum () {
    return service.get('/msgReceive/notReadMsg', {
      params: {
        shopStaffId: getSession(SHOPSTAFFID)
      }
    })
  },
  getMsgPage (payload) {
    return service.get('/msgReceive/page', {
      params: payload
    })
  },
  readMsg (payload) {
    return service.postForm('/msgReceive/readMsg', payload)
  },
  getStaffPoster (payload) {
    return service.get('/shopStaff/staffPoster', {
      params: payload
    })
  },
  getUserList (payload) {
    return service.get('/partner/user/page', {
      params: payload
    })
  }
}
