import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/order',
  component: AppLayout,
  children: [
    {
      path: 'goods_order',
      component: () => import('@/views/order/PageGoodsOrder.vue'),
      meta: { title: '订单查询', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'goods_order/detail/:shopOrderId',
      props: true,
      component: () => import('@/views/order/PageOrderDetail.vue'),
      meta: { title: '订单详情', noClose: false, showInMenuFlag: false, noCache: true }
    },
    {
      path: 'goods_order/service_detail/:shopOrderItemId',
      props: true,
      component: () => import('@/views/order/PageServiceDetail.vue'),
      meta: { title: '售后详情', noClose: false, showInMenuFlag: false, noCache: true }
    }
  ]
}
