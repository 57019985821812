<template>
  <a-spin :spinning='showLoading'>
    <div class='h5_page'>
      <div class='head-box'>
        <base-img class='logo' :src='query.logo||require("../../assets/default-logo.png")'></base-img>
        <div class='name'>{{query.name||'新零售'}}</div>
      </div>
      <div class='slogan'>
        <p>邀请您入驻</p>
        <p>新零售供应链平台</p>
      </div>
      <div class='tab-box'>
        <p class='link' :class='{active:type===1}' @click='changeType(1)'>绑定</p>
        <p class='link' :class='{active:type===0}' @click='changeType(0)'>注册</p>
      </div>
      <a-form-model class='form' ref="formRef" :model="form" @submit.native.prevent :rules="rules">
        <a-form-model-item prop="loginName" class='form-item'>
          <a-input allowClear v-model="form.loginName" placeholder="请输入手机号" />
        </a-form-model-item>
        <a-form-model-item prop="smsCode" class='form-item'>
          <a-input type="text" v-model="form.smsCode" placeholder="短信验证码" class='sms'>
            <template slot="suffix">
              <div class='get-sms' @click='getSmsCode'>{{smsTest}}</div>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="loginPwd" class='form-item'>
          <a-input allowClear type="password" v-model="form.loginPwd" placeholder="请输入密码" />
        </a-form-model-item>
        <a-form-model-item prop="shopName" class='form-item' v-if='type!==1'>
          <a-input allowClear v-model="form.shopName" placeholder="请输入店铺名称" />
        </a-form-model-item>
      </a-form-model>
      <div class='btn-css' @click='submit'>{{type===1?'确 定':'注 册'}}</div>
    </div>
  </a-spin>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'
import { common, partner } from '@/api'
import { phoneValidate, pwdValidate } from '@/utils/validator'
export default {
  name: 'Application',
  setup (props, { root }) {
    const state = reactive({
      formRef: null,
      query: {},
      type: 1,
      form: {},
      showLoading: false,
      smsTest: '获取验证码',
      interval: null,
      smsBtnDisabled: false,
      rules: {
        loginName: [
          { required: true, message: '请填写手机号', trigger: 'change' },
          { validator: phoneValidate, trigger: 'change' }
        ],
        smsCode: [{ required: true, message: '请填写短信验证码', trigger: 'change' }],
        loginPwd: [{ required: true, message: '请填写密码', trigger: 'change' }, { validator: pwdValidate, trigger: 'change' }],
        shopName: [{ required: true, message: '请填写店铺名', trigger: 'change' }]
      }
    })
    document.title = root.$route.meta.title
    if (root.$route.query) {
      state.query = root.$route.query
    }
    async function getSmsCode () {
      if (state.smsBtnDisabled) return
      state.showLoading = true
      const { msg, code } = await common.getSmsCode({
        type: 4,
        phone: state.form.loginName
      })
      state.showLoading = false
      if (code === '00000') {
        state.smsBtnDisabled = true
        let num = 60
        state.smsTest = `${num}s 后重试`
        state.interval = setInterval(() => {
          num--
          state.smsTest = `${num}s 后重试`
          if (num <= 0) {
            if (state.interval) {
              state.smsTest = '获取验证码'
              state.smsBtnDisabled = false
              clearInterval(state.interval)
              state.interval = null
            }
          }
        }, 1000)
      } else {
        root.$message.error(msg || '获取验证码失败，请重试')
      }
    }
    async function submit () {
      state.formRef.validate(async (valid) => {
        if (valid) {
          const param = {
            ...state.form,
            ...state.query
          }
          // followType  是 0 时，为绑定供应商关系 + 绑定上下级关系(奖励)
          const { code, msg, data } =
          state.query.followType !== '0' ? (state.type === 1 ? await partner.bindRelation(param) : await partner.registerBindRelation(param)) : (state.type === 1 ? await partner.supplyLogin(param) : await partner.supplyRegister(param))
          if (code === '00000') {
            root.$message.success('绑定成功')
            if (data) {
              root.$router.push({ path: '/h5_bind_success', query: { msg: encodeURIComponent(data) || '' } })
            } else {
              root.$router.push('/h5_bind_success')
            }
          } else {
            root.$message.error(msg || '绑定失败，请重试')
          }
        }
      })
    }
    function changeType (type) {
      state.type = type
    }
    return {
      ...toRefs(state),
      getSmsCode,
      submit,
      changeType
    }
  }
}
</script>
<style lang="less" scoped>
.h5_page {
  min-height: 100vh;
  width: 100vw;
  background: url('../../assets/h5_bg.jpg');
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  box-sizing: border-box;
  padding: 0.48rem 0.4rem;
  color: #fff;
}
.head-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 0.64rem;
}
.logo {
  width: 0.8rem;
  height: 0.8rem;
  margin-right: 0.28rem;
  border: 2px solid #fff;
  box-sizing: border-box;
  border-radius: 0.4rem;
  overflow: hidden;
  ::v-deep img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.slogan p:first-child {
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 0.24rem;
  margin-left: 0.8rem;
  position: relative;
  color: #7d0015;
}
.slogan p:first-child::after {
  content: '邀请您入驻';
  position: absolute;
  top: -2px;
  left: -2px;
  color: #fff;
  font-size: 26px;
  font-weight: 900;
}
.slogan p:last-child {
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 0.48rem;
  margin-left: 0.8rem;
  position: relative;
  color: #7d0015;
}
.slogan p:last-child::after {
  content: '新零售供应链平台';
  position: absolute;
  top: -2px;
  left: -2px;
  color: #fff;
  font-size: 32px;
  font-weight: 900;
}
.form {
  color: #000;
}
.name {
  color: #fff;
  font-weight: 500;
}
.form-item {
  width: 5.5rem;
  margin: 0 auto 0.3rem;
  display: flex;
  align-content: center;
  ::v-deep input {
    height: 0.8rem;
    background: #eee;
    border-radius: 0.4rem;
    padding: 0 0.38rem;
    box-sizing: border-box;
    width: 5.5rem;
  }
}
// .form-item {
//   height: 0.8rem;
//   background: #eee;
//   border-radius: 0.4rem;
//   margin-bottom: 0.3rem;
//   overflow: hidden;
//   padding: 0 0.38rem;
//   box-sizing: border-box;
//   width: 5.5rem;
//   margin: 0 auto 0.3rem;
//   display: flex;
//   align-items: center;
// }
// ::v-deep .ant-input-affix-wrapper .ant-input:focus {
//   border: none;
//   box-shadow: none;
// }
// ::v-deep input {
//   color: #000;
//   border: none;
//   outline: none;
//   line-height: 0.8rem;
//   flex: 1;
//   background: #eee;
//   outline: none;
//   padding: 0;
// }
.btn-css {
  width: 5.5rem;
  margin: 0 auto;
  background: #e83928;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 0.8rem;
  border-radius: 0.8rem;
  margin-bottom: 0.16rem;
}
.get-sms {
  color: #000;
  text-align: center;
  width: 80px;
  flex-shrink: 0;
  line-height: 0.8rem;
}

.link {
  font-size: 18px;
  margin-right: 14px;
  line-height: 36px;
  color: #eeeeee;
}
.link.active {
  font-size: 24px;
  font-weight: 900;
  line-height: 32px;
  color: #fff;
}
.tab-box {
  display: flex;
  width: 5.5rem;
  padding: 12px 0 8px;
  margin: 0 auto;
}
::v-deep .ant-form-explain {
  text-indent: 0.38rem;
  margin-top: 4px;
}
</style>
