import { service } from '@/utils/request'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'

export default {
  getCashCouponActivityPage (payload) {
    return service.get('/cash/coupon/act/page', {
      params: payload
    })
  },
  getCashCouponPage (payload) {
    return service.get('/cash/coupon/page', {
      params: payload
    })
  },
  createCashCoupon (payload) {
    return service.post('/cash/coupon/save', payload)
  },
  deleteCashCoupon (cashCouponId) {
    return service.postForm('/cash/coupon/delete', {
      cashCouponId
    })
  },
  saveCashCouponActivity (payload) {
    return service.post('/cash/coupon/act/save', payload)
  },
  getCashCouponActivity (id) {
    return service.get('/cash/coupon/act/get', {
      params: {
        cashCouponActId: id
      }
    })
  },
  deleteCashCouponActivity (cashCouponActId) {
    return service.postForm('/cash/coupon/act/delete', {
      cashCouponActId
    })
  },
  getGroupActivityPage (payload) {
    return service.get('/group/activity/page', {
      params: payload
    })
  },
  getActivitySelectProductpage (payload) {
    return service.get('/product/pageActivitySelectProduct', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  getVoucherSelectProductPage (payload) {
    return service.get('/product/pageProductForVoucher', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  saveGroupActivity (payload) {
    return service.post('/group/activity/add', payload)
  },
  getGroupActivityDetail (groupActivityId) {
    return service.get('/group/activity/detail/' + groupActivityId)
  },
  editGroupActivity (payload) {
    return service.post('/group/activity/edit', payload)
  },
  invalidGroupActivity (payload) {
    return service.postForm('/group/activity/invalid', payload)
  },
  deleteGroupACtivity (payload) {
    return service.postForm('/group/activity/delete', payload)
  },
  getBargainActivityPage (payload) {
    return service.get('/barginActivity/page', {
      params: payload
    })
  },
  saveBargainActivity (payload) {
    return service.post('/barginActivity/save', payload)
  },
  deleteBargainActivity (payload) {
    return service.postForm('/barginActivity/delete', payload)
  },
  getBargainActivityDetail (payload) {
    return service.get('/barginActivity/get', {
      params: payload
    })
  },
  getPageActivityProduct (payload) {
    return service.get('/barginActivity/pageActivityProduct', {
      params: payload
    })
  },
  getSeckillActivityPage (payload) {
    return service.get('/seckillActivity/page', {
      params: payload
    })
  },
  seckillActivitySave (payload) {
    return service.post('/seckillActivity/save', {
      shopId: getSession(SHOPID),
      ...payload
    })
  },
  getSeckillActivityDetail (seckillActivityId) {
    return service.get('/seckillActivity', {
      params: { seckillActivityId }
    })
  },
  disabledSeckillActivity (payload) {
    return service.postForm('/seckillActivity/delete', payload)
  },
  getExchangeActivityPage (payload) {
    return service.get('/order/voucher/create/page', {
      params: payload
    })
  },
  getSkusByProductId (id) {
    return service.get('/product/listSkuByProductId', {
      params: {
        productId: id
      }
    })
  },
  createVoucher (payload) {
    return service.post('/order/voucher/create/build', payload)
  }
}
