<template>
  <span>{{ now }}</span>
</template>

<script>
import moment from 'moment'
import { onMounted, onUnmounted, ref } from '@vue/composition-api'

export default {
  name: 'AClock',
  setup () {
    const now = ref(getCurrentTimeStr())

    function getCurrentTimeStr () {
      return moment().format('YYYY年MM月DD日 HH:mm:ss')
    }

    let timer
    onMounted(() => {
      timer = setInterval(() => {
        now.value = getCurrentTimeStr()
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    return {
      now
    }
  }
}
</script>

<style lang="css" scoped>

</style>
