import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/bean',
  component: AppLayout,
  children: [
    {
      path: 'charge',
      component: () => import('@/views/bean/PageCharge.vue'),
      meta: { title: '充值管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/bean/history',
      component: () => import('@/views/bean/PageChargeAccountHistory.vue'),
      meta: { title: '充值变动记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'parameter',
      component: () => import('@/views/bean/ParameterForm.vue'),
      meta: { title: '系统参数', noClose: false, showInMenuFlag: true }
    },
  ]
}
