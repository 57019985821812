<template>
  <div>
    <div class="box">
      <img class="seal" src="./assets/seal.png" alt="">
      <p class="sub-title">保险责任：</p>
      <p class="content">在中国境内（不含港澳台地区）由于使用机舱线路阻燃保护剂后，由于产品存在缺陷引发的线路短路、漏电及线路老化造成使用车辆的财产损失，依法由烟台旺谷网络科技有限公司承担的经济赔偿责任，经产品使用者或者消费者在保险期间内向被保险人首次提出索赔时，在本保单约定的赔偿限额内由中国人寿财产保险股份有限公司北京市分公司予以赔偿；</p>
      <p class="sub-title">每次事故赔偿限额：</p>
      <div class="content">
        <p>1.每车赔偿限额：该车新车购置价，且不超过100万。</p>
        <p>2.每车机舱内线路维修更换赔偿限额：10万。</p>
        <p>3.每车人身伤亡赔偿限额：60万，每车限2人，每人赔偿限额30万。</p>
        <p>保额为每辆车的新车购置价，每车最高保额（车辆新车购置价）不超100万。每次事故财产损失免赔额：1000元或损失金额的5%,两者以高者为准(只限自燃车辆)。</p>
      </div>
      <p class="sub-title">责任免除：</p>
      <div class="content">
        <p>1.机舱线路阻燃保护剂使用人或其代表自行清除或者重新使用其他发动机保护产品，致使使用该产品的车辆引起的任何或全部损失，均不予理赔；</p>
        <p>2.超出机舱线路阻燃保护剂所承保保险责任外的任何财产损失或人身伤亡，均不予理赔；</p>
        <p>3.任何经济纠纷引起的损失，均不予理赔；</p>
        <p>4.使用汽车机舱线路阻燃保护剂的车辆造成第三者的人身伤亡或财产损失，均不予理赔；</p>
        <p>5.因交通事故或其他外力作用所造成的汽车自燃；</p>
        <p>6.使用产品后，因人为因素故意造成汽车自燃，均不予理赔；</p>
        <p>7.非直接损失，均不予理赔；</p>
        <p>8.车辆超过8年的机动车使用本产品后产生的汽车自燃事故及线路老化车辆损失，均不予理赔；</p>
        <p>9.其他除外责任详见保险合同</p>
      </div>
      <p class="sub-title">赔偿处理：</p>
      <div class="content">
        <p>1.由于产品存在缺陷引发导致的机舱线路故障需要更换维修的，按实际损失进行赔偿。</p>
        <p>2.由于产品存在缺陷引发导致的车辆自燃报废，按新车发票购置价进行赔偿。</p>
        <p>3.承保车辆出险后必须到该产品原销售单位进行核价和维修。</p>
      </div>
      <p class="sub-title">索赔资料：</p>
      <div class="content">
        <p>1.理赔机舱内线路维修更换车辆需提供授权定点施工单位维修更换证明，驾驶证及身份证、机动车行驶证车辆修复发票及维修清单。</p>
        <p>2.理赔车辆自燃时需提供受损入驾驶证及身份证、机动车行驶证、机动车登记证书、购车原始发票、车辆购置税完税证明或免税证明复印件、车辆修复发票及维修清单，县级以上消防部门出具的消防证明和车辆已报停资料等保险公司要求的其他材料。</p>
        <p>3.涉及入伤医疗费赔偿的，需要提供全国三级甲等医院的医药费清单及发票，及保险公司要求的其他资料。</p>
        <p>中国人寿财险全国客服电话：010-95519</p>
        <p>厂家服务电话：4006687172</p>
      </div>
    </div>
    <p class="title spec">客户信息卡及服务声明</p>
    <table class="custom-table">
      <tr>
        <td>*客户名称</td>
        <td>{{data.customerName}}</td>
        <td>*移动电话</td>
        <td>{{data.linkPhone}}</td>
      </tr>
      <tr>
        <td>*身份证号</td>
        <td>{{data.idcardCode}}</td>
        <td>*新车/二手车购置价</td>
        <td>{{unitFormat(data.carPrice, '万元')}}</td>
      </tr>
      <tr>
        <td>联系地址</td>
        <td colspan="3">{{data.address}}</td>
      </tr>
      <tr>
        <td>*车辆VIN码</td>
        <td>{{data.frmNo}}</td>
        <td>*发动机号</td>
        <td>{{data.engNo}}</td>
      </tr>
      <tr>
        <td>车辆购买年限</td>
        <td>{{unitFormat(data.years, '年')}}</td>
        <td>*车牌号</td>
        <td>{{data.plateNo}}</td>
      </tr>
      <tr>
        <td>*厂牌型号</td>
        <td colspan="3">{{data.brandName}}</td>
      </tr>
      <tr>
        <td>*保险期限</td>
        <td colspan="3">一年期，由{{dateFormat(data.validTime)}}零时起至{{dateFormat(data.endTime)}}二十四时止</td>
      </tr>
      <tr>
        <td>*安装地址</td>
        <td colspan="3">{{data.installProvince}}{{data.installCity}}{{data.installCounty}} {{data.installAddr}}</td>
      </tr>
    </table>
    <p class="tip">注：表内*为必填项目，填写表格时请认真填写，其中二手车购置价按实际购置价填写，若虚报按当时权威鉴定的价格赔付或拒赔。</p>
  </div>
</template>

<script>
export default {
  name: 'WarrantyAgreement',

  props: {
    data: Object,
    dateFormat: Function,
    unitFormat: Function
  }
}
</script>

<style lang="less" scoped>
.box {
  position: relative;
  .seal {
    position: absolute;
    right: -10px;
    bottom: -24px;
    z-index: 1;
    width: 100px;
  }
}
.sub-title {
  font-size: 8px;
  font-weight: bold;
}
.content {
  font-size: 8px;
  text-indent: 16px;
}
</style>
