<template>
  <div class="page-customer-input">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="rules"
    >
      <card-verify @change="handleCardVerifyChange" />
      <div class="custom-field">
        <a-form-model-item label="保单号" prop="policyNo">
          <a-input v-model.trim="form.policyNo" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="大保单号" prop="bigPolicyNo">
          <a-input v-model.trim="form.bigPolicyNo" placeholder="请输入" disabled />
        </a-form-model-item>
        <a-form-model-item label="客户名称" prop="customerName">
          <a-input v-model.trim="form.customerName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="联系方式" prop="linkPhone">
          <a-input type="tel" v-model.trim="form.linkPhone" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="证件号" prop="idcardCode">
          <a-input v-model.trim="form.idcardCode" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item class="textarea" label="地址" prop="address">
          <a-input
            type="textarea"
            v-model.trim="form.address"
            placeholder="请输入"
            autoSize
          />
        </a-form-model-item>
      </div>
      <div class="custom-field">
        <a-form-model-item label="车辆厂牌" prop="brandName">
          <a-input v-model.trim="form.brandName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="车牌号" prop="plateNo">
          <a-input v-model.trim="form.plateNo" placeholder="请输入" @change="form.plateNo = form.plateNo && typeof form.plateNo === 'string' ? form.plateNo.toUpperCase() : ''" />
        </a-form-model-item>
        <a-form-model-item label="车架号" prop="frmNo">
          <a-input v-model.trim="form.frmNo" placeholder="请输入" @change="form.frmNo = form.frmNo && typeof form.frmNo === 'string' ? form.frmNo.toUpperCase().slice(0, 17) : ''" />
        </a-form-model-item>
        <a-form-model-item label="发动机号" prop="engNo">
          <a-input v-model.trim="form.engNo" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="车辆年限" prop="years">
          <a-input
            type="number"
            v-model="form.years"
            placeholder="请输入"
            addonAfter="年"
          />
        </a-form-model-item>
        <a-form-model-item label="新车/二手车购置价" prop="carPrice">
          <a-input
            v-model.trim="form.carPrice"
            placeholder="请输入"
            addonAfter="万元"
          />
        </a-form-model-item>
      </div>
      <div class="custom-field">
        <a-form-model-item
          :class="{'show-placeholder': !form.installTime}"
          label="安装时间"
          prop="installTime"
        >
          <a-input
            type="date"
            v-model.trim="form.installTime"
            placeholder="请选择"
          >
            <a-icon slot="addonAfter" type="calendar" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="安装区域" prop="installRegionCode">
          <a-input
            :value="`${form.installProvince}${form.installCity}${form.installCounty}`"
            placeholder="请选择"
            disabled
          >
            <a-icon slot="addonAfter" type="caret-down" />
          </a-input>
          <p class="form-item-overlay" @click="handleRegionSelectShow"></p>
        </a-form-model-item>
        <a-form-model-item label="安装地点" prop="installAddr">
          <a-input v-model.trim="form.installAddr" placeholder="请输入" />
        </a-form-model-item>
      </div>
      <div class="btn-box">
        <a href="javascript:;" class="btn-item empty" @click="$router.push('/warranty_card')">我的保险卡</a>
        <a href="javascript:;" class="btn-item" @click="handleSubmit">提交</a>
      </div>
    </a-form-model>
    <van-popup
      v-model="regionSelectShow"
      position="bottom"
      :overlay-style="{backgroundColor: 'rgba(0,0,0,.3)'}"
    >
      <van-picker
        ref="pickerRef"
        show-toolbar
        :columns="regionOptions"
        :loading="regionLoading"
        @cancel="regionSelectShow = false"
        @confirm="handleRegionConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { phoneValidate } from '@/utils/validator'
import { common, customer } from '@/api'
import moment from 'moment'
import { Popup, Toast, Picker, Dialog } from 'vant'
import 'vant/lib/popup/style'
import 'vant/lib/toast/style'
import 'vant/lib/picker/style'
import 'vant/lib/dialog/style'

export default {
  name: 'PageCustomerInput',

  components: {
    'van-popup': Popup,
    'van-picker': Picker
  },

  setup (props, { root }) {
    const formRef = ref(null)
    const form = ref({
      policyNo: '',
      bigPolicyNo: '815152020110106000010',
      customerName: '',
      linkPhone: '',
      idcardCode: '',
      address: '',
      brandName: '',
      plateNo: '',
      frmNo: '',
      engNo: '',
      years: 1,
      carPrice: '',
      serialNumber: '',
      installTime: '',
      installAddr: '',
      installProvince: '',
      installCity: '',
      installCounty: '',
      installRegionCode: '',
      validTime: '',
      endTime: '',
      remark: '',
      vehiclePics: '',
      drivingPic: ''
    })
    const validNormal = [
      { required: true, message: '请输入' },
      { max: 32, message: '请输入简短一些' }
    ]
    const rules = ref({
      policyNo: validNormal,
      bigPolicyNo: validNormal,
      customerName: validNormal,
      linkPhone: [
        { required: true, message: '请输入' },
        { validator: phoneValidate }
      ],
      idcardCode: [
        { required: true, message: '请输入' }
      ],
      address: [
        { required: true, message: '请输入' },
        { max: 128, message: '请输入简短一些' }
      ],
      brandName: validNormal,
      plateNo: [
        { required: true, message: '请输入' },
        { pattern: /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[ADF])|([ADF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/, message: '格式不正确' }
      ],
      frmNo: [
        { required: true, message: '请输入' },
        { pattern: /^[A-Z0-9]{17}$/, message: '格式不正确' }
      ],
      engNo: [
        { required: true, message: '请输入' },
        { pattern: /^[0-9A-Za-z\\-]{1,20}$/, message: '格式不正确' }
      ],
      years: [
        { required: true, message: '请输入' },
        { pattern: /^[1-9]\d*$/, message: '格式不正确' }
      ],
      carPrice: [
        { pattern: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, message: '格式不正确' }
      ],
      installAddr: [
        { max: 128, message: '请输入简短一些' }
      ]
    })
    watch(() => form.value.installTime, data => {
      const validTime = moment(data).add(1, 'd')
      form.value.validTime = validTime.format('YYYY-MM-DD')
      form.value.endTime = validTime.add(1, 'y').format('YYYY-MM-DD')
    })
    watch(() => form.value.policyNo, data => {
      form.value.serialNumber = data
    })

    const pickerRef = ref(null)
    const regionOptions = ref([])
    const regionLoading = ref(false)
    const regionSelectShow = ref(false)

    async function getRegionTree () {
      regionLoading.value = true
      const { data, msg, code } = await common.getRegionTree()
      regionLoading.value = false
      if (code === '00000') {
        regionOptions.value = handleTreeData(data)
      } else {
        root.$message.error(msg || '网络错误，请重试')
      }
    }

    function handleTreeData (data) {
      if (!data || !data.length) return
      for (let item of data) {
        item.value = item.regionCode
        item.text = item.regionName
        item.children = handleTreeData(item.children)
      }
      return data
    }

    function handleRegionSelectShow () {
      if (!regionOptions.value.length) getRegionTree()
      regionSelectShow.value = true
    }

    async function handleRegionConfirm (values, indexes) {
      regionSelectShow.value = false
      await root.$nextTick()
      form.value.installProvince = values[0]
      form.value.installCity = values[1]
      form.value.installCounty = values[2]
      form.value.installRegionCode = pickerRef.value.getColumnValues(2)[indexes[2]].regionCode
    }

    function handleSubmit () {
      formRef.value.validate(async valid => {
        if (!valid) return
        showLoading('提交中...')
        const param = Object.assign(form.value)
        if (!param.installTime) {
          const nowDate = moment(new Date())
          param.installTime = nowDate.format('YYYY-MM-DD')
          param.validTime = nowDate.add(1, 'd').format('YYYY-MM-DD')
          param.endTime = nowDate.add(1, 'd').add(1, 'y').format('YYYY-MM-DD')
        }
        const { code, msg } = await customer.updateCustomer(param)
        hideLoading()
        if (code === '00000') {
          Dialog.alert({
            message: '提交成功'
          }).then(() => {
            location.reload()
          })
        } else {
          Dialog.alert({
            message: msg || '提交失败，请稍后重试'
          })
        }
      })
    }

    function handleCardVerifyChange (e) {
      const translateFlds = ['address', 'engNo', 'frmNo', 'plateNo', 'drivingPic']
      form.value.customerName = e.holderName || ''
      form.value.brandName = e.vehicleAlias || ''
      form.value.years = moment().diff(moment(e.fstRegDate), 'years') + 1
      translateFlds.forEach(item => {
        form.value[item] = e[item] || ''
      })
    }

    function showLoading (msg) {
      Toast.loading({
        message: msg || '加载中...',
        duration: 0,
        forbidClick: true
      })
    }

    function hideLoading () {
      Toast.clear()
    }

    return {
      formRef,
      form,
      rules,
      pickerRef,
      regionOptions,
      regionLoading,
      regionSelectShow,
      handleRegionSelectShow,
      handleRegionConfirm,
      handleSubmit,
      handleCardVerifyChange
    }
  }
}
</script>

<style lang="less" scoped>
.page-customer-input {
  width: 100%;
  background-color: #eee;
  overflow: hidden;
  ::v-deep .ant-form {
    width: 100%;
    overflow: hidden;
  }
  ::v-deep form:not(.ant-form-vertical) :not(.ant-input-group-wrapper) > .ant-input-group,
  ::v-deep form:not(.ant-form-vertical) .ant-input-group-wrapper {
    top: 0;
  }
  .show-placeholder ::v-deep .ant-input::before {
    color: #bfbfbf;
    content: attr(placeholder);
  }
  .custom-field {
    width: 100%;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 0 15px 12px;
    background-color: #fff;
    overflow: hidden;
    ::v-deep .ant-form-item {
      display: flex;
      margin-bottom: 0;
      border-bottom: 1px solid #eee;
      &:last-child {
        border: none;
      }
      &.textarea {
        display: block;
        &.no-need .ant-form-item-label {
          padding-left: 10px;
        }
        .ant-form-item-control-wrapper {
          padding-left: 10px;
          .ant-input {
            resize: none;
          }
        }
      }
      &.form-item-pic {
        padding-bottom: 12px;
        .ant-form-explain {
          margin-top: 3px;
        }
      }
      .ant-form-item-label {
        flex: none;
        width: 135px;
        padding-bottom: 0;
        font-size: 14px;
        line-height: 44px;
        color: #666;
      }
      .ant-form-item-children {
        position: initial;
      }
      .ant-form-item-control-wrapper {
        flex: 1;
        .ant-input {
          display: flex;
          align-items: center;
          height: 44px;
          padding: 0;
          font-size: 14px;
          line-height: 20px;
          border: none;
          &:hover {
            border: none!important;
          }
          &:focus {
            box-shadow: none;
          }
          &[disabled] {
            background-color: transparent;
          }
        }
        .has-error .ant-input {
          border: none;
        }
        .ant-form-explain {
          font-size: 12px;
        }
      }
      .ant-input-group-addon {
        border: none;
        background-color: transparent;
      }
      .form-item-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
  .upload-box {
    display: flex;
    justify-content: space-between;
    .example {
      position: relative;
      flex: none;
      width: 80px;
      height: 60px;
      margin-left: 12px;
      .img {
        display: block;
        width: 100%;
        height: 100%;
      }
      .mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
  .tip {
    margin-top: 12px;
    font-size: 12px;
    color: #c30d23;
    line-height: 18px;
  }
  .custom-upload-single {
    width: 80px;
    height: 60px;
    ::v-deep .icon-plus {
      font-size: 24px;
    }
  }
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  .btn-item {
    width: 48%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    font-size: 16px;
    color: #fff;
    background-color: #EA5514;
    &.empty {
      color: #EA5514;
      border: 1px solid #EA5514;
      background-color: transparent;
    }
  }
}
</style>
