import { SHOPID, SHOPSTAFFID } from '@/constants'

import { getSession } from '@/utils/session'
import { service } from '@/utils/request'

export default {
  uploadSingle: '/upload/image/single',
  uploadFileSingle (file) {
    return service.postForm('/upload/image/single', { file })
  },
  uploadFileMultiple (payload) {
    return service.post('/upload/file/multiple', payload)
  },
  getRegionTree (payload) {
    return service.get('/sys/region/listTree', {
      params: payload
    })
  },
  getRoomTree (type, rerentCompanyIntoId) {
    return service.get('/estate/estateBuilding/getHouseTree', {
      params: { type, rerentCompanyIntoId }
    })
  },
  getDateDuration (startDate, endDate) {
    return service.get('/company/companyInto/getSubDate', {
      params: { startDate, endDate }
    })
  },
  // 根据关键词查询类目
  getCategoryByKeyword (keyword, onlyLeaf = true) {
    return service.get('/product/category/searchByKeyword', {
      params: { keyword, onlyLeaf }
    })
  },
  // 商品类目树（一二级）
  categoryTreeInit () {
    return service.get('/product/category/initTree')
  },
  // 根据父级获取商品类目树
  getCategoryByPid (pid) {
    return service.get(`/product/category/selectByPid/${pid}`)
  },
  // 根据关键词查询结果获取全部类目
  getSearchProductCategory (idsMapStr) {
    return service.get('/product/category/clickSearchProductCategory', {
      params: { idsMapStr }
    })
  },
  // 获取物流模版
  getShopLogistics () {
    return service.get('/shopLogistics/list', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  // 短信验证码 0:PC注册    1:PC忘记密码    2:银行卡维护    3.提现申请    4:h5注册
  getSmsCode (payload) {
    return service.get('/smsSend/smsValidCode', {
      params: payload
    })
  },
  getListPartner (type = 0, needCheckAuth = true) {
    return service.get(`/shopFollow/listPartner/${getSession(SHOPID)}/${type}`, {
      params: {
        shopStaffId: needCheckAuth ? getSession(SHOPSTAFFID) : ''
      }
    })
  },
  // 查询物流公司列表
  getLogisticsCompanyList (name = '') {
    return service.get('/logistics/company/list', {
      params: { name }
    })
  },
  getProductGroupByShopId () {
    return service.get(`/productGroup/list/${getSession(SHOPID)}`)
  },
  getAppLinkList () {
    return service.get('/app/link/list')
  },
  getLinkParamsList (payload) {
    return service.get('/app/link/listObjectByCode', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  }
}
