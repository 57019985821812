<script>
export default {
  name: 'RedirectPage',
  beforeCreate () {
    const { params, query } = this.$route
    const { path } = params
    this.$router.replace({ path: path ? `/${path}` : '/', query })
  },
  render (h) {
    // avoid warning message, just render nothing
    return h()
  }
}
</script>
