<template>
  <div>
    <div class="form">
      <div class="form-title">保险卡查询</div>
      <div class="form-item">
        <p class="form-label">联系方式</p>
        <a-input type="tel" v-model="linkPhone" placeholder="请输入联系方式" />
      </div>
      <div class="form-item">
        <p class="form-label">身份证后六位</p>
        <a-input type="text" v-model="idCardCode" placeholder="请输入身份证后六位" />
      </div>
      <div class="btn-box">
        <a href="javascript:;" class="btn-item" @click="handleSearch">查询</a>
      </div>
    </div>
    <div class="card-box">
      <div
        class="card-item"
        v-for="item in list"
        :key="item.xindlCustomerId"
      >
        <img class="logo" src="./assets/logo.png" alt="">
        <p class="title">保险服务卡</p>
        <p class="number">保单号：{{item.bigPolicyNo}}</p>
        <p class="number">NO：{{item.policyNo}}</p>
        <component
          :is="getWarrantyAgreement(item.policyNo)"
          :data="item"
          :dateFormat="dateFormat"
          :unitFormat="unitFormat"
        ></component>
      </div>
      <div class="custom-empty" v-if="searched && !list.length">
        <a-empty description="查询结果为空" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { customer } from '@/api'
import moment from 'moment'
import { Toast, Dialog } from 'vant'
import 'vant/lib/toast/style'
import 'vant/lib/dialog/style'
import 'vant/lib/image-preview/style'
import WarrantyAgreement from './WarrantyAgreement'
import WarrantyAgreement2 from './WarrantyAgreement2'
import WarrantyAgreement3 from './WarrantyAgreement3'

export default {
  name: 'PageWarrantyCard',

  setup () {
    const linkPhone = ref('')
    const idCardCode = ref('')
    const searched = ref(false)
    const list = ref([])
    async function handleSearch () {
      if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(linkPhone.value)) return Toast('请输入正确的联系方式')
      if (idCardCode.value.length !== 6) return Toast('请输入身份证后六位')
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      const { code, msg, data } = await customer.getWarrantyCards({
        linkPhone: linkPhone.value,
        idCardCode: idCardCode.value
      })
      Toast.clear()
      if (code === '00000') {
        searched.value = true
        list.value = data
      } else {
        Dialog.alert({
          message: msg || '查询失败，请稍后重试'
        })
      }
    }

    function dateFormat (date) {
      return moment(date).format('YYYY年M月D日')
    }

    function unitFormat (value, unit) {
      return value ? `${value}${unit}` : ''
    }

    function getWarrantyAgreement (policyNo) {
      if (!policyNo) return WarrantyAgreement
      if (policyNo[0] === 'A' || policyNo[0] === 'a') return WarrantyAgreement2
      if (policyNo[0] === 'X' || policyNo[0] === 'x') return WarrantyAgreement3
      return WarrantyAgreement
    }

    return {
      linkPhone,
      idCardCode,
      searched,
      list,
      handleSearch,
      dateFormat,
      unitFormat,
      getWarrantyAgreement
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  margin-top: 5px;
  background-color: #fff;
}
.form-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 4px;
  font-size: 14px;
  color: #333;
  line-height: 45px;
}
.form-title::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  width: 4px;
  height: 20px;
  margin-top: -10px;
  background-color: #25A767;
}
.form-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 12px;
  font-size: 14px;
  ::v-deep .ant-input {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    border: none;
    &:hover {
      border: none!important;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
.form-item::before,
.btn-box::before {
  content: '';
  position: absolute;
  left: 12px;
  right: 12px;
  top: 0;
  z-index: 1;
  height: 1px;
  background-color: #eee;
}
.form-item .form-label {
  flex: none;
  width: 98px;
  color: #666;
}
.btn-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 48px;
  padding: 0 12px;
}
.btn-box .btn-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0 16px;
  margin-left: 12px;
  border-radius: 14px;
  font-size: 13px;
  background-color: #EA5514;
  color: #fff;
  &.empty {
    color: #EA5514;
    border: 1px solid #EA5514;
    background-color: transparent;
  }
}
.card-box {
  padding: 12px;
  ::v-deep .ant-descriptions-bordered .ant-descriptions-view > table {
    table-layout: fixed;
  }
  ::v-deep .ant-descriptions-item-content {
    word-break: break-all;
  }
}
.card-item {
  margin-bottom: 42px;
  padding: 0 12px;
  color: #333;
  &:last-child {
    margin: 0;
  }
  .logo {
    width: 100%;
  }
  ::v-deep .title {
    margin-top: 12px;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 5px;
    &.spec {
      margin-bottom: 6px;
      letter-spacing: 0;
    }
  }
  .number {
    text-align: right;
    font-size: 6px;
  }
  ::v-deep .custom-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 8px;
    tr td {
      border: 1px solid #333;
    }
  }
  ::v-deep .tip {
    font-size: 8px;
  }
}
.custom-empty {
  padding-top: 64px;
}
</style>
