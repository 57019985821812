import { service } from '@/utils/request'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'

export default {
  getGoodsOrder (payload) {
    return service.get('/shop/order/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  getBatchBuyOrder (payload) {
    return service.get('/wholesale/order/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  getBatchSellOrder (payload) {
    return service.get('/wholesale/order/my/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  getGoodsOrderAfter (payload) {
    return service.get('/shop/order/after/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  getBatchBuyOrderAfter (payload) {
    return service.get('/wholesale/order/after/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  getBatchSellOrderAfter (payload) {
    return service.get('/wholesale/order/my/after/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  getOrderDetail (shopOrderId) {
    return service.get('/shop/order/detail', {
      params: { shopOrderId }
    })
  },
  shopOrderDelivery (payload) {
    return service.post('/shop/order/delivery', payload)
  },
  batchOrderDelivery (payload) {
    return service.post('/wholesale/order/send', payload)
  },
  updateOrderDelivery (payload) {
    return service.post('/shop/order/delivery/logistics/update', payload)
  },
  updateBatchOrderDelivery (payload) {
    return service.post('/wholesale/order/logistics/update', payload)
  },
  getServiceDetail (shopOrderItemId) {
    return service.get('/shop/order/return/detail', {
      params: { shopOrderItemId }
    })
  },
  getBatchServiceDetail (orderWholesaleReturnId) {
    return service.get('/wholesale/order/return/info', {
      params: { orderWholesaleReturnId }
    })
  },
  shopOrderReturnRefuse (payload) {
    return service.postForm('/shop/order/return/refuse', payload)
  },
  shopOrderReturnPass (payload) {
    return service.postForm('/shop/order/return/pass', payload)
  },
  shopOrderReturnReceived (orderReturnApplyId) {
    return service.postForm('/shop/order/return/received', { orderReturnApplyId })
  },
  getOrderDeliveryDetail (payload) {
    return service.get('/shop/order/delivery/detail', {
      params: payload
    })
  },
  getBatchOrderDeliveryDetail (orderId) {
    return service.get('/wholesale/order/delivery/info', {
      params: { orderId }
    })
  },
  getReturnAddrByType (payload) {
    return service.get('/shopAddr/page/0', {
      params: payload
    })
  },
  deliveryWriteOff (payload) {
    return service.postForm('/shop/order/delivery/writeOff', payload)
  },
  // 取消订单
  async cancelOrder (orderId) {
    const { code, msg } = await service.postForm('/shop/order/cancel', {
      orderId
    })
    if (code !== '00000') throw new Error(msg)
  },
  // 进货端取消批发订单
  async cancelBatchOrder (orderId) {
    const { code, msg } = await service.postForm('/wholesale/order/cancel', {
      orderId
    })
    if (code !== '00000') throw new Error(msg)
  },
  // 进货端删除批发订单
  async deleteBatchOrder (orderId) {
    const { code, msg } = await service.postForm('/wholesale/order/delete', {
      orderId
    })
    if (code !== '00000') throw new Error(msg)
  },
  // 进货端确认收货
  async confirmBatchOrder (orderId) {
    const { code, msg } = await service.postForm('/wholesale/order/confirm/receipt', {
      orderId
    })
    if (code !== '00000') throw new Error(msg)
  },
  // 获取批发订单详情
  async getBatchOrderDetail (orderId) {
    const { data, code, msg } = await service.get('/wholesale/order/info/get', {
      params: {
        orderId
      }
    })
    if (code !== '00000') throw new Error(msg)
    return data
  },
  // 批发售后提交
  async rightProtectionRequest (payload) {
    const { code, msg } = await service.post('/wholesale/order/return/commit', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 批发售后审核
  batchOrderReturnUpdate (payload) {
    return service.post('/wholesale/order/return/confirm', payload)
  },
  // 批发退货发货
  batchReturnDeliveryUpdate (payload) {
    return service.post('/wholesale/order/return/delivery/update', payload)
  },
  exportOrder (params) {
    return service.get('/shop/order/exportOrder', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  importOrder (payload) {
    return service.post('/shop/order/delivery/import', payload)
  },
  exportUserAccountXhcd (params) {
    return service.get('/user/account/xhcd/export', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  importUserAccountXhcd (payload) {
    return service.post('/user/account/xhcd/import', payload)
  }
}
