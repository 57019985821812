<template>
  <div class="upload-box">
    <a-spin :spinning="uploading">
      <input
        ref="fileRef"
        class="ipt-file"
        type="file"
        accept="image/*"
        @change="handleFileChange"
      />
      <div class="icon-box" v-if="!picUrl">
        <a-icon class="icon-plus" type="plus" />
      </div>
      <img v-else class="upload-img" :src="picUrl" alt="">
    </a-spin>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import ossUpload from '@/utils/oss'

export default {
  name: 'BaseUploadSingle',

  model: {
    prop: 'url',
    event: 'update:url',
  },

  props: {
    url: String
  },

  setup (props, { root, emit }) {
    const picUrl = computed({
      get: () => props.url ? `${process.env.VUE_APP_IMG}${props.url}` : '',
      set: val => {
        emit('update:url', val)
      }
    })
    const fileRef = ref(null)
    const uploading = ref(false)
    async function handleFileChange () {
      if (!fileRef.value.files.length) return
      try {
        uploading.value = true
        const data = await ossUpload(fileRef.value.files[0])
        picUrl.value = data
      } catch (e) {
        console.error(e)
        root.$message.warn('上传失败')
      } finally {
        uploading.value = false
      }
    }

    return {
      picUrl,
      fileRef,
      uploading,
      handleFileChange
    }
  }
}
</script>

<style lang="less" scoped>
.upload-box {
  position: relative;
  width: 200px;
  height: 344px;
  border-radius: 4px;
  border: 1px dashed #d9d9d9;
  overflow: hidden;
  ::v-deep .ant-spin-nested-loading,
  ::v-deep .ant-spin-container {
    width: 100%;
    height: 100%;
  }
  .upload-img {
    width: 100%;
    height: 100%;
  }
  .icon-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .icon-plus {
      font-size: 34px;
      color: #999;
    }
  }
  .ipt-file {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
