import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/partner',
  component: AppLayout,
  children: [
    {
      path: 'my_supplier',
      component: () => import('@/views/partner/PageSupplier.vue'),
      meta: { title: '我的供应商', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'my_distributor',
      component: () => import('@/views/partner/PageDistributor.vue'),
      meta: { title: '我的分销商', noClose: false, showInMenuFlag: true }
    }
  ]
}
