import moment from 'moment'
import { common } from '@/api'
import Vue from 'vue'
import store from '../store/index'
import { ref } from '@vue/composition-api'
import { SHOPID } from '../constants'
import { getSession } from '@/utils/session'
export const trimNormalizer = x => {
  if (typeof x === 'undefined') return
  if (typeof x !== 'string') x = String(x)
  return x.trim()
}

export const listFormat = (obj, mapping) => {
  let list = []
  mapping.forEach(([title, value, format]) => {
    let valueFormat = format ? format(obj[value]) : obj[value]
    list.push({ title, value: valueFormat })
  })
  return list
}

export const getDateDuration = async (beginDate, endDate) => {
  let { code, data } = await common.getDateDuration(moment(beginDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
  if (code === 0) {
    let format = data.split(',')
    return `${format[0]}年${format[1]}月${format[2]}日`
  } else {
    return '获取时间差失败'
  }
}

export function closePage (backPath) {
  if (
    store.state.tabs.views
      .map(item => item.path)
      .indexOf(backPath) !== -1
  ) {
    Vue.prototype.$closeCurrentView()
  } else {
    Vue.prototype.$closeCurrentView(backPath)
  }
}

export function genMapFromList (list, keyName = 'value', valueName = 'name') {
  return list.reduce((acc, x) => {
    return {
      ...acc,
      [x[keyName]]: x[valueName]
    }
  }, {})
}

export function filterFields (obj, filter) {
  const result = {}
  for (const key in obj) {
    if (filter(key)) result[key] = obj[key]
  }
  return result
}

export function includeFields (obj, fields = []) {
  return filterFields(obj, key => fields.includes(key))
}

export function excludeFields (obj, fields = []) {
  return filterFields(obj, key => !fields.includes(key))
}

export function downloadFile (fetcher, defaultFileName = '下载_' + Date.now()) {
  return fetcher().then((res) => {
    console.log(res)
    const { headers, data: blob } = res
    const m = /filename=("|')?(.+)\1($|\s|;)/.exec(headers['content-disposition'])
    const serverSuggestName = m && m[2] ? decodeURIComponent(m[2]) : defaultFileName

    // IE & 旧版本Edge
    if ('msSaveOrOpenBlob' in navigator) {
      navigator.msSaveOrOpenBlob(blob, serverSuggestName)
    } else {
      const el = document.createElement('a')
      const url = URL.createObjectURL(blob)
      el.setAttribute('href', url)
      el.setAttribute('download', serverSuggestName)
      el.style.display = 'none'
      document.body.append(el)
      el.click()
      setTimeout(() => {
        URL.revokeObjectURL(url)
        el.remove()
      })
    }
  })
}

export function useExport (fetcher, defaultFileName) {
  const exporting = ref(false)
  async function exportFn () {
    exporting.value = true
    await downloadFile(fetcher, defaultFileName)
    exporting.value = false
  }
  return {
    exporting,
    exportFn
  }
}

export function wrapAwait (p) {
  return p.then(data => [undefined, data], err => [err])
}

export class Range {
  start
  end
  constructor (start, end = new EndPoint(Infinity)) {
    if (start == null) {
      throw new Error('there must be at least one param')
    }
    if (!(start instanceof EndPoint) || !(end instanceof EndPoint)) {
      throw new Error('params must be instance of EndPoint')
    }
    if (start.value > end.value ||
      (!(start.real && end.real) && start.value === end.value)) throw new Error('the range is empty')
    this.start = start
    this.end = end
  }

  isConflictWith (other) {
    if (!(other instanceof Range)) throw new Error('should pass in a Range')
    return other.start.isInRange(this) || other.end.isInRange(this)
  }

  print () {
    return `${this.start.real ? '[' : '('}${this.start.value}, ${this.end.value === Infinity ? '∞' : this.end.value}${this.end.real ? ']' : ')'}`
  }
}

export class EndPoint {
  value
  real
  constructor (value = Infinity, real = false) {
    if (value === '') value = Infinity
    value = +value
    if (Number.isNaN(value)) {
      throw new Error('please construct a EndPoint with a valid number')
    }
    this.value = value
    this.real = real
  }

  isInRange (range) {
    const { start, end } = range
    return (this.real && start.real ? this.value >= start.value : this.value > start.value) && (this.real && end.real ? this.value <= end.value : this.value < end.value)
  }
}

export function checkConflict (...ranges) {
  if (ranges.length < 2) return
  for (let i = 0, len = ranges.length; i < len - 1; i++) {
    for (let j = i + 1; j < len; j++) {
      if (ranges[i].isConflictWith(ranges[j])) {
        return [ranges[i], ranges[j]]
      }
    }
  }
}
export let isPlatform = process.env.VUE_APP_PLATFORMSHOPID && process.env.VUE_APP_PLATFORMSHOPID.indexOf(getSession(SHOPID)) !== -1

export function checkIsPlatform () {
  isPlatform = process.env.VUE_APP_PLATFORMSHOPID && process.env.VUE_APP_PLATFORMSHOPID.indexOf(getSession(SHOPID)) !== -1
}
// // test
// const A = new Range(new EndPoint(5, true), new EndPoint(7, false))
// const B = new Range(new EndPoint(7, true), new EndPoint(9, true))
// const C = new Range(new EndPoint(3, true), new EndPoint(5, false))
// const D = new Range(new EndPoint(3, true), new EndPoint(5, true))
// const E = new Range(new EndPoint(6, false), new EndPoint(100, false))
// const F = new Range(new EndPoint(0, false), new EndPoint(6, false))

// console.log(checkIfNotHaveConflict(A, B, C)) // true
// console.log(checkIfNotHaveConflict(A, B, C, D)) // false
// console.log(checkIfNotHaveConflict(A, B, C, E)) // false
// console.log(checkIfNotHaveConflict(A, B, C, F)) // false

export function createMapFromList (list, keyProp = 'value', valueProp = 'name') {
  return list.reduce((acc, x) => ({
    ...acc,
    [x[keyProp]]: x[valueProp]
  }), {})
}
