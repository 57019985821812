<template>
  <a-layout id="components-layout" style="min-height: 100vh;">
    <a-layout-sider v-model="collapsed" collapsible>
      <icon-svg class="menu-logo" icon-class="icon-menu-logo" />
      <a-menu theme="dark"
          :defaultOpenKeys='defaultOpenKeys'
          :defaultSelectedKeys='defaultSelectKeys'
          mode="inline"
          class="menu-ul">
        <template v-for="menu in menus">
          <a-sub-menu v-if="menu.children && menu.children.length" :key="menu.id">
            <template #title>
              <icon-svg
                class="anticon"
                v-if="menu.icon"
                :icon-class="menu.icon"
              />
              <span>{{menu.title}}</span>
            </template>
            <template>
              <a-menu-item
                :key="submenu.id"
                v-for="submenu in menu.children"
                @click="goPage(submenu.serverPath)"
              >· {{submenu.title}}</a-menu-item>
            </template>
          </a-sub-menu>
          <a-menu-item v-else :key="menu.id" @click="goPage(menu.serverPath)">
            <icon-svg
              class="anticon"
              v-if="menu.icon"
              :icon-class="menu.icon"
            />
            <span>{{menu.title}}</span>
          </a-menu-item>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff;padding:0">
        <app-header />
      </a-layout-header>
      <a-layout-content>
        <app-tabs @routeChange="handelCurrentRoute"></app-tabs>
        <transition name="fade" mode="out-in">
          <div class="wrap-view">
            <!-- <router-view v-if="isDev" :key="$route.fullPath"></router-view> -->
            <TimeMachine :max="20" :cachedRoutes="cachedRoutes">
              <router-view :track="$route.fullPath" :key="$route.fullPath"></router-view>
            </TimeMachine>
          </div>
        </transition>
      </a-layout-content>
      <!-- <a-layout-footer style="text-align: center">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer>-->
    </a-layout>
  </a-layout>
</template>
<script>
import AppHeader from './AppHeader'
import AppTabs from './AppTabs'
import TimeMachine from '@/components/TimeMachine.js'
export default {
  name: 'AppLayout',
  components: {
    AppHeader,
    AppTabs,
    TimeMachine
  },
  data () {
    return {
      collapsed: false,
      defaultOpenKeys: [''],
      defaultSelectKeys: [''],
      isDev: process.env.NODE_ENV === 'development',
    }
  },
  methods: {
    goPage (url) {
      if (url === this.$route.path) return
      this.$router.push(url)
    },
    handelCurrentRoute () {
      if (!this.$route.meta.showInMenuFlag && this.$route.meta.pid !== '0') {
        this.findHideMenuParentToShowInMenu(this.menus, this.$route.meta.pid)
      } else {
        this.defaultOpenKeys = [this.$route.meta.pid || '']
        this.defaultSelectKeys = [this.$route.meta.id || '']
      }
    },
    findHideMenuParentToShowInMenu (arr, id) {
      if (this.defaultSelectKeys[0] && this.defaultOpenKeys[0]) return
      arr.forEach(item => {
        if (item.id === id) {
          this.defaultOpenKeys = [item.pid]
          this.defaultSelectKeys = [item.id]
          if (this.defaultSelectKeys[0] && this.defaultOpenKeys[0] === '0') {
            this.defaultOpenKeys = this.defaultSelectKeys
          }
        } else {
          if (item.children && item.children.length) {
            this.findHideMenuParentToShowInMenu(item.children, id)
          }
        }
      })
    }
  },
  beforeMount () {
    this.handelCurrentRoute()
  },
  computed: {
    menus () {
      return this.$store.getters['route/menus']
    },
    cachedRoutes () {
      return (this.$store.state.tabs.views || []).filter(view => !view.meta.noCache).map(view => view.path)
    }
  }
}
</script>

<style lang="less" scoped>
#components-layout {
  ::v-deep .ant-layout-sider {
    background: #3e4557;
  }
  ::v-deep .ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    background: #3e4557;
  }
  ::v-deep .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background: #fff;
    color: #000;
  }
  ::v-deep .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: #fff;
    color: #000;
  }
  ::v-deep
  .ant-menu-dark .ant-menu-item-selected .anticon {
    color: #333;
  }
  ::v-deep .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #333;
  }
  ::v-deep .ant-layout-sider-trigger {
    background: #2e3442;
  }
  ::v-deep .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #303644;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.45) inset;
  }
  // ::v-deep .ant-menu-dark .ant-menu-submenu-selected {
  //   background: #fff;
  //   color: #000;
  // }
  .wrap-view {
    height: calc(100vh - 104px);
    overflow: auto;
    min-width: 900px;
    box-sizing: border-box;
    padding: 16px;
    color: #000;
  }
  .menu-logo {
    width: 100%;
    height: 32px;
    font-size: 32px;
    color: #fff;
    margin: 12px auto;
    text-align: center;
  }
}
.menu-ul {
  scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    cursor: pointer;height: calc(100vh - 104px);overflow: auto;
}
</style>
