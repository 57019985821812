<template>
  <div class='page-box'>
    <div v-html='title' class='title'></div>
    <div v-html="html"></div>
  </div>
</template>

<script>
import { guidHtmlData } from '@/utils/guidHtmlData'
export default {
  data () {
    return {
      index: 0,
      html: '',
      title: ''
    }
  },
  mounted () {
    if (this.$route.params.index !== undefined) {
      this.index = Number(this.$route.params.index)
    }
    this.html = guidHtmlData[this.index].content
    this.title = guidHtmlData[this.index].name
    this.$route.meta.title = guidHtmlData[this.index].name
  }
}
</script>

<style lang="less" scoped>
  .title {
    width: 100%;
    line-height: 64px;
    font-weight: 900;
    font-size: 18px;
    background: #fff;
    text-align: center;
  }
</style>
