<template>
  <div class="picture-card fix">
    <div v-if="!isPreview" class="ant-upload-list ant-upload-list-picture-card">
      <draggable v-model="fileListFormat" :options="{handle: draggable ? '.ant-upload-list-item' : '.disabled'}">
        <div class="ant-upload-list-item ant-upload-list-item-done" v-for="(imgItem, index) in fileListFormat" :key="`img${index}`">
          <div class="ant-upload-list-item-info">
            <span>
              <a class="ant-upload-list-item-thumbnail" href="javascript:;">
                <img :src="imgItem | format" alt="" />
              </a>
            </span>
          </div>
          <span class="ant-upload-list-item-actions">
            <a href="javascript:;" title="预览文件" @click="handlePreview(imgItem)">
              <a-icon type="eye-o" />
            </a>
          </span>
          <a href="javascript:;" class="btn-del" @click="handleDelete(index)"><a-icon type="close" /></a>
        </div>
      </draggable>
      <a-upload
        style="float: left; width: auto"
        listType="picture-card"
        accept="image/*"
        :showUploadList="false"
        :customRequest="handleCustomRequest"
      >
        <div v-if="fileList.length < this.max">
          <a-spin :spinning="uploading">
            <a-icon class="btn-upload-icon" type="plus" />
            <p class="btn-upload-text">添加图片</p>
          </a-spin>
        </div>
      </a-upload>
    </div>
    <div v-else class="ant-upload-list ant-upload-list-picture-card">
      <div class="ant-upload-list-item ant-upload-list-item-done" v-for="(imgItem, index) in fileListFormat" :key="`img${index}`">
        <div class="ant-upload-list-item-info">
          <span>
            <a class="ant-upload-list-item-thumbnail" href="javascript:;" @click="handlePreview(imgItem)">
              <img :src="imgItem | format" alt="" />
            </a>
          </span>
        </div>
        <span class="ant-upload-list-item-actions">
          <a href="javascript:;" title="预览文件" @click="handlePreview(imgItem)">
            <a-icon type="eye-o" />
          </a>
        </span>
      </div>
    </div>
    <a-modal :visible="previewVisible" :footer="null" :zIndex="12000" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ossUpload from '@/utils/oss'

export default {
  name: 'UploadImg',

  components: {
    draggable
  },

  props: {
    fileList: Array,
    max: {
      type: Number,
      default: 3
    },
    isPreview: Boolean, // 是否开启预览模式（没有上传按钮）
    draggable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    fileListFormat: {
      get () {
        return this.fileList || []
      },
      set (value) {
        this.$emit('update:fileList', value)
      }
    }
  },

  filters: {
    format: x => `${process.env.VUE_APP_IMG}${x}`
  },

  data () {
    return {
      previewVisible: false,
      previewImage: '',
      uploading: false
    }
  },

  methods: {
    handlePreview (imgUrl) {
      this.previewImage = `${process.env.VUE_APP_IMG}${imgUrl}`
      this.previewVisible = true
    },
    async handleDelete (index) {
      this.$emit('update:fileList', this.fileList.filter((x, i) => i !== index))
    },
    async handleCustomRequest (options) {
      try {
        this.uploading = true
        const data = await ossUpload(options.file)
        this.$emit('update:fileList', [...this.fileList, data])
        this.$emit('change', data)
      } catch (e) {
        console.error(e)
        this.$message.warn('上传失败')
      } finally {
        this.uploading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.custom-upload-list {
  display: flex;
}
.picture-card {
  ::v-deep .ant-upload-list-picture-card {
    .ant-upload-list-item:hover .btn-del {
      display: flex;
    }
    .ant-upload-list-item-progress {
      left: 12px;
    }
    .ant-upload-list-item-uploading-text {
      text-align: center;
    }
  }
  .btn-upload-icon {
    font-size: 18px;
  }
  .btn-upload-text {
    margin-top: 3px;
    font-size: 14px;
  }
  .btn-del {
    display: none;
    position: absolute;
    z-index: 2;
    top: -9px;
    right: -9px;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font-size: 16px;
    background: hsla(0,0%,60%,.6);
    border-radius: 10px;
    ::v-deep .anticon {
      position: relative;
      top: 0;
      right: 0;
      color: #fff;
      line-height: 0;
    }
    &:hover {
      background: #333;
    }
  }
}
</style>
