<template>
  <div>
    <div v-show="baseRoute === this.$route.path">
      <slot></slot>
    </div>
    <transition appear name="fade">
      <router-view
        v-show="baseRoute !== this.$route.path"
      ></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseSubRouteContainer',
  props: {
    baseRoute: {
      type: String,
      required: true
    }
  }
}
</script>
