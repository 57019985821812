// 验证手机号
const phoneValidate = (rule, value, callback) => {
  if (value) {
    if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value)) {
      callback(new Error('请输入正确的手机号'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}
// 银行卡
const bankCardValidate = (rule, value, callback) => {
  if (value) {
    if (!/^[0-9]\d{9,29}$/.test(value)) {
      callback(new Error('请输入正确的银行卡号'))
    } else {
      callback()
    }
  }
}
// 验证邮箱
const emailValidate = (rule, value, callback) => {
  if (value) {
    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
      callback(new Error('请输入正确的邮箱地址'))
    } else {
      callback()
    }
  }
}
// 创建店铺时 多个电话 校验
const validatorManyTel = (rule, value, callback) => {
  let hasError = ''
  value.forEach(item => {
    if (item.value) {
      let value = item.value
      if (/^(\(\d{3,4}\)|\d{3,4}-)?\d{7,8}$/.test(value) || /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value) || /^(400)-(\d{3})-(\d{4})$/.test(value)) {

      } else {
        hasError = true
      }
    }
  })
  if (hasError) {
    callback(new Error('请输入正确的固定电话或手机号'))
  } else {
    callback()
  }
}
// 验证固定电话或手机号
const telValidate = (rule, value, callback) => {
  if (value) {
    if (/^(\(\d{3,4}\)|\d{3,4}-)?\d{7,8}$/.test(value) || /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(value) || /^(400)-(\d{3})-(\d{4})$/.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的固定电话或手机号'))
    }
  } else {
    callback()
  }
}

// 身份证校验
const cardValidate = (rule, value, callback) => {
  if (value) {
    if (/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的居民身份证'))
    }
  } else {
    callback()
  }
}

// 车牌
const carNolidate = (rule, value, callback) => {
  if (value) {
    if (/^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/.test(value)) {
      callback()
    } else {
      callback(new Error('请输入正确的车牌号'))
    }
  } else {
    callback()
  }
}

const checkRatio = (rule, value, callback) => {
  if (value) {
    const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
    if (!reg.test(value)) {
      return callback(new Error('整数或最多两位小数'))
    } else if (value > 100) {
      return callback(new Error('不能超过100'))
    } else if (value < 0) {
      return callback(new Error('最少为0'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

const checkFloat = (rule, value, callback) => {
  if (value) {
    const reg = /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/
    if (!reg.test(value)) {
      return callback(new Error('整数或最多两位小数'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

const checkInteger = (rule, value, callback) => {
  if (value) {
    const reg = /^[0-9]*[1-9][0-9]*$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('整数'))
    }
  } else {
    callback()
  }
}

const checkIdCard = (rule, value, callback) => {
  if (!value) return callback()
  if (!/^[0-9X]{18}$/.test(value)) return callback(new Error('格式不正确'))

  if (policeValidate(value)) {
    callback()
  } else {
    callback(new Error('格式不正确'))
  }
}

function policeValidate (code) {
  let ratios = [
    7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2
  ]
  let sum = ratios.map((ratio, index) => ratio * code[index])
    .reduce((x, y) => x + y, 0)
  let remain = sum % 11
  let last = code[code.length - 1]
  switch (remain) {
    case 0: return last === '1'
    case 1: return last === '0'
    case 2: return last === 'X' || last === 'x'
    case 3: return last === '9'
    case 4: return last === '8'
    case 5: return last === '7'
    case 6: return last === '6'
    case 7: return last === '5'
    case 8: return last === '4'
    case 9: return last === '3'
    case 10: return last === '2'
    default: return false
  }
}

const pwdValidate = (rule, value, callback) => {
  if (value) {
    if (!/^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{8,20}$/.test(value)) {
      return callback(new Error('密码为8-20位数字或字母，且至少包含一个数字一个字母'))
    } else {
      callback()
    }
  } else {
    callback()
  }
}

export { phoneValidate, telValidate, cardValidate, carNolidate, checkRatio, checkInteger, checkIdCard, pwdValidate, validatorManyTel, emailValidate, bankCardValidate, checkFloat }
