<template>
  <a-modal
    v-model="visible"
    title="订单发货"
    width="840px"
    :maskClosable="false"
    @cancel='formDeliveryRef.formReset()'
  >
    <div class="modal-footer" slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button
        type="primary"
        :loading="confirmLoading"
        :disabled="deliveryData.hasReturnApply"
        @click="handleOk"
      >
        确定
      </a-button>
      <a-popover v-if="deliveryData.hasReturnApply">
        <span slot="content">订单存在维权未处理时，不允许进行发货操作</span>
        <a-icon class="icon-tip" type="question-circle" />
      </a-popover>
    </div>
    <a-spin :spinning="loading">
      <p class="sub-title">待发货商品</p>
      <a-table
        bordered
        :columns="columns"
        :dataSource="deliveryData.products"
        :rowKey="record => record.productId"
        :pagination="false"
      >
        <template #goodsName="record">
          <div class="goods-info">
            <base-img
              class="goods-img"
              :src="record.productSkuPic||record.productMainPic"
              width="60"
              height="60"
              oss_style="60_60"
            />
            <div>
              <p class="goods-name el2">{{record.productName}}</p>
              <p class="sku-info">规格：{{record.productSkuName}}</p>
            </div>
          </div>
        </template>
      </a-table>
      <div class="info-field">
        <p class="label">配送信息</p>
        <div class="field-content">
          <p>收货人：{{deliveryData.receiverName}} {{deliveryData.receiverPhone}}</p>
          <p>收货地址：{{deliveryData.receiverProvince}}{{deliveryData.receiverCity}}{{deliveryData.receiverCounty}} {{deliveryData.receiverAddr}}</p>
        </div>
      </div>
      <div class="info-field field2">
        <p class="label">发货信息</p>
        <div class="field-content">
          <form-delivery ref="formDeliveryRef" />
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { order } from '@/api'
import FormDelivery from './FormDelivery'

const columns = [
  {
    title: '商品名称',
    scopedSlots: {
      customRender: 'goodsName'
    }
  }, {
    title: '数量(件)',
    dataIndex: 'count'
  }
]
export default {
  name: 'DialogDelivery',

  props: {
    isBatch: Boolean,
    show: Boolean,
    deliveryInfo: [Object, String], // isBatch为true时，代表orderId
  },

  components: {
    FormDelivery
  },

  setup (props, { root, emit }) {
    const visible = computed({
      get: () => props.show,
      set: val => {
        emit('update:show', val)
      }
    })
    watch(() => props.deliveryInfo, data => {
      if (props.isBatch) {
        getBatchDetail(props.deliveryInfo)
      } else if (data && data.shopOrderId && data.orderInfoId) {
        getDetail(data.shopOrderId, data.orderInfoId)
      }
    })

    const loading = ref(false)
    const deliveryData = ref({})
    async function getDetail (shopOrderId, orderInfoId) {
      loading.value = true
      const { code, msg, data } = await order.getOrderDeliveryDetail({ shopOrderId, orderInfoId })
      loading.value = false
      if (code === '00000') {
        deliveryData.value = data
      } else {
        root.$message.error(msg || '发货信息获取失败，请稍后重试')
      }
    }
    function handleCancel () {
      formDeliveryRef.value.formReset()
      visible.value = false
    }
    async function getBatchDetail (orderId) {
      loading.value = true
      const { code, msg, data } = await order.getBatchOrderDeliveryDetail(orderId)
      loading.value = false
      if (code === '00000') {
        deliveryData.value = {
          ...data,
          products: [
            {
              productSkuPic: data.productSkuPic,
              productMainPic: data.productMainPic,
              productName: data.productName,
              productSkuName: data.productSkuName,
              count: data.count,
              productId: data.productId,
            }
          ]
        }
      } else {
        root.$message.error(msg || '发货信息获取失败，请稍后重试')
      }
    }

    const formDeliveryRef = ref(null)
    const confirmLoading = ref(false)
    async function handleOk () {
      try {
        const params = await formDeliveryRef.value.handleSubmit()
        confirmLoading.value = true
        const { code } = await (props.isBatch ? order.batchOrderDelivery({
          orderId: props.deliveryInfo,
          ...params
        }) : order.shopOrderDelivery({
          shopOrderId: props.deliveryInfo.shopOrderId,
          orderInfoId: props.deliveryInfo.orderInfoId,
          ...params
        }))
        confirmLoading.value = false
        if (code !== '00000') return root.$message.error('提交失败，请稍后重试')
        root.$message.success('发货成功')
        formDeliveryRef.value.formReset()
        visible.value = false
        emit('update')
      } catch (e) {
        console.error(e.message || '提交失败')
      }
    }

    return {
      columns,
      handleCancel,
      visible,
      loading,
      deliveryData,
      formDeliveryRef,
      confirmLoading,
      handleOk
    }
  }
}
</script>

<style lang="less" scoped>
.sub-title {
  color: #333;
  font-weight: 700;
  line-height: 32px;
}
.goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    margin-right: 12px;
  }
  .goods-name {
    flex: none;
    max-width: 500px;
    font-size: 14px;
    line-height: 22px;
    color: #0066FF;
  }
  .sku-info {
    max-width: 130px;
    font-size: 12px;
  }
}
.info-field {
  display: flex;
  margin-top: 12px;
  line-height: 24px;
  color: #333;
  &.field2 .label {
    margin-top: 8px;
  }
  .label {
    flex: none;
    width: 80px;
  }
  .field-content {
    flex: 1;
  }
}
.icon-tip {
  margin-left: 6px;
  color: #cacaca;
}
</style>
