<template>
  <div>
    <div class="head-box">
      <img src="@/assets/login-logo.png" class="login-logo" alt />
      <div class="menu-box">
        <a href="http://www.hongse.tech/" target="_blank">官方网站</a>
        <a href="http://www.hongse.tech/about/index.html" target="_blank">联系我们</a>
      </div>
    </div>
    <div class="login-container">
      <transition name="zoom" appear>
        <div class="wrap-login-form">
          <p class="form-title">登录</p>
          <a-form-model
            style="width: auto"
            ref="formRef"
            :model="form"
            @submit.native.prevent
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            labelAlign="left"
            :rules="rules"
          >
            <a-form-model-item prop="loginName">
              <a-input v-model="form.loginName" placeholder="手机号" @keyup.enter="handleSubmit"></a-input>
            </a-form-model-item>
            <a-form-model-item prop="loginPwd">
              <a-input v-model="form.loginPwd" type="password" placeholder="请输入密码" @keyup.enter="handleSubmit"></a-input>
            </a-form-model-item>
            <a-form-model-item prop="verifyCode">
              <a-row>
                <a-col :span="14">
                  <a-input @change="changeVerifyCode" v-model="form.verifyCode" placeholder="请输入验证码" @keyup.enter="handleSubmit"></a-input>
                </a-col>
                <a-col :span="8" :offset='2'>
                  <base-img
                    width="100"
                    height="40"
                    class="verify-img"
                    v-if="verifyImg"
                    :src="verifyImg"
                    @click.native="getVerifyCode"
                  />
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-form-model>
          <div>
            <a-button
              type="primary"
              class="big-button"
              @click="handleSubmit"
              :loading="submitting"
            >登录</a-button>
            <div class="register-row">
              <div>
                没有账号？
                <a-button @click="goRegister" type="link" style="padding:0">去注册</a-button>
              </div>
              <div>
                <a-button @click="forgetPassword" type="link" style="padding:0">忘记密码</a-button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { user } from '@/api'
// import { phoneValidate } from '@/utils/validator'
import { NICKNAME, SHOPUSERID, PHONE } from '@/constants'
import { setSession } from '@/utils/session'
import { reactive, toRefs, onMounted } from '@vue/composition-api'
export default {
  name: 'AppLogin',
  setup (props, { root }) {
    const labelCol = { span: 0 }
    const wrapperCol = { span: 24 }
    const rules = {
      loginName: [
        { required: true, message: '请输入手机号', trigger: 'change' }
      ],
      verifyCode: [
        { required: true, message: '请输入验证码', trigger: 'change' }
      ],
      loginPwd: [{ required: true, message: '请输入密码', trigger: 'change' }]
    }
    const state = reactive({
      redirect: '/select_shop',
      form: {},
      submitting: false,
      interval: null,
      formRef: null,
      isGetSms: false,
      verifyKey: '',
      verifyImg: ''
    })
    onMounted(() => {
      if (process.env.NODE_ENV === 'development') {
        state.form = { loginName: '18553511139', loginPwd: 'jereh123', verifyCode: '' }
      }
      localStorage.clear()
      state.redirect = decodeURIComponent(
        (root.$route.query && root.$route.query.redirect) || '/select_shop'
      )
      getVerifyCode()
    })
    function goRegister () {
      root.$router.push('/register')
    }
    async function getVerifyCode () {
      let {
        data: { verifyKey, verifyFile }
      } = await user.getVerifyCode()
      state.verifyImg = 'data:image/jpg;base64,' + verifyFile
      state.verifyKey = verifyKey
    }
    function forgetPassword () {
      root.$router.push('/forget_pwd')
    }
    function changeVerifyCode () {
      state.formRef.validateField('verifyCode')
    }
    function handleSubmit () {
      if (state.submitting) return
      state.formRef.validate(async valid => {
        if (valid) {
          state.submitting = true
          let { code, msg, data } = await user.login({
            ...state.form,
            verifyKey: state.verifyKey
          })
          state.submitting = false
          if (code === '00000') {
            setSession(NICKNAME, data.nickName)
            setSession(SHOPUSERID, data.shopUserId)
            setSession(PHONE, data.loginName)
            root.$message.success('登录成功')
            root.$router.replace(state.redirect)
          } else {
            root.$message.error(msg || '登录失败')
            getVerifyCode()
          }
        }
      })
    }
    return {
      ...toRefs(state),
      rules,
      labelCol,
      wrapperCol,
      goRegister,
      getVerifyCode,
      forgetPassword,
      handleSubmit,
      changeVerifyCode
    }
  }
}
</script>

<style lang="less" scoped>
.head-box {
  height: 110px;
  width: 1200px;
  padding-top: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .login-logo {
    height: 48px;
    object-fit: contain;
  }
  .menu-box {
    display: flex;
    align-items: center;
    a {
      display: block;
      border-right: 1px solid #ddd;
      padding: 0 16px;
      line-height: 16px;
    }
    a:last-child {
      border: none;
    }
  }
}
.register-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  text-align: right;
}
.login-container {
  min-height: calc(100vh - 110px);
  position: relative;

  @keyframes be-real {
    to {
      filter: none;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("../assets/login-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: -1;
    animation: be-real 0.8s 0.3s ease forwards;
  }
}
.wrap-login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @bg-color: #fff;
  position: absolute;
  top: 60px;
  z-index: 1;
  width: 420px;
  right: 8vw;
  box-sizing: border-box;
  background-color: @bg-color;
  border-radius: 8px;
  padding: 16px 40px;
  height: 460px;
  @keyframes spin {
    from {
      opacity: 0;
    }
    to {
      transform: rotate(2turn);
    }
  }
  .form-title {
    font-size: 28px;
    padding-top: 14px;
    text-align: center;
  }
  ::v-deep .ant-input {
    border-radius: 2px;
    height: 40px;
    line-height: 40px;
  }
  ::v-deep .ant-form-explain {
    text-align: left;
  }
}
.big-button {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  font-size: 20px;
}
</style>
