<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="标题" prop='title'>
          <a-input v-model="params.title" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="阅读状态" prop='readState'>
          <a-select
            v-model="params.readState"
            placeholder="请选择"
            style="min-width: 120px"
          >
            <a-select-option :value="0">未读</a-select-option>
            <a-select-option :value="1">已读</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="消息类型" prop='type'>
          <a-select
            v-model="params.type"
            placeholder="请选择"
            style="min-width: 200px"
          >
            <a-select-option v-for='item in IMType' :key='item.dictItemId' :value="item.dictItemCode">{{item.dictItemName}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box tr">
      <a-button type="primary" :loading='btnLoading' :disabled='selectedRowKeys.length === 0' @click="readMsg($event)">批量标注已读</a-button>
    </div>
    <a-table bordered :pagination='{
        current: current,
        pageSize: size,
        total: total
      }' :columns="columns" messageReceiveId :rowKey="record => record.messageReceiveId" :dataSource="tableDate" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange ,getCheckboxProps:getCheckboxProps}" :loading="tableLoading" @change="handleTableChange">
      <template  slot="level" slot-scope="text, record">
        <div class='table-level' :style="{color: colors[record.level],borderColor: colors[record.level]}">{{record.level + 1}}</div>
      </template>
      <template  slot="content" slot-scope="text, record">
        {{record.content}}
        <a-button type='link' v-if='record.type === 10' @click='goCollect(record.rid)'>去采集</a-button>
        <a-button type='link' v-if='record.type === 8' @click='goOrder(record.rid)'>查看</a-button>

        <a-button type='link' v-if='record.type === 12||record.type === 13' @click='goReturn(record.rid)'>去处理</a-button>
      </template>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" :loading='selectSingleId === record.messageReceiveId' @click='readMsg($event,record.messageReceiveId)' :disabled='record.readState === 1'>标注已读</a-button>
      </div>
    </a-table>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, onUnmounted } from '@vue/composition-api'
import { system, dict } from '@/api'
import { SHOPSTAFFID } from '@/constants'
import { getSession } from '@/utils/session'
const columns = [
  {
    title: '级别',
    dataIndex: 'level',
    scopedSlots: { customRender: 'level' }
  },
  {
    title: '标题',
    dataIndex: 'title',
    scopedSlots: { customRender: 'title' }
  },
  {
    title: '内容',
    dataIndex: 'content',
    scopedSlots: { customRender: 'content' }
  },
  {
    title: '发布时间',
    dataIndex: 'sendTime'
  },
  {
    title: '阅读状态',
    dataIndex: 'readState',
    customRender: (x) => {
      return x === 0 ? '未读' : '已读'
    }
  },
  {
    title: '操作',
    width: 200,
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'messagePage',
  setup (props, { root }) {
    const state = reactive({
      shopStaffId: getSession(SHOPSTAFFID),
      params: {
        shopStaffId: getSession(SHOPSTAFFID),
        title: '',
        readState: undefined,
        type: undefined
      },
      formRef: null,
      current: 1,
      size: 10,
      total: 0,
      tableLoading: false,
      tableDate: [],
      selectedRowKeys: [],
      selectSingleId: '',
      btnLoading: false,
      colors: ['#40a9ff', '#ff9400', '#ff4d4f'],
      IMType: []
    })
    const callback = () => { getData() }
    onUnmounted(() => {
      root.$bus.$off('PageMessageList:refresh', callback)
    })
    onMounted(() => {
      getData()
      getIMType()
      root.$bus.$on('PageMessageList:refresh', callback)
    })
    async function getIMType () {
      const { data } = await dict.getDict({
        dictTypeCode: 'IMType'
      })
      state.IMType = data.filter(x => x.seqNum > 0)
    }
    async function getData () {
      state.tableLoading = true
      const { data, msg, code, page } = await system.getMsgPage({
        ...state.params,
        size: state.size,
        current: state.current
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableDate = data
        state.current = page.pageNo
        state.size = page.pageSize
        state.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange ({ current }) {
      state.current = current
      getData()
    }
    async function readMsg (e, id) {
      let ids = []
      if (id !== undefined) {
        // 单个
        state.selectSingleId = id
        ids = [id]
      } else {
        // 批量
        ids = state.selectedRowKeys
        state.btnLoading = true
      }
      const { code, msg } = await system.readMsg({
        messageReceiveId: ids.join(','),
        type: 1,
      })
      state.btnLoading = false
      state.selectSingleId = ''
      if (code === '00000') {
        root.$message.success('操作成功')
        root.$bus.$emit('PageMessage:refresh')
        if (state.selectedRowKeys.length) {
          state.selectedRowKeys = []
        }
        handleReset()
      } else {
        root.$message.error(msg || '操作失败')
      }
    }
    function onSelectChange (e) {
      state.selectedRowKeys = e
    }
    function handleReset () {
      state.formRef.resetFields()
      getData()
    }
    function handleSearch () {
      state.current = 1
      getData()
    }
    function getCheckboxProps (record) {
      return {
        props: {
          disabled: record.readState === 1
        }
      }
    }
    function goCollect (productId) {
      root.$router.push(`/goods/goods_collect_detail/${window.btoa(JSON.stringify({ productId }))}`)
    }
    function goReturn (id) {
      root.$router.push(`/order/goods_order/service_detail/${id}`)
    }
    function goOrder (id) {
      root.$router.push(`/order/goods_order/detail/${id}`)
    }

    return {
      goOrder,
      columns,
      ...toRefs(state),
      getData,
      handleTableChange,
      readMsg,
      onSelectChange,
      handleReset,
      handleSearch,
      getCheckboxProps,
      goCollect,
      getIMType,
      goReturn
    }
  }
}
</script>

<style lang="less" scoped>
.btn-box {
  margin-bottom: 16px;
}
.table-level {
  font-weight:900;font-size:14px;border:2px solid #000;line-height:20px;width:24px;text-align:center;border-radius: 100%;box-sizing:border-box;color:#000
}
</style>
