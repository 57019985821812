<template>
  <div class="base-sub-title">
    <div class="title">{{title}}</div>
    <a
      href="javascript:;"
      v-if="hasBack"
      class="btn-back"
      slot="tabBarExtraContent"
      @click="$closeCurrentView()"
    >
      <icon-svg class="icon-back" icon-class="icon-back"></icon-svg>返回
    </a>
  </div>
</template>

<script>
export default {
  name: 'BaseSubTitle',

  props: {
    title: String,
    hasBack: Boolean
  }
}
</script>

<style lang="less" scoped>
.base-sub-title {
  margin-bottom: 18px;
  background-color: #f3f6f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 48px;
  padding: 0 16px;
  margin-bottom: 18px;
  .title {
    font-size: 16px;
    font-weight: 900;
  }
  .btn-back {
    padding-top: 3px;
    margin-right: 8px;
    line-height: 48px;
    flex-shrink: 0;
    &:hover {
      color: @main-color;
      .icon-back {
        color: @main-color;
      }
    }
  }
}
</style>
