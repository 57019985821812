import Dashboard from '@/views/AppDashboard'
import { ADD_TAB, DEL_ALL_OTHER_TABS, DEL_ALL_TABS, DEL_ONLY_CACHED_TAB, DEL_TAB, UPDATE_TAB } from '../mutation-types'
const dashboard = {
  path: '/',
  name: 'Dashboard',
  component: Dashboard,
  meta: { title: '系统首页', noClose: true, showInMenuFlag: true }
}
export default {
  namespaced: true,
  state: {
    views: [dashboard],
    cachedViews: [dashboard.name]
  },
  mutations: {
    /**
     * 添加一个tab
     * @param {Array} state
     * @param {Object} view {title: string, name: string, noClose: boolean, path: string}
     */
    [ADD_TAB] (state, view) {
      if (!state.views.some(x => x.path === view.path)) {
        state.views.push({ ...view })
      }
      if (view.noCache) return
      if (!state.cachedViews.some(x => x === view.name)) {
        state.cachedViews.push(view.name)
      }
    },
    [DEL_TAB] (state, index) {
      state.views.splice(index, 1)
      state.cachedViews.splice(index, 1)
    },
    [DEL_ONLY_CACHED_TAB] (state, name) {
      let index = state.cachedViews.findIndex(x => x === name)
      state.cachedViews.splice(index, 1)
    },
    [DEL_ALL_TABS] (state) {
      state.views = state.views.filter(x => x.path === '/')
      state.cachedViews = state.cachedViews.filter(x => x === dashboard.name)
    },
    [DEL_ALL_OTHER_TABS] (state, view) {
      state.views = state.views.filter(x => x.path === '/' || x.path === view.path)
      state.cachedViews = state.cachedViews.filter(x => x === dashboard.name || x === view.name)
    },

    [UPDATE_TAB] (state, view) {
      let found = state.views.find(x => view.path.startsWith(x.path))
      let oldCachedView = found.name
      if (found) {
        Object.assign(found, view)
        let index = state.cachedViews.findIndex(x => x === oldCachedView)
        state.cachedViews.splice(index, 1, view.name)
      }
    }
  },
  actions: {
    addTab ({ commit }, view) {
      commit(ADD_TAB, view)
    },
    /**
     * @return {(number, string)} 剩余view的数量，被删除视图左手边的视图的path
     */
    delTab ({ commit, state }, view) {
      let index = state.views.findIndex(x => x.path === view.path)
      if (index !== -1) {
        commit(DEL_TAB, index)
        return state.views
      } else {
        throw new Error('action DEL_TAB error')
      }
    },
    delOnlyCachedTab ({ commit, state }, view) {
      let found = state.views.find(x => x.path === view.path)
      if (found) {
        commit(DEL_ONLY_CACHED_TAB, found.name)
      } else {
        throw new Error('action DEL_TAB error')
      }
    },
    delAllTabs ({ commit }) {
      commit(DEL_ALL_TABS)
    },
    delAllOtherTabs ({ commit }, view) {
      commit(DEL_ALL_OTHER_TABS, view)
    },
    updateTab ({ commit }, view) {
      commit(UPDATE_TAB, view)
    }
  }
}
