function pruneCache (timeMachineInstance, filter) {
  const { cache, _vnode } = timeMachineInstance
  cache.forEach((_, key) => {
    const cachedNode = cache.get(key)
    if (cachedNode && !filter(key)) {
      pruneCacheEntry(cache, key, _vnode)
    }
  })
}

function pruneCacheEntry (cache, key, current) {
  const cached = cache.get(key)
  if (cached && (!current || cached.data.attrs.track !== current.data.attrs.track)) {
    cached.componentInstance.$destroy()
  }
  cache.delete(key)
}

function getFirstComponentChild (children) {
  if (Array.isArray(children)) {
    for (let i = 0; i < children.length; i++) {
      const c = children[i]
      if (isDef(c) && (isDef(c.componentOptions) || isAsyncPlaceholder(c))) {
        return c
      }
    }
  }
}

function isAsyncPlaceholder (node) {
  return node.isComment && node.asyncFactory
}

function isDef (v) {
  return v != null
}

// use `track` attr [!important]
function getRoute (attrs) {
  const track = attrs.track
  if (!track) throw new Error('You must specify `track` attr on <router-view> com below <time-machine>')
  return track
}

export default {
  name: 'time-machine',

  props: {
    cachedRoutes: Array,
    max: Number
  },

  created () {
    this.cache = new Map()
  },

  destroyed () {
    this.cache.forEach((_, key) => {
      pruneCacheEntry(this.cache, key)
    })
  },

  mounted () {
    this.$watch('cachedRoutes', val => {
      pruneCache(this, key => val.includes(key))
    })
  },

  render () {
    const slot = this.$slots.default
    const vnode = getFirstComponentChild(slot)
    const attrs = vnode && vnode.data.attrs
    if (attrs) {
      const route = getRoute(attrs)
      const { cachedRoutes } = this
      if (!cachedRoutes.includes(route)) {
        return vnode
      }

      const { cache } = this
      const key = route
      if (cache.has(key)) {
        const cachedVnode = cache.get(key)
        vnode.componentInstance = cachedVnode.componentInstance
        // make current key freshest
        cache.delete(key)
        cache.set(key, cachedVnode)
      } else {
        cache.set(key, vnode)
        // prune oldest entry
        if (this.max && cache.keys().length > parseInt(this.max)) {
          pruneCacheEntry(cache, cache.keys()[0], this._vnode)
        }
      }
      vnode.data.keepAlive = true
    }
    return vnode || (slot && slot[0])
  }
}
