<template>
  <div class="form-delivery">
    <a-form-model ref="formRef" layout="inline" :model="form" :rules="rules">
      <a-row>
        <a-form-model-item label="配送方式" prop="deliveryMode">
          <a-radio-group v-model="form.deliveryMode">
            <a-radio  :value="0">
              物流配送
            </a-radio>
            <a-radio  :value="1">
              自行配送
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-row>
      <template v-if="form.deliveryMode === 0">
        <a-row>
          <a-form-model-item label="物流公司" prop="logisticsCompCode">
            <a-spin class="custom-spin" :spinning="firstLoading" size="small">
              <a-select v-model="form.logisticsCompCode" show-search placeholder="请选择" style="width:200px" :filter-option="false" :not-found-content="logisticsCompanyLoading ? undefined : null" @search="getLogisticsCompanyList" @blur="handleBlur" @popupScroll="popupScroll">
                <a-spin v-if="logisticsCompanyLoading" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in logisticsCompanyList" :key="d.logisticsCode">{{d.logisticsName}}</a-select-option>
              </a-select>
            </a-spin>
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item label="物流单号" prop="logisticsNo">
            <a-input v-model="form.logisticsNo" style='width:200px' allowClear placeholder="请输入" />
          </a-form-model-item>
        </a-row>
      </template>
      <template v-else-if="form.deliveryMode === 1">
        <a-row>
          <a-form-model-item label="配送人" prop="deliveryName">
            <a-input v-model="form.deliveryName" style='width:200px' allowClear placeholder="请输入" />
          </a-form-model-item>
        </a-row>
        <a-row>
          <a-form-model-item label="联系电话" prop="deliveryPhone">
            <a-input v-model="form.deliveryPhone" style='width:200px' allowClear placeholder="请输入" />
          </a-form-model-item>
        </a-row>
      </template>
    </a-form-model>
  </div>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { common } from '@/api'
import debounce from 'lodash/debounce'
import { phoneValidate } from '@/utils/validator'
export default {
  name: 'FormDelivery',

  props: {
    deliveryInfo: Object,
  },

  setup (props) {
    const formRef = ref(null)
    const form = ref({
      logisticsCompCode: undefined,
      logisticsNo: '',
      deliveryMode: 0,
      deliveryName: '',
      deliveryPhone: ''
    })
    const rules = ref({
      logisticsCompCode: [{ required: true, message: '请选择' }],
      logisticsNo: [
        { required: true, message: '请输入' },
        { max: 32, message: '请输入简短一些' },
      ],
      deliveryMode: [{ required: true, message: '请选择' }],
      deliveryPhone: [{ required: true, message: '请输入' }, { validator: phoneValidate, message: '格式错误' }],
      deliveryName: [{ required: true, message: '请输入' }],

    })
    const firstLoading = ref(false)
    const logisticsCompanyLoading = ref(false)
    const logisticsCompanyList = ref([])
    const logisticsCompanyAll = ref([])
    const getLogisticsCompanyList = debounce(async (name, logisticsCompCode) => {
      logisticsCompanyList.value = []
      if (logisticsCompCode) {
        firstLoading.value = true
      } else {
        logisticsCompanyLoading.value = true
      }
      const { data } = await common.getLogisticsCompanyList(name)
      firstLoading.value = false
      logisticsCompanyLoading.value = false
      logisticsCompanyList.value = data ? data.slice(0, 100) : []
      logisticsCompanyAll.value = data || []
      if (logisticsCompCode) form.value.logisticsCompCode = logisticsCompCode
    }, 800)

    function handleBlur () {
      if (logisticsCompanyList.value.length > 100) {
        logisticsCompanyList.value = logisticsCompanyAll.slice(0, 100)
      }
    }

    let scrollPage = 1
    function popupScroll (e) {
      const { target } = e
      const rmHeight = target.scrollHeight - target.scrollTop
      const clHeight = target.clientHeight
      if (rmHeight === 0 && clHeight === 0) {
        scrollPage = 1
      } else {
        if (rmHeight < clHeight + 5) {
          scrollPage = scrollPage + 1
          logisticsCompanyList.value = logisticsCompanyAll.value.slice(0, scrollPage * 100)
        }
      }
    }
    watch(
      () => props.deliveryInfo,
      (data) => {
        const { logisticsCompCode, logisticsNo, deliveryMode, deliveryName, deliveryPhone } = data
        getLogisticsCompanyList('', logisticsCompCode || '')
        if (logisticsNo) form.value.logisticsNo = logisticsNo
        form.value.deliveryMode = deliveryMode
        form.value.deliveryName = deliveryName || ''
        form.value.deliveryPhone = deliveryPhone || ''
      }
    )
    onMounted(() => {
      if (props.deliveryInfo && Object.keys(props.deliveryInfo).length) {
        const { logisticsCompCode, logisticsNo, deliveryMode, deliveryName, deliveryPhone } = props.deliveryInfo
        getLogisticsCompanyList('', logisticsCompCode || '')
        if (logisticsNo) form.value.logisticsNo = logisticsNo
        form.value.deliveryMode = deliveryMode
        form.value.deliveryName = deliveryName || ''
        form.value.deliveryPhone = deliveryPhone || ''
      } else {
        getLogisticsCompanyList()
      }
    })

    function formReset () {
      form.value.logisticsCompCode = undefined
      form.value.logisticsNo = ''
    }

    function handleSubmit () {
      return new Promise((resolve, reject) => {
        formRef.value.validate((valid) => {
          if (valid) {
            resolve({
              logisticsCompName: form.value.deliveryMode === 0 ? logisticsCompanyList.value.find(
                (x) => x.logisticsCode === form.value.logisticsCompCode
              ).logisticsName : '',
              ...form.value,
            })
            formRef.value.resetFields()
          } else {
            reject(new Error('表单校验失败'))
          }
        })
      })
    }

    return {
      formRef,
      form,
      rules,
      firstLoading,
      logisticsCompanyLoading,
      logisticsCompanyList,
      getLogisticsCompanyList,
      handleBlur,
      popupScroll,
      formReset,
      handleSubmit,
    }
  },
}
</script>

<style lang="less" scoped>
.form-delivery {
  .custom-spin {
    ::v-deep > div > .ant-spin .ant-spin-dot {
      left: initial;
      right: 18px;
    }
  }
  ::v-deep .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0;
  }
  ::v-deep .ant-form-item-required::before {
    display: none;
  }
}
::v-deep .ant-form-item-label {
  width: 5em;
}
</style>
