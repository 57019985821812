import { service } from '@/utils/request'

export default {
  saveTemplate (payload) {
    return service.post('/shopLogistics/save', payload)
  },
  getTemplatePage (payload) {
    return service.get('/shopLogistics/page', {
      params: payload
    })
  },
  deleteTemplate (payload) {
    return service.postForm('/shopLogistics/delete', payload)
  },
  copyTemplate (payload) {
    return service.postForm('/shopLogistics/copyTemplate', payload)
  },
  getTemplateDetail (payload) {
    return service.get('/shopLogistics/get', {
      params: payload
    })
  },
  getGoodsList (payload) {
    return service.get('/product/listProductByLogistics', {
      params: payload
    })
  }
}
