import Dashboard from '@/views/AppDashboard'
import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/',
  component: AppLayout,
  children: [
    {
      path: '',
      component: Dashboard,
      meta: { title: '系统首页', noClose: false, showInMenuFlag: false }
    }
  ]
}
