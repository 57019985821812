<template>
  <div>
    <a-modal v-model="visible" title="修改密码" @ok='submit' :confirmLoading='submitLoading' okText='提交' @cancel="closeModal">
      <a-form-model
        style="width: 520px"
        ref="formRef"
        :model="form"
        @submit.native.prevent
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :maskClosable='false'
        :rules="rules">
        <a-form-model-item label='原密码' prop="oldPwd">
          <a-input v-model="form.oldPwd" type="password" placeholder="请输入原密码"></a-input>
        </a-form-model-item>
        <a-form-model-item label='新密码' prop="loginPwd">
          <a-input v-model="form.loginPwd" type="password" placeholder="请输入新密码"></a-input>
        </a-form-model-item>
        <a-form-model-item label='重复密码' prop="reLoginPwd">
          <a-input v-model="form.reLoginPwd" type="password" placeholder="请重复输入新密码"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { user } from '@/api'
import { SHOPUSERID } from '@/constants'
import { getSession } from '@/utils/session'
import { reactive, toRefs, computed } from '@vue/composition-api'
import { pwdValidate } from '@/utils/validator'
export default {
  name: 'ChangePwd',
  props: {
    show: Boolean
  },
  setup (props, { root, emit }) {
    const visible = computed({
      get: () => props.show,
      set: val => {
        emit('closeModal')
      }
    })
    const rePwdValidate = (rule, value, callback) => {
      if (value) {
        if (value !== state.form.loginPwd) {
          callback(new Error('两次密码输入不一致'))
        } else {
          callback()
        }
      }
    }
    const labelCol = { span: 4 }
    const wrapperCol = { span: 16 }
    const rules = {
      oldPwd: [
        { required: true, message: '请输入原密码', trigger: 'change' }
      ],
      loginPwd: [{ required: true, message: '请输入新密码', trigger: 'change' },
        { validator: pwdValidate, trigger: 'change' }],
      reLoginPwd: [
        { required: true, message: '请重复输入新密码', trigger: 'change' },
        { validator: rePwdValidate, trigger: 'change' }
      ]
    }
    const state = reactive({
      form: {},
      formRef: null,
      submitLoading: false
    })
    function submit () {
      state.formRef.validate(async valid => {
        if (valid) {
          state.submitLoading = true
          let { code, msg } = await user.changePwd(
            {
              userId: getSession(SHOPUSERID),
              oldPwd: state.form.oldPwd,
              newPwd: state.form.loginPwd
            }
          )
          state.submitLoading = false
          if (code === '00000') {
            root.$message.success('修改密码成功')
            state.form = {}
            closeModal()
          } else {
            this.$message.error(msg || '修改密码失败')
          }
        }
      })
    }
    function closeModal () {
      emit('closeModal')
    }
    return {
      labelCol,
      wrapperCol,
      visible,
      rules,
      ...toRefs(state),
      submit,
      closeModal
    }
  }
}
</script>
