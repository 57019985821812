<template>
  <a-spin tip="Loading..." :spinning="formLoading">
    <a-form-model
      :loading="formLoading"
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="店铺LOGO：" prop="logo">
        <upload-img v-if='$route.path === "/create_shop"' :fileList="form.logo?[form.logo]:[]" @update:fileList='res=>form.logo=res.length?res[0]:""' :max="1" />
        <picture-card-upload v-else :fileList="form.logo?[form.logo]:[]" @update:fileList='res=>form.logo=res.length?res[0]:""' :max="1" />
        <p class="tips">建议尺寸60*60像素</p>
      </a-form-model-item>
      <a-form-model-item label="店铺名称：" prop="name">
        <a-input v-model="form.name" allowClear placeholder="店铺名称" />
        <p class="tips">店铺名称应尽量简洁易记，不能超过15个字</p>
      </a-form-model-item>
      <a-form-model-item label="店铺简介：" prop="intro">
        <a-input
          v-model="form.intro"
          allowClear
          :autoSize="{minRows:4}"
          placeholder="店铺简介"
          type="textarea"
        />
        <p class="tips" style="margin-top:-8px;">展示店铺信息，建议60字以内</p>
      </a-form-model-item>
      <a-form-model-item label="联系人：" prop="linkMan">
        <a-input v-model="form.linkMan" allowClear placeholder="联系人" />
      </a-form-model-item>
      <a-form-model-item label="联系电话：" prop="linkPhone">
        <a-input v-model="form.linkPhone" allowClear placeholder="联系电话" />
      </a-form-model-item>
      <a-form-model-item label="客服电话：" prop="serviceTel" :wrapperCol="{span: 20}">
        <a-row align="middle" type="flex" v-for="(item,index) in form.serviceTel" :key="index">
          <a-col style="width: 90%">
            <a-input v-model="item.value" allowClear placeholder="客服电话" />
          </a-col>
          <a-col v-if="index !== 0" @click="delServiceTel(index)" :span="1">
            <a-icon theme="filled" type="minus-circle" class="add-phone" />
          </a-col>
          <a-col
            v-if="index === 0 && form.serviceTel.length<5"
            @click="addServiceTel"
            :span="1"
          >
            <a-icon theme="filled" type="plus-circle" class="add-phone" />
          </a-col>
        </a-row>
        <p class="tips">请正确填写客服联系方式，多个请点击后面加号，最多不超过5个</p>
      </a-form-model-item>
      <a-form-model-item label="售后电话：" prop="afterServiceTel" :wrapperCol="{span: 20}">
        <a-row align="middle" type="flex" v-for="(item,index) in form.afterServiceTel" :key="index">
          <a-col style="width: 90%">
            <a-input v-model="item.value" allowClear placeholder="售后电话" />
          </a-col>
          <a-col v-if="index !== 0" @click="delAfterServiceTel(index)" :span="1" >
            <a-icon theme="filled" type="minus-circle" class="add-phone" />
          </a-col>
          <a-col
            v-if="index === 0 && form.afterServiceTel.length<5"
            @click="addAfterServiceTel"
            :span="1"
          >
            <a-icon theme="filled" type="plus-circle" class="add-phone" />
          </a-col>
        </a-row>
        <p class="tips">请正确填写售后支持联系方式，多个请点击后面加号，最多不超过5个</p>
      </a-form-model-item>
      <a-form-model-item label="区域：" prop="regionCode">
        <a-cascader
          placeholder="请选择省市区"
          v-model="form.regionCode"
          :options="regionOptions"
          @change="regionChange"
        />
      </a-form-model-item>
      <a-form-model-item label="详细地址：" prop="addr">
        <a-input placeholder="请输入详细地址" allowClear v-model="form.addr" />
      </a-form-model-item>

      <a-row type='flex' align='middle' justify="center">
        <a-button type="primary" :loading='submitLoading' @click="onSubmit">保存</a-button>
        <a-button style="margin-left: 10px;" @click='cancel'>取消</a-button>
      </a-row>
    </a-form-model>
  </a-spin>
</template>
<script>
import UploadImg from './UploadImg'
import { telValidate, validatorManyTel } from '@/utils/validator'
import { PHONE } from '@/constants'
import { getSession } from '@/utils/session'
import { common, shop } from '@/api'
export default {
  name: 'ShopInfoForm',
  props: {
    shopId: {
      type: String,
      value: ''
    }
  },
  components: {
    UploadImg
  },
  watch: {
    shopId: {
      handler (newVal, oldVal) {
        if (newVal) {
          this.getData()
        }
      }
    }
  },
  data: () => ({
    common,
    formLoading: false,
    submitLoading: false,
    baseApi: process.env.VUE_APP_API,
    baseUrl: process.env.VUE_APP_IMG,
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
    form: {
      linkName: '',
      logo: '',
      name: '',
      regionCode: [],
      addr: '',
      province: '',
      city: '',
      county: '',
      linkPhone: getSession(PHONE) || '',
      serviceTel: [
        {
          value: ''
        }
      ],
      afterServiceTel: [
        {
          value: ''
        }
      ]
    },
    regionOptions: [],
    rules: {
      name: [
        { required: true, message: '请输入店铺名', trigger: 'blur' },
        { max: 32, message: '您可以尝试一下简短的店铺名', trigger: 'blur' }
      ],
      phone: [{ validator: telValidate, trigger: 'blur' }],
      serviceTel: [{ validator: validatorManyTel, trigger: 'blur' }],
      afterServiceTel: [{ validator: validatorManyTel, trigger: 'blur' }],
      intro: [
        { max: 128, message: '您可以尝试一下简短的店铺介绍', trigger: 'blur' }
      ],
      addr: [
        { max: 128, message: '您可以尝试一下简短的详细地址', trigger: 'blur' }
      ]
    }
  }),
  async mounted () {
    this.getRegionTree()
    await this.$nextTick()
    if (this.shopId) {
      this.getData()
    }
  },
  methods: {
    changeImg (e) {
      if (e.length) {
        this.form.logo = e[0]
      } else {
        this.form.logo = ''
      }
    },
    cancel () {
      if (this.$route.path === '/shop/info') {
        this.$closeCurrentView()
      } else {
        this.$router.go(-1)
      }
    },
    async getData () {
      this.formLoading = true
      let { code, msg, data } = await shop.getShopInfo({
        shopId: this.shopId
      })
      this.formLoading = false
      if (code === '00000') {
        this.form = {
          linkMan: data.linkMan,
          intro: data.intro,
          logo: data.logo,
          name: data.name,
          regionCode: this.getRegionCodes(data.regionCode),
          addr: data.addr,
          province: data.province,
          city: data.city,
          county: data.county,
          linkPhone: data.linkPhone,
          serviceTel: data.serviceTel
            ? data.serviceTel.split(',').map(item => {
              return { value: item }
            })
            : [{ value: '' }],
          afterServiceTel: data.afterServiceTel
            ? data.afterServiceTel.split(',').map(item => {
              return { value: item }
            })
            : [{ value: '' }]
        }
      } else {
        this.$message.error(msg || '网络错误，请重试')
      }
    },
    getRegionCodes (regionCode) {
      if (!regionCode || regionCode.length < 4) return []
      return [
        `${regionCode.substr(0, 2)}`,
        `${regionCode.substr(0, 4)}`,
        regionCode
      ]
    },
    async getRegionTree () {
      let { data, msg, code } = await common.getRegionTree()
      if (code === '00000') {
        this.regionOptions = this.handleTreeData(data)
      } else {
        this.$message.error(msg || '网络错误，请重试')
      }
    },
    handleTreeData (data) {
      if (!data || !data.length) return
      for (let item of data) {
        item.value = item.regionCode
        item.label = item.regionName
        item.children = this.handleTreeData(item.children)
      }
      return data
    },

    regionChange (e, selectedOptions) {
      if (selectedOptions) {
        const [{ regionName: province }, { regionName: city }, { regionName: county }] = selectedOptions
        this.form.province = province
        this.form.city = city
        this.form.county = county
      } else {
        this.form.province = ''
        this.form.city = ''
        this.form.county = ''
      }
    },

    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.saveShop()
        } else {
          return false
        }
      })
    },
    async saveShop () {
      this.submitLoading = true
      let param = this.handelFormData()
      let { code, msg } = await shop.saveShop(param)
      this.submitLoading = false
      if (code === '00000') {
        if (this.shopId) {
          this.$message.success('修改成功')
          if (this.$route.path === '/create_shop') { this.$router.go(-1) }
        } else {
          this.$message.success('创建成功')
          if (this.$route.path === '/create_shop') { this.$router.go(-1) }
        }
      } else {
        this.$message.error(msg || '创建失败')
      }
    },
    handelFormData () {
      let param = {}
      if (this.shopId) {
        param.shopId = this.shopId
      }
      param.name = this.form.name
      param.logo = this.form.logo
      param.linkMan = this.form.linkMan
      param.intro = this.form.intro
      param.linkPhone = this.form.linkPhone
      param.lngLat = ''
      param.addr = this.form.addr
      param.province = this.form.province
      param.city = this.form.city
      param.county = this.form.county
      param.regionCode = this.form.regionCode[2] ? this.form.regionCode[2] : ''
      param.serviceTel = this.form.serviceTel.map(item => item.value).join(',')
      param.afterServiceTel = this.form.afterServiceTel
        .map(item => item.value)
        .join(',')
      return param
    },
    addServiceTel () {
      if (this.form.serviceTel.length === 5) return
      this.form.serviceTel.push({
        value: ''
      })
    },
    delServiceTel (index) {
      this.form.serviceTel.splice(index, 1)
    },
    addAfterServiceTel () {
      if (this.form.afterServiceTel.length === 5) return
      this.form.afterServiceTel.push({
        value: ''
      })
    },
    delAfterServiceTel (index) {
      this.form.afterServiceTel.splice(index, 1)
    }
  }
}
</script>
<style lang="less" scoped>
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
.add-phone {
  font-size: 20px;
  cursor: pointer;
  color: #666;
  margin-left: 12px;
}
.map {
  height: 300px;
  margin: 0 auto;
}
.logo-img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}
</style>
