
import AccountSetting from '@/views/AccountSetting'
import AppLogin from '@/views/AppLogin'
import AppRegister from '@/views/AppRegister'
import CreateShop from '@/views/CreateShop'
import ForgetPwd from '@/views/ForgetPwd'
import AppLayout from '@/views/layout/AppLayout'
import RedirectPage from '@/views/redirect/RedirectPage'
import SelectShop from '@/views/SelectShop'
import Application from '@/views/h5/PageApplication'
import BindSuccess from '@/views/h5/PageBindSuccess'
import PageCustomerInput from '@/views/h5/PageCustomerInput'
import PageWarrantyCard from '@/views/h5/PageWarrantyCard'
import MassagePage from '@/views/MessagePage'
import PageGuid from '@/views/PageGuid.vue'
const baseRoutes = [

  {
    path: '/redirect/:path*',
    component: RedirectPage
  },
  {
    path: '/login',
    component: AppLogin
  },
  {
    path: '/register',
    component: AppRegister
  },
  {
    path: '/select_shop',
    component: SelectShop
  },
  {
    path: '/create_shop',
    component: CreateShop
  },
  {
    path: '/forget_pwd',
    component: ForgetPwd
  },
  {
    path: '/application',
    component: Application,
    meta: { title: '申请加盟' }
  },
  {
    path: '/h5_bind_success',
    component: BindSuccess,
    meta: { title: '绑定成功' }
  },
  {
    path: '/account_setting',
    component: AppLayout,
    children: [
      {
        path: '',
        component: AccountSetting,
        meta: { title: '账号管理', noClose: false, showInMenuFlag: false }
      }
    ]
  },
  {
    path: '/message_page',
    component: AppLayout,
    children: [
      {
        path: '',
        component: MassagePage,
        meta: { title: '消息列表', noClose: false, showInMenuFlag: false }
      }
    ]
  },
  {
    path: '/guid_page/:index',
    component: AppLayout,
    children: [
      {
        path: '',
        component: PageGuid,
        meta: { title: '学习交流', noClose: false, showInMenuFlag: false }
      }
    ]
  },
  {
    path: '/customer_input',
    component: PageCustomerInput,
    meta: { title: '保单录入' }
  },
  {
    path: '/warranty_card',
    component: PageWarrantyCard,
    meta: { title: '我的保险卡' }
  }
]

export default baseRoutes
