import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/system',
  component: AppLayout,
  children: [
    {
      path: 'wx_applet',
      component: () => import('@/views/system/PageWxApplet.vue'),
      meta: { title: '微信小程序', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'dy_applet',
      component: () => import('@/views/system/PageWxApplet.vue'),
      meta: { title: '抖音小程序', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'staff_management',
      component: () => import('@/views/system/PageStaffManagement.vue'),
      meta: { title: '账号管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'user_management',
      component: () => import('@/views/system/PageUserManagement.vue'),
      meta: { title: '用户管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'role_management',
      component: () => import('@/views/system/PageRoleManagement.vue'),
      meta: { title: '角色管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'role_management/add/:type',
      component: () => import('@/views/system/PageRoleForm.vue'),
      meta: { title: '创建角色', noClose: false, showInMenuFlag: false }
    }, {
      path: 'role_management/edit/:type/:id',
      component: () => import('@/views/system/PageRoleForm.vue'),
      meta: { title: '修改角色', noClose: false, showInMenuFlag: false }
    }, {
      path: 'role_management/detail/:type/:id',
      component: () => import('@/views/system/PageRoleForm.vue'),
      meta: { title: '角色详情', noClose: false, showInMenuFlag: false }
    }
  ]
}
