import { service } from '@/utils/request'

export default {
  getRoleList (payload) {
    return service.get('/shopRole/page', {
      params: payload
    })
  },
  saveRole (payload) {
    return service.post('/shopRole/save', payload)
  },
  getRoleDetail (payload) {
    return service.get('/shopRole/getRole', {
      params: payload
    })
  },
  deleteRole (payload) {
    return service.postForm('/shopRole/deleteRole', payload)
  },
  getRoleListSelect (payload) {
    return service.get('/shopRole/list', {
      params: payload
    })
  },
  getStaffList (payload) {
    return service.get('/shopStaff/page', {
      params: payload
    })
  },
  saveStaff (payload) {
    return service.post('/shopStaff/save', payload)
  },
  getStaffDetail (payload) {
    return service.get('/shopStaff/getStaff', {
      params: payload
    })
  },
  deleteStaff (payload) {
    return service.postForm('/shopStaff/delete', payload)
  },
  getAuthBtnList (payload) {
    return service.get('/mmsFunc/listFunc', {
      params: payload
    })
  },
  getSelectStaffData (payload) {
    return service.get('/shopStaff/list', {
      params: payload
    })
  },
}
