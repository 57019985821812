import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/activity',
  component: AppLayout,
  children: [
    // 拼团
    {
      path: 'group',
      component: () => import('@/views/activity/PageGroup.vue'),
      meta: { title: '拼团活动', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'group/add',
      component: () => import('@/views/activity/PageGroupForm.vue'),
      meta: { title: '新建拼团活动', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'group/edit/:id',
      component: () => import('@/views/activity/PageGroupForm.vue'),
      meta: { title: '编辑拼团活动', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'group/detail/:id',
      component: () => import('@/views/activity/PageGroupForm.vue'),
      meta: { title: '拼团活动详情', noClose: false, showInMenuFlag: false }
    },
    // 砍价
    {
      path: 'bargain',
      component: () => import('@/views/activity/PageBargain.vue'),
      meta: { title: '砍价活动', noClose: false, showInMenuFlag: true }
    },
    {
      name: 'bargain_add',
      path: 'bargain/add',
      component: () => import('@/views/activity/PageBargainForm.vue'),
      meta: { title: '新建砍价活动', noClose: false, showInMenuFlag: false }
    },
    {
      name: 'bargain_edit',
      path: 'bargain/edit/:id',
      component: () => import('@/views/activity/PageBargainForm.vue'),
      meta: { title: '编辑砍价活动', noClose: false, showInMenuFlag: false }
    },
    {
      name: 'bargain_detail',
      path: 'bargain/detail/:id',
      component: () => import('@/views/activity/PageBargainForm.vue'),
      meta: { title: '砍价活动详情', noClose: false, showInMenuFlag: false }
    },
    // 秒杀
    {
      path: 'seckill',
      component: () => import('@/views/activity/PageSeckill.vue'),
      meta: { title: '秒杀活动', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'seckill/add',
      component: () => import('@/views/activity/PageSeckillForm.vue'),
      meta: { title: '新建秒杀活动', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'seckill/edit/:id',
      props: route => ({
        type: 'edit',
        id: route.params.id
      }),
      component: () => import('@/views/activity/PageSeckillForm.vue'),
      meta: { title: '编辑秒杀活动', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'seckill/detail/:id',
      props: route => ({
        type: 'detail',
        id: route.params.id
      }),
      component: () => import('@/views/activity/PageSeckillForm.vue'),
      meta: { title: '查看秒杀活动', noClose: false, showInMenuFlag: false }
    },
    // 兑换券
    {
      path: 'exchange',
      component: () => import('@/views/activity/PageExchange.vue'),
      meta: { title: '兑换券', noClose: false, showInMenuFlag: true }
    },
    {
      name: 'exchange_add',
      path: 'exchange/add',
      component: () => import('@/views/activity/PageExchangeForm.vue'),
      meta: { title: '新建兑换券', noClose: false, showInMenuFlag: false }
    },
    {
      name: 'exchange_detail',
      path: 'exchange/detail/:id',
      props: route => ({
        id: route.params.id,
        readonly: '1'
      }),
      component: () => import('@/views/activity/PageExchangeForm.vue'),
      meta: { title: '兑换券详情', noClose: false, showInMenuFlag: false }
    },
    // 代金券
    {
      path: 'cash_coupon',
      component: () => import('@/views/activity/PageCashCoupon.vue'),
      meta: { title: '代金券', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'cash_coupon/detail/:id',
      props: route => ({
        id: route.params.id,
        readonly: '1'
      }),
      component: () => import('@/views/activity/PageCashCouponForm.vue'),
      meta: { title: '代金券活动详情', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'cash_coupon/edit/:id',
      props: route => ({
        id: route.params.id,
      }),
      component: () => import('@/views/activity/PageCashCouponForm.vue'),
      meta: { title: '代金券活动编辑', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'cash_coupon/add',
      component: () => import('@/views/activity/PageCashCouponForm.vue'),
      meta: { title: '代金券活动创建', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'cash_coupon/coupons',
      component: () => import('@/views/activity/PageCashCouponCoupons.vue'),
      meta: { title: '代金券维护', noClose: false, showInMenuFlag: false }
    },
  ]
}
