<template>
  <div class='page-box'>
    <BaseSubTitle title="账号管理"/>
    <a-spin :spinning='loading'>
      <a-form-model
      style="width: 900px;"
      ref="formRef"
      :model="formData"
      @submit.native.prevent
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :rules="rules">
      <a-form-model-item label="账号" prop="loginName">
        <div style='display:flex;align-items:center;'>
          <div>{{formData.loginName}}</div>
          <a-button type='link' @click='showChangePwdModal = true'>修改密码</a-button>
        </div>
      </a-form-model-item>

      <a-form-model-item label="昵称" prop="nickName">
        <a-input style='width:300px;' v-model="formData.nickName" placeholder="昵称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="性别" prop="sex">
        <a-radio-group v-model="formData.sex" :options="options" />
      </a-form-model-item>
      <a-form-model-item label="头像" prop="headImg">
        <picture-card-upload :fileList="formData.headImg?[formData.headImg]:[]" @update:fileList="res=>$set(formData, 'headImg', res.length ? res[0] : '')" :max="1" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="phone">
        <a-input style='width:300px;' :maxLength="11" v-model="formData.phone" placeholder="联系电话"></a-input>
      </a-form-model-item>
      <a-row>
          <a-col :offset='4'>
            <a-button type='primary' style='margin-right:14px;' :loading='submitLoading' @click="submit">保存</a-button>
            <a-button @click="cancel">取消</a-button>
          </a-col>
        </a-row>
    </a-form-model>
    </a-spin>
    <change-pwd :show='showChangePwdModal' @closeModal="showChangePwdModal = false"></change-pwd>
  </div>
</template>
<script>
import ChangePwd from '@/components/ChangePwd'
import { reactive, toRefs, onMounted } from '@vue/composition-api'
import { user, common, system } from '@/api'
import { SHOPUSERID, NICKNAME } from '@/constants'
import { getSession, setSession } from '@/utils/session'
import { phoneValidate } from '@/utils/validator'
export default {
  name: 'AccountSetting',
  components: {
    ChangePwd
  },
  setup (props, { root }) {
    const labelCol = { span: 4 }
    const wrapperCol = { span: 20 }
    const rules = {
      nickName: [
        { required: true, message: '请填写昵称', trigger: 'change' }
      ],
      phone: [
        { validator: phoneValidate, trigger: 'change' }
      ]
    }
    const options = [
      { label: '男', value: true },
      { label: '女', value: false }
    ]
    const state = reactive({
      formData: {

      },
      showChangePwdModal: false,
      loading: false,
      submitLoading: false,
      formRef: null
    })
    async function getUserInfo () {
      state.loading = true
      let { code, data, msg } = await user.getUserInfo(
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: {
            userId: getSession(SHOPUSERID)
          }
        }
      )
      state.loading = false
      if (code === '00000') {
        state.formData = data
      } else {
        root.$message.error(msg || '获取用户信息失败')
      }
    }
    function submit () {
      state.formRef.validate(async valid => {
        if (valid) {
          state.submitLoading = true
          let { code, msg } = await system.userSetting(state.formData)
          state.submitLoading = false
          if (code === '00000') {
            root.$message.success('保存成功')
            setSession(NICKNAME, state.formData.nickName)
          } else {
            root.$message.error(msg || '网络错误，请重试')
          }
        }
      })
    }
    function cancel () {
      root.$closeCurrentView()
    }
    onMounted(() => {
      getUserInfo()
    })
    return {
      common,
      options,
      labelCol,
      wrapperCol,
      rules,
      ...toRefs(state),
      getUserInfo,
      submit,
      cancel
    }
  }
}
</script>
<style lang="less" scoped>
.head-img {
  width: 90px;
  height: 90px;
  object-fit: cover;
}
</style>
