<template>
  <div class="page">
    <div class="head">
      <img src="@/assets/login-logo.png" class="logo-img" alt />
    </div>
    <div class="form-box">
      <p class="form-title">找回密码</p>
      <a-form-model
        style="width: auto"
        ref="formRef"
        :model="form"
        @submit.native.prevent
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="rules">
        <a-form-model-item label prop="loginName">
          <a-input :maxLength="11" v-model="form.loginName" placeholder="手机号"></a-input>
        </a-form-model-item>
        <a-form-model-item label prop="smsCode">
          <sms-code :type="1" :phone='form.loginName' @isGeted='(res)=>{isGetSms = res}' v-model='form.smsCode'></sms-code>
        </a-form-model-item>
        <a-form-model-item label prop="loginPwd">
          <a-input v-model="form.loginPwd" type="password" placeholder="请输入密码"></a-input>
        </a-form-model-item>
        <a-form-model-item label prop="reLoginPwd">
          <a-input v-model="form.reLoginPwd" type="password" placeholder="再次输入密码"></a-input>
        </a-form-model-item>
      </a-form-model>
      <div style='margin-top:40px'>
        <a-button type="primary" class="big-button" @click="handleSubmit" :loading="submitting">确认修改</a-button>
        <div class="register-row">
          没有账号？
          <a-button type="link" style="padding:0" @click="goRegister">去注册</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { phoneValidate, pwdValidate } from '@/utils/validator'
import { user } from '@/api'
import { reactive, toRefs } from '@vue/composition-api'
export default {
  name: 'ForgetPwd',
  setup (props, { root }) {
    const state = reactive({
      form: {},
      formRef: null,
      submitting: false,
      interval: null,
      isGetSms: false
    })
    const rePwdValidate = (rule, value, callback) => {
      if (value) {
        if (value !== state.form.loginPwd) {
          callback(new Error('两次密码输入不一致'))
        } else {
          callback()
        }
      }
    }
    const labelCol = { span: 0 }
    const wrapperCol = { span: 24 }
    const rules = {
      loginName: [
        { required: true, message: '请填写手机号', trigger: 'change' },
        { validator: phoneValidate, trigger: 'change' }
      ],
      smsCode: [
        { required: true, message: '请填写短信验证码', trigger: 'change' }
      ],
      loginPwd: [{ required: true, message: '请填写密码', trigger: 'change' },
        { validator: pwdValidate, trigger: 'change' }],
      reLoginPwd: [
        { required: true, message: '请填写重复密码', trigger: 'change' },
        { validator: rePwdValidate, trigger: 'change' }
      ]
    }
    function goRegister () {
      root.$router.replace('/register')
    }
    function handleSubmit () {
      if (state.submitting) return
      if (!state.isGetSms) return root.$message.error('请先获取短信验证码')
      state.formRef.validate(async valid => {
        if (valid) {
          state.submitting = true
          let { code, msg } = await user.forgetPwd({
            loginName: state.form.loginName,
            loginPwd: state.form.loginPwd,
            smsCode: state.form.smsCode
          })
          state.submitting = false
          if (code === '00000') {
            root.$message.success('密码修改成功')
            root.$router.replace('/login')
          } else {
            root.$message.error(msg || '密码修改失败')
          }
        }
      })
    }
    return {
      labelCol,
      wrapperCol,
      rules,
      ...toRefs(state),
      goRegister,
      handleSubmit
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  min-height: 100vh;
  min-width:900px;
  background: #eee;
  padding-bottom: 40px;
  box-sizing: border-box;
  .head {
    padding: 16px 40px;
    margin-bottom: 40px;
    .logo-img {
      height: 40px;
    }
  }
  .form-box {
    height: 500px;
    width: 440px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    padding: 32px;
    box-sizing: border-box;
    color: #000;
    ::v-deep .ant-input {
      border-radius: 2px;
      height: 40px;
      line-height: 40px;
    }
    .form-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 32px;
    }
    ::v-deep .sms-btn {
      line-height: 40px;
      margin-top: 0;
    }
  }
  .big-button {
    margin-top: 10px;
    width: 100%;
    height: 50px;
    font-size: 20px;
  }
  .register-row {
    padding-top: 8px;
    color: #666;
    text-align: right;
  }
}
</style>
