import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { SHOPID, SHOPSTAFFID, TOKEN } from './constants.js'
import router from './router'
import store from './store'
import { getSession, removeSession } from './utils/session'
NProgress.configure({ showSpinner: false })
const whiteList = ['/login', '/register', '/forget_pwd', '/application', '/h5_bind_success', '/customer_input', '/warranty_terms', '/warranty_card']

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  // 如果是白名单 放行
  if (whiteList.some(x => to.path.indexOf(x) >= 0)) {
    next()
  } else {
    // 非白名单
    if (getSession(TOKEN)) {
      // 有token
      if (getSession(SHOPID) && getSession(SHOPSTAFFID)) {
        if (to.path === '/massage_page') {
          return next()
        }
        if (!store.state.route.initFlag) {
          try {
            await store.dispatch('route/initRoutes')
            router.addRoutes(store.state.route.routes)
            next({ ...to, replace: true })
          } catch (e) {
            removeSession(TOKEN)
            next({ path: `/login?redirect=${to.path}` })
            NProgress.done()
          }
        } else {
          next()
        }
      } else {
        if (to.path === '/select_shop' || to.path === '/create_shop') {
          next()
        } else {
          next('/select_shop')
          NProgress.done()
        }
      }
    } else {
      // 没有 token   跳转登录 并 重定向地址设为 选择店铺页面
      next(`/login?redirect=/select_shop`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
