import { SHOPID, SHOPSTAFFID } from '@/constants'
import { service } from '@/utils/request'
import { getSession } from '@/utils/session'

export default {
  saveShop (payload) {
    return service.post('/shop/save', payload)
  },
  getShopInfo (payload) {
    return service.get('/shop/getShop', {
      params: Object.assign({ shopStaffId: getSession(SHOPSTAFFID) }, payload)
    })
  },
  saveShopAuth (payload) {
    return service.post('/shopAuth/save', payload)
  },
  getShopAuthData (payload) {
    return service.get('/shopAuth/getShopAuth', {
      params: payload
    })
  },
  saveShopAddr (payload) {
    return service.post('/shopAddr/save', payload)
  },
  getAddrPage (payload) {
    return service.get('/shopAddr/page', {
      params: payload
    })
  },
  getAddrDetail (payload) {
    return service.get('/shopAddr/getShopAddr', {
      params: payload
    })
  },
  delShopAddr (payload) {
    return service.postForm('/shopAddr/delete', payload)
  },
  saveShopDesign (payload) {
    return service.post('/shopDesignStorage/save', payload)
  },
  releaseShopDesign (payload) {
    return service.post('/shopDesign/save', payload)
  },
  wxGetPreAuthUrl (payload) {
    return service.get('/shopWxma/getPreAuthUrl', {
      params: payload
    })
  },
  wxSendQueryAuth (payload) {
    return service.postForm('/shopWxma/getQueryAuth', payload)
  },
  wxGetAppletBaseData (payload) {
    return service.get('/shopWxma/getAccountInfo', {
      params: payload
    })
  },
  wxGetDomainResult (payload) {
    return service.get('/shopWxma/getDomainResult', {
      params: payload
    })
  },
  // 商品分组
  getProductGroupPage (payload) {
    return service.get('/productGroup/page', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  addProductGroup (payload) {
    return service.post('/productGroup/save', {
      shopId: getSession(SHOPID),
      ...payload
    })
  },
  deleteProductGroup (productGroupId) {
    return service.postForm('/productGroup/delete', { productGroupId, shopId: getSession(SHOPID) })
  },
  getProductByGroup (payload) {
    return service.get('/product/listProductByGroup', {
      params: Object.assign({ type: 0 }, payload)
    })
  },
  webDomain (payload) {
    return service.postForm('/shopWxma/updateWebviewdomain', payload)
  },
  apiDomain (payload) {
    return service.postForm('/shopWxma/modifyDomain', payload)
  },
  updateAdvert (payload) {
    return service.post('/shop/index/pop/saveOrUpdate', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  getAdvertDetail () {
    return service.get(`/shop/index/pop/detail/${getSession(SHOPID)}`)
  },
  updateGroup (payload) {
    return service.post('/shopMaterialGroup/save', payload)
  },
  getResourcePage (payload) {
    return service.get('/shopMaterial/page', {
      params: payload
    })
  },
  getSourceGroup (payload) {
    return service.get('/shopMaterialGroup/list', {
      params: Object.assign({ type: 0, shopId: getSession(SHOPID) }, payload)
    })
  },
  deleteImgResource (payload) {
    return service.postForm('/shopMaterial/delete', payload)
  },
  deleteGroup (payload) {
    return service.postForm('/shopMaterialGroup/delete', payload)
  },
  imgChangeGroup (payload) {
    return service.postForm('/shopMaterial/changeGroup', payload)
  },
  saveImgResource (payload) {
    return service.post('/shopMaterial/save', payload)
  },
  getVoucher (payload) {
    return service.get('/shopVoucher/pageByShop', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getVoucherTree () {
    return service.get('/shopVoucher/listTree')
  },
  getProductVoucherList (payload) {
    return service.get('/shopVoucher/list', {
      params: payload
    })
  },
  getVoucherImg (payload) {
    return service.get('/shopVoucher/listVoucherImg', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  submitVoucherImgs (payload) {
    return service.post('/shopVoucher/submitVoucher', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  getDesignList (payload) {
    return service.get('/shopDesignStorage/list', {
      params: payload
    })
  },
  deleteTemplate (payload) {
    return service.postForm('/shopDesignStorage/del', payload)
  },
  saveTemplate (payload) {
    return service.post('/shopDesignStorage/save', payload)
  },
  getDesignDetail (payload) {
    return service.get('/shopDesignStorage', {
      params: payload
    })
  },
  getTemplateList () {
    return service.get('/shopDesign/list', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  getSaleClass () {
    return service.get('/saleClass/list', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  getSaleClassContent (payload) {
    return service.get('/saleClassItem/listProduct', {
      params: payload
    })
  },
  saveSaleClassContent (payload) {
    return service.post('/saleClassItem/bind', payload)
  },
  saveSaleClass (payload) {
    return service.post('/saleClass/save', {
      shopId: getSession(SHOPID),
      ...payload
    })
  },
  getChooseProducts (payload) {
    return service.get('/saleClassItem/pageForCreate', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  removeProduct (payload) {
    return service.postForm('/saleClassItem/removeProduct', payload)
  },
  getSplitPage (payload) {
    return service.get('/shop/split/log/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getSplitAddData () {
    return service.get('/shop/split/log/getSplitAddData', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
}
