<template>
  <a-modal
    v-model="visible"
    title="修改配送信息"
    width="500px"
    :maskClosable="false"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    destroy-on-close
  >
    <a-spin v-if="isBatchSell" :spinning="loading">
      <form-delivery
        v-if="serverDeliveryInfo"
        ref="formDeliveryRef"
        :delivery-info="serverDeliveryInfo"
      />
    </a-spin>
    <form-delivery v-else ref="formDeliveryRef" :delivery-info="deliveryInfo" />
  </a-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { order } from '@/api'
import FormDelivery from './FormDelivery'

export default {
  name: 'DialogUpdateDelivery',

  props: {
    isBatchSell: Boolean,
    isBatchBuy: Boolean,
    show: Boolean,
    deliveryInfo: [Object, String] // isBatchSell为true时，代表orderId
  },

  emits: ['success'],

  components: {
    FormDelivery
  },

  setup (props, { root, emit }) {
    const visible = computed({
      get: () => props.show,
      set: val => {
        emit('update:show', val)
      }
    })

    const serverDeliveryInfo = ref(null)
    const loading = ref(false)
    watch(visible, value => {
      if (props.isBatchSell && value && typeof props.deliveryInfo === 'string') {
        getBatchDetail(props.deliveryInfo)
      } else {
        serverDeliveryInfo.value = null
      }
    }, { immediate: true })

    async function getBatchDetail (orderId) {
      loading.value = true
      const { code, msg, data } = await order.getBatchOrderDeliveryDetail(orderId)
      loading.value = false
      if (code === '00000') {
        serverDeliveryInfo.value = data
      }
    }

    const formDeliveryRef = ref(null)
    const confirmLoading = ref(false)
    async function handleOk () {
      try {
        const params = await formDeliveryRef.value.handleSubmit()
        confirmLoading.value = true
        const { code } = await (props.isBatchSell ? order.updateBatchOrderDelivery({
          orderId: props.deliveryInfo,
          ...params
        }) : props.isBatchBuy ? order.batchReturnDeliveryUpdate({
          orderWholesaleReturnId: props.deliveryInfo.orderWholesaleReturnId,
          ...params,
        }) : order.updateOrderDelivery({
          shopOrderId: props.deliveryInfo.shopOrderId,
          orderInfoId: props.deliveryInfo.orderInfoId,
          ...params
        }))
        confirmLoading.value = false
        if (code !== '00000') return root.$message.error('提交失败，请稍后重试')
        root.$message.success('修改成功')
        emit('update')
        visible.value = false
      } catch (e) {
        console.error(e.message || '提交失败')
      }
    }

    return {
      visible,
      formDeliveryRef,
      confirmLoading,
      handleOk,
      loading,
      serverDeliveryInfo,
    }
  }
}
</script>

<style lang="less" scoped>
</style>
