<template>
  <!-- A -->
  <div>
    <div class="box">
      <img class="seal" src="./assets/seal.png" alt="">
      <p class="sub-title">保险责任：</p>
      <p class="content">在中国境内（不含港澳台地区）使用芯驰力引擎全效复合剂（以下简称产品）后，因产品存在缺陷而造成发动机内部单独零部件损坏的，依法由烟台旺谷网络科技有限公司承担的经济赔偿责任；经产品使用者或消费者在保险期间内向被保险人首次提出索赔时，在本保单约定的赔偿限额内由中国人寿财产保险股份有限公司北京市分公司予以赔偿；</p>
      <p class="sub-title">单次事故赔偿限额及标准：</p>
      <div class="content">
        <p>1.每车赔偿限额：该车发动机原厂零部件购置价，且不超过100万；</p>
        <p>2.每车赔偿标准：使用投保产品后在质保期内，汽车发动机出现零部件损坏，影响车辆正常使用，予以赔偿。</p>
      </div>

      <p class="sub-title">索赔须知：</p>
      <div class="content">
        <p>1.索赔车辆需提供4S店两次连续的基础保养记录；</p>
        <p>2.本零部件保障项目只限于中国大陆境内的品牌授权4S店内运营销售，其它性质的维修保养机构运营销售，索赔不予受理；</p>
        <p>3.免责期：保障项目生效次日起30天内报案或行驶里程数未满300公里报案的，均不予受理。</p>
      </div>

      <p class="sub-title">索赔流程：</p>
      <div class="content">
        <p>客户向中国人寿财险报案（010-95519）→中国人寿财险查勘、定责定损并委托第三方协同（客户需要提供与本案相关理赔资料）→中国人寿财险确认属于本协议责任→车辆维修→维修结束后，由中国人寿财险核赔并按约赔付给维修单位→结案。（*报案后，受损车辆需在12小时内返回指定维修点*索赔周期在索赔资料齐全后约7个工作日）</p>
      </div>

      <p class="sub-title">保障范围：</p>
      <div class="content">
        <p>发动机系统（汽油机/柴油机）气缸体总成：活塞、活塞环、曲轴、轴瓦、活塞销、连杆、飞轮、汽缸盖、凸轮轴、涡轮增压器、机油泵、油底壳、集滤器、限压阀、汽油压力调节器、分电器、气门室盖罩、汽缸垫、可变正时调节器。</p>
      </div>

      <p class="sub-title">除外责任：</p>
      <div class="content">
        <p>下列情形下的损失、费用、责任，保险公司不负责赔偿：</p>
        <p>1.因厂家设计缺陷（如机油消耗过大、渗油、杂音大等）或人为或外力因素导致的发动机故障，使用人或其代表自行清除或者重新使用其它品牌发动机保护产品，致使使用该产品的车辆引起的任何或全部损失，不予赔偿；</p>
        <p>2.投保车辆必须使用原厂机油或原厂指定机油，否则因使用其它机油造成的发动机损坏，不予赔偿；</p>
        <p>3.没有遵从原厂维修保养手册或用户手册中规定的保养时间、里程及保养项目进行保养的，不予赔偿；</p>
        <p>4.未使用该品牌车型提供或推荐的符合该品牌车型技术规范的零部件，不予赔偿；</p>
        <p>5.投保前车况不正常（机油消耗过大、渗油、杂音大等状况及发动机损坏或零部件明显故障、发动机烧机油、漏油），非直接损失，不予赔偿；</p>
        <p>6.车辆若过户需要重新审核发动机质保条件，行驶证首次注册日期8年以上或公里数超过20万公里以上的车（超过8年或20万公里以上的车以先到为准），仪表盘不显示公里数或里程表故障、不工作导致所显示公里数不真实，不予赔偿；</p>
        <p>7.营运车辆或发动机动力系统改装过的车辆、非正常驾驶车辆，不予赔偿；</p>
        <p>8.保险人未查勘前保险车辆已拆解的，车辆经保险人同意拆解后直接修理未通知保险人现场核对损失的，保险人未查勘前已将发动机机油或防冻液放掉的，均不予赔偿；</p>
        <p>9.经保险公司指定的有资质的第三方机构鉴定，未使用本保单指定的生效产品造成的损坏，不予赔偿；</p>
        <p>10.因发动机缺机油、机油泵机油滤清器堵塞、水箱缺水导致高温引发的发动机损坏，均不予赔偿；因涉水行驶引起的发动机电气部分及内部损失，不予赔偿；</p>
        <p>11.因故障未及时修理导致的发动机扩大损失，不予赔偿；由于其他部件(超出承保的19个部件以外的部件)引发故障造成的发动机损坏，不予赔偿；(超出承保的19个部件以外的部件)引发故障造成的发动机损坏，不予赔偿；</p>
        <p>12.提供虚假信息、或未录入电子管理系统，所有因第三方疏忽或故意的行为导致的责任，不予赔偿；</p>
        <p>13.消费者车辆或其部件已由汽车制造厂商或者部件提供商承担的损失，不予赔偿；</p>
        <p>14.超出本保单所承保保险责任外的任何财产损失或人身伤亡，不予赔偿；被保险人因保险事故而被提起仲裁或者诉讼的，应由被保险人支付的仲裁或诉讼费用、律师费用及其他费用或任何间接损失（包括但不限于精神损害、品牌信誉等无形资产的损失），均不予赔偿。</p>
        <p>（除外责任包括但不仅限于以上内容）</p>
      </div>
      <p class="sub-title">赔偿处理：</p>
      <div class="content">
        <p>1.由于产品存在缺陷引发发动机内部零部件损坏及相关工时费用按实际损失进行赔偿。</p>
        <p>2.由于产品存在缺陷引发发动机损坏的，按施工方及保险公司核价后进行赔偿。</p>
        <p>3.承保车辆出险后必须到项目授权施工定点单位进行核价和维修。</p>
      </div>
      <p class="sub-title">索赔资料：</p>
      <div class="content">
        <p>理赔时需提供受损人驾驶证及身份证、机动车行驶证、机动车登记证书、购车原始发票、车辆购置税完税证明或免税证明复印件、车辆修复发票及维修清单、事故认证单等保险公司要求的其他材料。 </p>
        <p>中国人寿财险全国客服电话：010-95519</p>
        <p>服务电话：4006687172</p>
      </div>
    </div>
    <p class="title spec">客户信息卡及服务声明</p>
    <table class="custom-table">
      <tr>
        <td>客户名称</td>
        <td>{{data.customerName}}</td>
        <td>身份证号</td>
        <td>{{data.idcardCode}}</td>
      </tr>
      <tr>
        <td>地址</td>
        <td>{{data.address}}</td>
        <td>移动电话</td>
        <td>{{data.linkPhone}}</td>
      </tr>
      <tr>
        <td>厂牌型号</td>
        <td>{{data.brandName}}</td>
        <td>车牌号</td>
        <td>{{data.plateNo}}</td>
      </tr>
      <tr>
        <td>车辆购买年限及公里数</td>
        <td>{{unitFormat(data.years, '年')}}</td>
        <td>车辆新车购置价</td>
        <td>{{unitFormat(data.carPrice, '万元')}}</td>
      </tr>
      <tr>
        <td>保险期限</td>
        <td colspan="3">一年期，由{{dateFormat(data.validTime)}}零时起至{{dateFormat(data.endTime)}}二十四时止</td>
      </tr>
      <tr>
        <td>安装地址</td>
        <td colspan="3">{{data.installProvince}}{{data.installCity}}{{data.installCounty}} {{data.installAddr}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'WarrantyAgreement2',

  props: {
    data: Object,
    dateFormat: Function,
    unitFormat: Function
  }
}
</script>

<style lang="less" scoped>
.box {
  position: relative;
  .seal {
    position: absolute;
    right: -10px;
    bottom: -24px;
    z-index: 1;
    width: 100px;
  }
}
.sub-title {
  font-size: 8px;
  font-weight: bold;
  margin-top: 4px;
}
.content {
  font-size: 8px;
  text-indent: 16px;
}
</style>
