import store from '@/store'
import Vue from 'vue'

Vue.directive('pms', function (el, binding, vnode) {
  setTimeout(() => {
    let { value: btnKey } = binding
    let { context } = vnode
    let btnPermissions = context.$permittedButtons
    let instance = context
    while (instance && btnPermissions == null) {
      instance = instance.$parent
      if (instance) btnPermissions = instance.$permittedButtons
    }
    if (btnPermissions == null || !btnPermissions.includes(btnKey)) {
      Vue.config.silent = true
      el.style.display = 'none'
      Vue.config.silent = false
    }
  })
})

Vue.mixin({
  async beforeRouteEnter (to, from, next) {
    if (to.path.startsWith('/redirect')) return next()
    next(vm => {
      vm.$permittedButtons = store.state.route.buttons.map(item => {
        if (item.id === vm.$route.meta.id) {
          return item.name
        }
      })
    })
  }
})
