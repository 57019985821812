import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/marketing',
  component: AppLayout,
  children: [
    {
      path: 'sharer',
      component: () => import('@/views/marketing/PageSharer.vue'),
      meta: { title: '推客管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'partner',
      component: () => import('@/views/marketing/PagePartner.vue'),
      meta: { title: '合伙人管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'level',
      component: () => import('@/views/marketing/PageLevel.vue'),
      meta: { title: '级别管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'goods',
      component: () => import('@/views/marketing/PageGoodsList.vue'),
      meta: { title: '推广商品', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'goods/add',
      component: () => import('@/views/marketing/PageGoodsAdd.vue'),
      meta: { title: '添加推广商品', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'order',
      component: () => import('@/views/marketing/PageOrder.vue'),
      meta: { title: '推广订单', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'withdraw',
      component: () => import('@/views/marketing/PageWithdraw.vue'),
      meta: { title: '提现审核', noClose: false, showInMenuFlag: true }
    }
  ]
}
