<template>
  <div class="order-table">
    <a-spin :spinning="loading">
      <table>
        <thead>
          <tr class="tr-th">
            <th v-for="(item, i) in columns" :key="`th${i}`">{{item.title}}</th>
          </tr>
        </thead>
        <tbody v-if="dataSource.length">
          <!-- 批发进货订单 -->
          <template v-if="isBatchBuy">
            <template v-for="item in dataSource">
              <tr class="sep-row" :key="`th${item.orderWholesaleReturnId || item.orderNo}`">
                <td :colspan="columns.length"></td>
              </tr>
              <tr class="tr-th shop-th bd" :key="`batch_${item.orderWholesaleReturnId || item.orderNo}`">
                <td :colspan="columns.length">
                  <div class="shop-order-info">
                    <div>
                      <span class="info-item">订单号：{{item.orderNo}}</span>
                      <span class="info-item">下单时间：{{item.orderTime}}</span>
                      <span class="info-item">由供应商发货，联系电话：{{item.serviceTel}}</span>
                    </div>
                    <div>
                      <a href="javascript:;" class="link" @click="$router.push(`/purchase/batch_order_detail/${item.orderWholesaleInfoId}`)">查看详情</a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="tr-goods" :key="`batch_item${item.orderWholesaleReturnId || item.orderNo}`">
                <template v-for="column in columns">
                  <td :key="`shopColumn${item.orderWholesaleReturnId || item.orderNo}${column.dataIndex}`">
                    <div v-if="column.dataIndex === 'name'" class="goods-info">
                      <base-img class="goods-img" :src="item.productSkuPic || item.productMainPic" width="60" height="60" oss_style="60_60" />
                      <div>
                        <p class="goods-name el2">{{item.productName}}</p>
                        <p class="sku-info">规格：{{item.productSkuName}}</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'price'">
                      <div>
                        <p>{{item.price}}元</p>
                        <p>{{item.count}}件</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'service'">
                      <div class="table-operations">
                        <a-button v-if="item.returnState != null" type="link" @click="$router.push(`/purchase/service_detail/${item.orderWholesaleReturnId}`)">
                          {{getUserReturnText(item.returnState)}}
                        </a-button>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'realAmount'">
                      <div>
                        <p>{{item.realAmount}}元</p>
                        <p>(含运费：{{item.logisticsAmount}}元)</p>
                      </div>
                    </div>
                    <div class="order-state" v-if="column.dataIndex === 'orderState'">
                      {{handleOrderState(item.orderState + '')}}
                      <br>
                      <a v-if="[2, 3].includes(item.orderState)" href="javascript:;" class="link" @click="$router.push(`/purchase/batch_order_detail/${item.orderWholesaleInfoId}`)">查看物流</a>
                    </div>
                    <div v-if="column.dataIndex === 'operation'">
                      <div class="table-operations">
                        <a-button v-if="item.orderState === 0" type="link" @click="handlePay(item.orderWholesaleInfoId)">付款</a-button>
                        <a-popconfirm v-if="item.orderState === 0" placement="topRight" title="确定要取消订单吗？" ok-text="确定" cancel-text="取消" @confirm="closeOrder(item.orderWholesaleInfoId)">
                          <a-button type="link" :loading="closing === item.orderWholesaleInfoId">关闭交易</a-button>
                        </a-popconfirm>
                        <a-button v-if="(item.orderState > 0 && item.orderState < 3 && [-1, -2, undefined].includes(item.returnState)) && item.afterFlag !== false" type="link" @click="$router.push(`/purchase/service_apply/${item.orderWholesaleInfoId}/${item.orderWholesaleItemId}`)">申请售后</a-button>
                        <a-popconfirm v-if="item.orderState === 2" placement="topRight" title="确认收货后将无法维权，确定吗？" ok-text="确定" cancel-text="取消" @confirm="confirmOrder(item.orderWholesaleInfoId)">
                          <a-button type="link" :loading="confirming === item.orderWholesaleInfoId">确认收货</a-button>
                        </a-popconfirm>
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <!-- 批发发货订单 -->
          <template v-else-if="isBatchSell">
            <template v-for="item in dataSource">
              <tr class="sep-row" :key="`th${item.orderWholesaleReturnId || item.orderNo}`">
                <td :colspan="columns.length"></td>
              </tr>
              <tr class="tr-th shop-th bd" :key="`batch_${item.orderWholesaleReturnId || item.orderNo}`">
                <td :colspan="columns.length">
                  <div class="shop-order-info">
                    <div>
                      <span class="info-item">订单号：{{item.orderNo}}</span>
                      <span class="info-item">下单时间：{{item.orderTime}}</span>
                      <span class="info-item">由供应商进货，联系电话：{{item.serviceTel}}</span>
                    </div>
                    <div>
                      <a href="javascript:;" class="link" @click="$router.push(`/purchase/batch_order_detail/${item.orderWholesaleInfoId}`)">查看详情</a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="tr-goods" :key="`batch_item${item.orderWholesaleReturnId || item.orderNo}`">
                <template v-for="column in columns">
                  <td :key="`shopColumn${item.orderWholesaleReturnId || item.orderNo}${column.dataIndex}`">
                    <div v-if="column.dataIndex === 'name'" class="goods-info">
                      <base-img class="goods-img" :src="item.productSkuPic || item.productMainPic" width="60" height="60" oss_style="60_60" />
                      <div>
                        <p class="goods-name el2">{{item.productName}}</p>
                        <p class="sku-info">规格：{{item.productSkuName}}</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'price'">
                      <div>
                        <p>{{item.price}}元</p>
                        <p>{{item.count}}件</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'service'">
                      <div class="table-operations">
                        <a-button v-if="item.returnState != null" type="link" :style='{color:item.returnState===0?"red":"" }' @click="$router.push(`/purchase/service_detail/${item.orderWholesaleReturnId}/yes`)">
                          {{getUserReturnText(item.returnState)}}
                        </a-button>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'realAmount'">
                      <div>
                        <p>{{item.realAmount}}元</p>
                        <p>(含运费：{{item.logisticsAmount}}元)</p>
                      </div>
                    </div>
                    <div class="order-state" v-if="column.dataIndex === 'orderState'">
                      {{handleOrderState(item.orderState + '')}}
                      <br>
                      <a v-if="[2, 3].includes(item.orderState)" href="javascript:;" class="link" @click="$router.push(`/purchase/batch_order_detail/${item.orderWholesaleInfoId}`)">查看物流</a>
                    </div>
                    <div v-if="column.dataIndex === 'operation'">
                      <div class="table-operations">
                        <a-button v-if="item.orderState === 1" type="link" @click="handleBatchDelivery(item)">发货</a-button>
                        <a-button v-if="item.orderState === 2" type="link" @click="handleBatchUpdateDelivery(item)">修改物流</a-button>
                      </div>
                    </div>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <!-- 普通订单 -->
          <template v-else-if="orderState !== '-1'">
            <template v-for="item in dataSource">
              <tr class="sep-row" :key="`th${item.mainOrderNo}`">
                <td :colspan="columns.length"></td>
              </tr>
              <tr class="tr-th shop-th" :key="item.mainOrderNo">
                <td :colspan="columns.length">
                  <span class="info-item">订单号：{{item.mainOrderNo}}</span>
                  <span class="info-item">下单时间：{{item.orderTime}}</span>
                  <span class="info-item">下单用户：{{item.userName}}</span>
                  <span class="info-item">{{handlePayMode(item.payMode)}}</span>
                </td>
              </tr>
              <template v-for="shopOrder in item.orders">
                <tr class="tr-th tr-info" :key="`shopOrderInfo${shopOrder.orderNo}`">
                  <td :colspan="columns.length">
                    <div class="shop-order-info">
                      <div>
                        <span class="info-item">订单号：{{shopOrder.orderNo}}</span>
                        <span class="info-item">下单时间：{{item.orderTime}}</span>
                        <span v-if="shopOrder.orderType !== 1" class="info-item" style='color:red;'>{{handleOrderType(shopOrder.orderType)}}</span>
                        <span class="info-item">供应商发货，售后电话：{{shopOrder.supplyAfterServiceTel}}</span>
                        <span class="info-item" v-if="shopOrder.voucherCode">兑换码：{{shopOrder.voucherCode}}</span>
                      </div>
                      <div>
                        <a href="javascript:;" class="link" @click="$router.push(`/order/goods_order/detail/${shopOrder.shopOrderId}`)">查看详情</a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr class="tr-goods" v-for="(product, productIndex) in shopOrder.products" :key="product.orderItemId">
                  <template v-for="column in columns">
                    <td v-if="['name', 'price', 'service'].indexOf(column.dataIndex) >= 0 || (['name', 'price', 'service'].indexOf(column.dataIndex) < 0 && !productIndex)" :key="`shopColumn${shopOrder.orderNo}${column.dataIndex}`" :rowspan="['name', 'price', 'service'].indexOf(column.dataIndex) < 0 && !productIndex ? shopOrder.products.length : ''">
                      <div v-if="column.dataIndex === 'name'" class="goods-info">
                        <base-img class="goods-img" :src="product.productSkuPic||product.productMainPic" width="60" height="60" />
                        <div>
                          <p class="goods-name el2">{{product.productName}}</p>
                          <p class="sku-info">规格：{{product.productSkuName||'-'}}</p>
                        </div>
                      </div>
                      <div v-if="column.dataIndex === 'price'">
                        <div>
                          <p>{{product.price}}元</p>
                          <p>{{product.count}}件</p>
                        </div>
                      </div>
                      <div v-if="column.dataIndex === 'service'">
                        <div class="table-operations">
                          <a-button v-if="typeof product.returnState !== 'undefined'" type="link" :style='{color:product.returnState===0?"red":"" }' @click="$router.push(`/order/goods_order/service_detail/${product.shopOrderItemId}`)">
                            {{getUserReturnText(product.returnState)}}
                          </a-button>
                        </div>
                      </div>
                      <div v-if="column.dataIndex === 'receiver'">
                        <div>
                          <p>{{shopOrder.receiverName}}</p>
                          <p>{{shopOrder.receiverPhone}}</p>
                        </div>
                      </div>
                      <div v-if="column.dataIndex === 'delivery'">
                        <div>
                          <p>{{shopOrder.orderType===6?'上门服务':shopOrder.deliveryMode === 0 ? '物流配送':'自行配送'}}</p>
                        </div>
                      </div>
                      <div v-if="column.dataIndex === 'realAmount'">
                        <div>
                          <p>{{shopOrder.realAmount}}</p>
                          <p v-if="item.supplyShopId===currentShopId">(含运费：{{shopOrder.logisticsAmount}})</p>
                        </div>
                      </div>
                      <div v-if="column.dataIndex === 'orderState'">{{shopOrder.orderType===6&&shopOrder.orderState==='2'?'待完成':handleOrderState(shopOrder.orderState)}}</div>
                      <div v-if="column.dataIndex === 'operation'">
                        <div class="table-operations" v-if="shopOrder.supplyShopId === currentShopId">
                          <a-button v-if="shopOrder.orderState === '1'" type="link" @click="handleDelivery(shopOrder)">发货</a-button>
                          <a-popconfirm  v-if="shopOrder.orderState === '0'||shopOrder.orderState === '1'" placement="topRight" :title="`确定要取消该订单吗？`" ok-text="确定" cancel-text="取消" @confirm="cancelOrder(shopOrder)">
                            <a-button type="link">取消订单</a-button>
                          </a-popconfirm>
                          <a-button v-if="shopOrder.orderState === '2'&&shopOrder.orderType!==6" type="link" @click="handleUpdateDelivery(shopOrder)">修改配送信息</a-button>
                          <a-button v-if="shopOrder.orderType===7 && shopOrder.orderState === '6'" type="link" @click="handleDeliveryWriteOff(shopOrder)">核销</a-button>
                        </div>
                      </div>
                    </td>
                  </template>
                </tr>
              </template>
            </template>
          </template>
          <!-- 售后订单 -->
          <template v-else>
            <template v-for="item in dataSource">
              <tr class="sep-row" :key="`th${item.orderReturnApplyId}`">
                <td :colspan="columns.length"></td>
              </tr>
              <tr class="tr-th shop-th bd" :key="`orderAfterInfo${item.orderReturnApplyId}`">
                <td :colspan="columns.length">
                  <div class="shop-order-info">
                    <div>
                      <span class="info-item">订单号：{{item.orderNo}}</span>
                      <span class="info-item">下单时间：{{item.orderTime}}</span>
                      <span v-if="item.orderType !== 1" class="info-item">{{handleOrderType(item.orderType)}}</span>
                    </div>
                    <div>
                      <a href="javascript:;" class="link" @click="$router.push(`/order/goods_order/service_detail/${item.shopOrderItemId}`)">查看详情</a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="tr-goods" :key="`prodtuct${item.orderReturnApplyId}`">
                <template v-for="column in columns">
                  <td :key="`shopColumn${item.orderReturnApplyId}${column.dataIndex}`">
                    <div v-if="column.dataIndex === 'name'" class="goods-info">
                      <base-img class="goods-img" :src="item.productSkuPic||item.productMainPic" width="60" height="60" />
                      <div>
                        <p class="goods-name el2">{{item.productName}}</p>
                        <p class="sku-info">规格：{{item.productSkuName}}</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'price'">
                      <div>
                        <p>{{item.price}}元</p>
                        <p>{{item.count}}件</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'service'">
                      <div class="table-operations">
                        <a-button v-if="typeof item.returnState !== 'undefined'" type="link" @click="$router.push(`/order/goods_order/service_detail/${item.shopOrderItemId}`)">
                          {{getUserReturnText(item.returnState)}}
                        </a-button>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'receiver'">
                      <div>
                        <p>{{item.receiverName}}</p>
                        <p>{{item.receiverPhone}}</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'delivery'">
                      <div>
                        <p>{{item.deliveryMode&&item.deliveryMode === 1 ? '自行配送':'物流配送'}}</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'realAmount'">
                      <div>
                        <p>{{item.realAmount}}</p>
                        <p v-if="item.supplyShopId===currentShopId">(含运费：{{item.logisticsAmount}})</p>
                      </div>
                    </div>
                    <div v-if="column.dataIndex === 'orderState'">{{handleOrderState(item.orderState)}}</div>
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <div v-if="!dataSource.length" class="custom-empty">
        <a-empty />
      </div>
      <div class="custom-pagination">
        <a-pagination v-model="pagination.current" size="small" :page-size="pagination.size" :total="pagination.total" :show-total="total => `共 ${total} 条`" @change="handlePaginationChange" />
      </div>
    </a-spin>
    <!-- 订单发货 -->

    <dialog-delivery :is-batch="isBatchSell" :show.sync="dialogDeliveryShow" :delivery-info="currentOrderDetail" @update="$emit('update')" />
    <!-- 修改物流 -->
    <dialog-update-delivery :is-batch-sell="isBatchSell" :show.sync="updateDeliveryShow" :delivery-info="currentOrderDetail" @update="$emit('update')" />
    <a-modal v-model="showPayModalFlag" @cancel="showPayModalFlag = false, pendingPay = ''" @ok="doPay" :confirm-loading="paying">
      <ModalBatchPay ref="batchPay" :order-no="pendingPay" />
    </a-modal>
    <dialog-write-off :show.sync="showWirteOffDialog" :orderData="orderData" @success="wirteOffSuccess" />
  </div>
</template>

<script>
import { payModes, orderStates, orderReturnStates, orderTypes } from '@/utils/constants'
import DialogDelivery from '@/views/order/DialogDelivery'
import DialogUpdateDelivery from '@/views/order/DialogUpdateDelivery'
import DialogWriteOff from '@/views/order/DialogWriteOff'
import { ref } from '@vue/composition-api'
import pick from 'lodash/pick'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import ModalBatchPay from '@/views/purchase/ModalBatchPay'
import { wrapAwait } from '@/utils/tools'
import { message } from 'ant-design-vue'
import { order } from '@/api'

export default {
  name: 'OrderTable',

  props: {
    isBatchBuy: Boolean,
    isBatchSell: Boolean,
    columns: Array,
    dataSource: Array,
    loading: Boolean,
    orderState: String,
    pagination: {
      type: Object,
      default () {
        return {
          current: 1,
          size: 10,
          total: 0,
        }
      },
    },
  },

  emits: ['update'],

  components: {
    ModalBatchPay,
    DialogDelivery,
    DialogUpdateDelivery,
    DialogWriteOff,
  },

  setup (props, { root, emit }) {
    const currentShopId = getSession(SHOPID)
    function handlePayMode (value) {
      const matching = payModes.find((x) => x.value === value)
      return matching ? matching.name : ''
    }
    function handleOrderState (value) {
      const matching = orderStates.find((x) => x.value === value)
      return matching ? matching.name : ''
    }

    const currentOrderDetail = ref({})
    // 订单发货
    const dialogDeliveryShow = ref(false)
    function handleDelivery (shopOrder) {
      currentOrderDetail.value = pick(shopOrder, ['shopOrderId', 'orderInfoId'])
      dialogDeliveryShow.value = true
    }
    async function cancelOrder (shopOrder) {
      const [err, data] = await wrapAwait(order.cancelOrder(shopOrder.orderInfoId))
      confirming.value = ''
      if (err) {
        message.error(err.message || '取消订单失败')
      } else {
        message.success('取消订单成功')
        emit('update')
      }
    }
    // 批发：订单发货
    function handleBatchDelivery (order) {
      currentOrderDetail.value = order.orderWholesaleInfoId
      dialogDeliveryShow.value = true
    }
    // 修改物流
    const updateDeliveryShow = ref(false)
    function handleUpdateDelivery (shopOrder) {
      currentOrderDetail.value = Object.assign({}, shopOrder)
      updateDeliveryShow.value = true
    }
    // 批发：修改物流
    function handleBatchUpdateDelivery (order) {
      currentOrderDetail.value = order.orderWholesaleInfoId
      updateDeliveryShow.value = true
    }

    function getUserReturnText (returnState) {
      const matching = orderReturnStates.find((x) => x.value === +returnState)
      return matching ? matching.orderBtn : ''
    }

    function handlePaginationChange (page) {
      emit('paginationChange', page)
    }

    function handleOrderType (orderType) {
      const matching = orderTypes.find((x) => x.value === orderType)
      return matching ? matching.name : ''
    }

    function handleToShopIndex (supplyShopId) {
      return false
      // if (supplyShopId === currentShopId) {
      //   root.$router.push('/goods/list')
      // } else {
      //   root.$router.push(`/goods/shop_goods_list/${supplyShopId}`)
      // }
    }
    const showWirteOffDialog = ref(false)
    const orderData = ref({})
    function handleDeliveryWriteOff (order) {
      showWirteOffDialog.value = true
      orderData.value = order
    }
    function wirteOffSuccess () {
      showWirteOffDialog.value = false
      emit('update')
    }

    const pendingPay = ref('')
    const batchPay = ref(null)
    const paying = ref(false)
    const showPayModalFlag = ref(false)
    function handlePay (orderId) {
      showPayModalFlag.value = true
      pendingPay.value = orderId
    }
    async function doPay () {
      paying.value = true
      const [err, data] = await wrapAwait(batchPay.value.submit())
      paying.value = false
      if (!err) {
        pendingPay.value = ''
        showPayModalFlag.value = false
        message.success('提交成功')
        emit('update')
      }
    }

    const closing = ref('')
    async function closeOrder (orderId) {
      closing.value = orderId
      const [err, data] = await wrapAwait(order.cancelBatchOrder(orderId))
      closing.value = ''
      if (err) {
        message.error(err.message || '关闭订单失败')
      } else {
        message.success('关闭订单成功')
        emit('update')
      }
    }

    const confirming = ref('')
    async function confirmOrder (orderId) {
      confirming.value = orderId
      const [err, data] = await wrapAwait(order.confirmBatchOrder(orderId))
      confirming.value = ''
      if (err) {
        message.error(err.message || '确认收货失败')
      } else {
        message.success('确认收货成功')
        emit('update')
      }
    }

    return {
      currentShopId,
      handlePayMode,
      handleOrderState,
      dialogDeliveryShow,
      currentOrderDetail,
      handleDelivery,
      cancelOrder,
      updateDeliveryShow,
      handleUpdateDelivery,
      handleBatchDelivery,
      handleBatchUpdateDelivery,
      getUserReturnText,
      handlePaginationChange,
      handleOrderType,
      handleToShopIndex,
      handleDeliveryWriteOff,
      showWirteOffDialog,
      orderData,
      wirteOffSuccess,
      handlePay,
      pendingPay,
      batchPay,
      doPay,
      paying,
      showPayModalFlag,
      closeOrder,
      closing,
      confirming,
      confirmOrder,
    }
  },
}
</script>

<style lang="less" scoped>
@border-color: #e8e8e8;
.order-table table {
  width: 100%;
  border-collapse: collapse;
  color: rgba(0, 0, 0, 0.65);
  td {
    padding: 10px 16px;
  }
}
.tr-th {
  font-size: 14px;
  background-color: #fafafa;
  th {
    padding: 16px;
    font-weight: 500;
  }
  &.tr-info {
    border: 1px solid @border-color;
    border-top: none;
    background-color: #fff;
  }
}
.sep-row {
  height: 20px;
}
.info-item {
  margin-right: 12px;
}
.shop-order-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .link {
    margin: 0 1px;
    color: @link-color;
    font-style: normal;
  }
}
.order-state .link {
  color: @link-color;
  font-style: normal;
}
.shop-th {
  border: 1px solid @border-color;
  border-bottom: none;
  &.bd {
    border-bottom: 1px solid @border-color;
  }
}
.goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    margin-right: 12px;
  }
  .goods-name {
    flex: none;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
    color: #0066ff;
  }
  .sku-info {
    max-width: 130px;
    font-size: 12px;
  }
}
.tr-goods {
  border-left: 1px solid @border-color;
  td {
    border-right: 1px solid @border-color;
    border-bottom: 1px solid @border-color;
  }
}
.custom-empty {
  padding: 24px 0;
}
.custom-pagination {
  padding: 16px 0;
  text-align: right;
}
</style>
