<template>
  <a-row>
    <a-col :span="14">
      <a-input :value="value" @input='handleInput' placeholder="请输入短信验证码" />
    </a-col>
    <a-col :span="10">
      <div class="sms-box">
        <div class="sms-btn" @click="getSmsCode" :class="{'sms-btn-disabled':smsBtnDisabled}">
          <a-icon type="loading" v-if="smsBtnLoading" style="margin-right:4px" />
          <span>{{smsTest}}</span>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'
import { common } from '@/api'
export default {
  name: 'SmsCode',
  props: {
    value: {
      type: String
    },
    phone: {
      type: String
    },
    type: {
      type: Number
    }
  },
  setup (props, { root, emit }) {
    const state = reactive({
      smsTest: '获取验证码',
      smsBtnDisabled: false,
      smsBtnLoading: false,
      interval: null,
      isGeted: false
    })
    function clearIntervalFn () {
      if (state.interval) {
        state.smsTest = '获取验证码'
        state.smsBtnDisabled = false
        clearInterval(state.interval)
        state.interval = null
      }
    }
    function handleInput (e) {
      emit('input', e.target.value)
    }
    async function getSmsCode () {
      if (state.smsBtnDisabled || state.smsBtnLoading) return
      if (!props.phone) {
        return root.$message.error('请输入手机号码')
      }
      if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(props.phone)) {
        return root.$message.error('请输入正确的手机号')
      }
      state.smsBtnLoading = true
      let { code, msg } = await common.getSmsCode({
        type: props.type,
        phone: props.phone,
        shopId: props.shopId
      })
      emit('isGeted', true)
      state.smsBtnLoading = false
      if (code === '00000') {
        state.smsBtnDisabled = true
        let num = 60
        state.smsTest = `${num}s 后重试`
        state.interval = setInterval(() => {
          num--
          state.smsTest = `${num}s 后重试`
          if (num <= 0) {
            clearIntervalFn()
          }
        }, 1000)
      } else {
        root.$message.error(msg || '获取验证码失败')
      }
    }

    return {
      getSmsCode,
      ...toRefs(state),
      handleInput,
      clearIntervalFn
    }
  }
}
</script>
<style lang="less" scoped>
.sms-box {
  padding-left: 12px;
}
.sms-btn {
  width: 100%;
  background: @main-color;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 4px;
  line-height: 32px;
}
.sms-btn-disabled {
  background: #ddd;
  color: #666;
}
</style>
