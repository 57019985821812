<template>
  <div class="app-tabs">
    <a-tabs
      type="editable-card"
      hideAdd
      :activeKey="$route.path"
      @edit="closeView"
      @change="toggleTab">
      <a-tab-pane
        v-for="pane in tabs"
        :tab="pane.meta.title"
        :closable="!pane.meta.noClose"
        :key="pane.path"
      ></a-tab-pane>
    </a-tabs>
    <a-popover
      placement="bottomRight"
      overlayClassName="app-tabs-operation"
      >
      <template slot="content"
           >
        <div class="operation-item" @click="refresh">
          <a-icon type="reload" />
          刷新当前
        </div>
        <div class="operation-item" @click="closeOthers">
          <a-icon type="close" />
          关闭其他
        </div>
        <div class="operation-item" @click="closeAll">
          <a-icon type="poweroff" />
          关闭所有
        </div>
      </template>
      <div class="tabs-operation"><a-icon type="caret-down" /></div>
    </a-popover>
  </div>
</template>

<script>
export default {
  name: 'AppTabs',

  mounted () {
    this.addNewTab()
    this.$bus.$on('closeCurrentView', () => {
      if (this.currentView) this.closeView(this.currentView.path)
    })
  },

  data () {
    return {
      oldRoute: null,
      popoverVisible: false
    }
  },

  watch: {
    $route (route, oldRoute) {
      this.addNewTab()
      this.oldRoute = oldRoute
      this.$emit('routeChange')
    }
  },

  computed: {
    tabs () {
      return this.$store.state.tabs.views
    },
    currentView () {
      return this.tabs.find(x => this.$route.path === x.path)
    }
  },

  methods: {
    addNewTab () {
      this.$store.dispatch('tabs/addTab', this.$route)
    },
    toggleTab (path) {
      let item = this.tabs.find(x => x.path === path)
      this.$router.push(item.fullPath || path)
    },
    async closeView (path) {
      let item = this.tabs.find(x => x.path === path)
      let visitedViews = await this.$store.dispatch('tabs/delTab', item)
      if (this.$route.path === item.path) {
        const previousVisitedView = this.oldRoute ? visitedViews.find(x => x.path === this.oldRoute.path) : null
        const lastVisitedView = visitedViews.slice(-1)[0]
        if (previousVisitedView) {
          this.$router.push(previousVisitedView.fullPath || previousVisitedView.path)
        } else if (lastVisitedView) {
          this.$router.push(lastVisitedView.fullPath || lastVisitedView.path)
        } else {
          this.$router.push('/')
        }
      }
    },
    async refresh () {
      const { fullPath, path } = this.currentView
      await this.$store.dispatch('tabs/delOnlyCachedTab', this.currentView)
      await this.$nextTick()
      this.$router.replace({
        path: '/redirect' + (fullPath || path)
      })
    },
    closeOthers () {
      this.$store.dispatch('tabs/delAllOtherTabs', this.currentView)
    },
    async closeAll () {
      await this.$store.dispatch('tabs/delAllTabs')
      this.$router.replace({ path: '/' })
    }
  }
}
</script>

<style lang="less" scoped>
.app-tabs {
  display: flex;
  height: 40px;
  min-width: 900px;
  border-bottom: 1px solid #e8e8e8;
  background-color: #fff;
  ::v-deep .ant-tabs.ant-tabs-card {
    flex: 1;
    .ant-tabs-bar {
      border: none;
    }
    .ant-tabs-tab {
      margin: 0;
      padding: 9px 16px;
      line-height: 23px;
      background-color: #fff;
      border: none;
      border-radius: 0;
      &.ant-tabs-tab-active {
        color: #2c3e50;
        background-color: #eee;
      }
      &:hover {
        color: #2c3e50;
      }
    }
    .ant-tabs-ink-bar {
      display: none!important;
    }
  }
  .tabs-operation {
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 100%;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
}
.app-tabs-operation {
  .operation-item {
    padding: 0 6px;
    border-bottom: 1px dotted  #ccc;
    line-height: 40px;
    cursor: pointer;
    &:hover {
      color: @main-color;
    }
  }
}
</style>
