<template>
  <div class="base-screen">
    <a-collapse defaultActiveKey='1' style='background-color: #F3F6F8;' expandIconPosition='right' :bordered="false">
      <template #expandIcon="props">
        <a-icon type="up" style='font-size:14px;' :rotate="props.isActive ? 180 : 0" />
      </template>
      <a-collapse-panel key="1" :header="title">
        <div class='solt-div'>
          <slot></slot>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'

export default {
  name: 'BaseScreen',

  props: {
    title: {
      type: String,
      default: '查询条件'
    },
    open: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    const refContentSlot = ref(null)
    let contentShow = ref(false)
    let contentHeight = computed(() => refContentSlot.value.offsetHeight)

    onMounted(() => {
      contentShow.value = props.open
    })

    return {
      refContentSlot,
      contentShow,
      contentHeight
    }
  }
}
</script>

<style lang="less" scoped>
.base-screen {
  margin-bottom: 18px;
  background-color: #F3F6F8;
  ::v-deep .ant-collapse-item{
    border: none;
  }
  ::v-deep .ant-collapse-header {
    line-height: 48px;
    border-bottom: 1px solid #ddd;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 900;
  }
  ::v-deep .ant-form-inline .ant-form-item {
    margin-right: 32px;
    .ant-btn-default {
      margin-left: 10px;
    }
    .ant-btn-primary {
      margin-right: 12px;
    }
  }
  .solt-div {
    padding: 10px 20px 0;
  }
}
</style>
