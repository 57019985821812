<template>
  <div class="base-img">
    <img
      v-show="!isError"
      :src="imgSrc"
      :alt="alt"
      :width="width"
      :height="height"
      @load="handleImgLoaded"
    />
    <div
      v-show="isError"
      class="img-default"
      :style="{width: `${width}px`, height: `${height}px`}"
    >
      <icon-svg icon-class="img-default" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-img',
  props: {
    // 单个图片路径
    src: String,
    // 配置图片原生属性-alt
    alt: {
      type: String,
      default: 'Img'
    },
    // 配置图片原生属性-宽度
    width: {
      type: String,
      default: '200'
    },
    // 配置图片原生属性-高度
    height: {
      type: String,
      default: ''
    },
    ossStyle: {
      type: String,
      default: ''
    }
  },

  computed: {
    imgSrc () {
      if (!this.src) return ''
      const src = this.src.indexOf('data:image/') >= 0 || this.src.indexOf('http') >= 0 ? this.src : `${this.baseUrl}${this.src}`
      return `${src}${this.ossStyle ? '/' + this.ossStyle : ''}`
    }
  },

  data () {
    return {
      baseUrl: process.env.VUE_APP_IMG,
      isError: true
    }
  },

  methods: {
    handleImgLoaded () {
      this.isError = false
    }
  }
}
</script>

<style lang="less" scoped>
.base-img {
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.img-default {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 24px;
  background: #fafafa;
}
</style>
