import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/helper',
  component: AppLayout,
  children: [
    {
      path: '',
      component: () => import('@/views/helper/PageIndex.vue'),
      meta: { title: '帮助中心', noClose: false, showInMenuFlag: false }
    }
  ]
}
