<!--
 * @Author: your name
 * @Date: 2021-09-01 20:14:14
 * @LastEditTime: 2021-09-03 19:44:54
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \mms\src\views\h5\PageBindSuccess.vue
-->
<template>
  <div>
    <div class='h5_page'>
      <img src="@/assets/success.png" alt="" class='success-page'>
      <p class='success-title'>{{msg?'操作':'绑定'}}成功</p>
      <p class='desc' v-if='msg'>结果：{{msg}}</p>
      <!-- <div class='back-btn' @click='close'>关闭</div> -->
      <p class='tips'>您可以前往pc端查看<br>访问地址：<a style='color: dodgerblue;' href="http://xlshow-mms.hongse.tech" target="_blank">http://xlshow-mms.hongse.tech</a></p>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from '@vue/composition-api'
export default {
  name: 'BindSuccess',
  setup (props, { root }) {
    document.title = root.$route.meta.title
    const state = reactive({
      msg: ''
    })
    function close () {
      window.close()
    }
    onMounted(() => {
      if (root.$route.query.msg) {
        state.msg = decodeURIComponent(root.$route.query.msg)
      }
    })
    return {
      ...toRefs(state),
      close
    }
  }
}
</script>
<style lang="less" scoped>
.h5_page {
  min-height: 100vh;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  padding-top: 44px;
}
.success-page {
  display: block;
  width: 40%;
  object-fit: contain;
  margin: 0 auto;
  margin-bottom: 40px;
}
.success-title {
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  color: #000;
}
.back-btn {
  position: fixed;
  bottom: 136px;
  left: 10vw;
  line-height: 40px;
  border-radius: 6px;
  text-align: center;
  margin: 0 auto;
  margin-top: 24px;
  color: #fff;
  width: 80vw;
  font-size: 16px;
  background-color: rgba(63, 196, 61);
}
.tips {
  margin-top: 48px;
  padding: 0 48px;
  text-align: center;
  line-height: 24px;
}
.desc {
  margin-top: 16px;
  padding: 0 16px;
  text-align: center;
  line-height: 24px;
  color: red;
}
</style>
