<template>
  <div>
    <a-modal :getContainer='getContainer' :zIndex='1001' :maskClosable='false' :visible="show" width='860px' title="选择视频" @ok="handleOk" @cancel='handleCancel'>
      <a-spin :spinning='loading'>
        <div style='display:flex;'>
          <div class='tab-box'>
            <div class='tab-item' :class='{active:currentGroupId === item.id}' v-for='item in groupList' :key='item.id' @click='changeTab(item.id,item.officeFlag)'>{{item.name}}</div>
          </div>
          <div class='flex-a-c' style='justify-content: flex-end;'>
            <a-button type='primary' @click='$router.push("/content/resource")'>视频管理</a-button>
            <a-button type='link' @click="init()">刷新</a-button>
          </div>
        </div>
        <div class='img-box' v-if='list.length'>
          <div v-for='(img) in list' :key='img.shopMaterialId' class='img-item'>
            <video class='img' controls :src="baseUrl + img.picPath">
              当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="baseUrl + img.picPath" target="_blank">下载视频</a>
            </video>
            <a-checkbox class='img-name' @change="onSelectImg($event,img)" :checked="selectImgData.findIndex(x=>x === img.picPath) !== -1">
              {{img.name}}
            </a-checkbox>
          </div>
        </div>
        <div v-else style='margin-top: 30px'>
          <a-empty description='暂无视频素材' />
        </div>
        <div class='pagination-box'>
          <div class='flex-a-c'>
            <div class='select-num-box'>已选<span>{{selectImgData.length}}</span>个</div>
            <div class='max-num-box' v-if='multiple'>，最多可选<span>{{max}}</span>个</div>
          </div>
          <a-pagination size="small" v-model="current" :pageSize='pageSize' :total="total" @change='changePage' @showSizeChange="onShowSizeChange" />
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { shop } from '@/api'
const deepClone = (obj) => {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // 判断obj子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          // 如果不是，简单复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
export default {
  name: 'selectVideoDialog',
  props: {
    show: {
      type: Boolean,
      default () {
        return false
      },
    },
    initSelectData: {
      type: Array,
      default () {
        return []
      },
    },
    multiple: {
      type: Boolean,
      default () {
        return false
      },
    },
    max: {
      type: Number,
      default () {
        return 9
      },
    },
    idName: {
      type: String,
    },
  },
  data () {
    return {
      loading: false,
      currentGroupId: '',
      current: 1,
      pageSize: 12,
      total: 0,
      list: [],
      groupList: [],
      selectImgData: [],
      officeFlag: false,
      baseUrl: process.env.VUE_APP_IMG,
    }
  },
  watch: {
    initSelectData: {
      handler (val) {
        this.selectImgData = deepClone(val)
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted () {
    this.loading = true
    await this.init()
    this.selectImgData = Object.assign([], this.initSelectData)
    this.loading = false
  },
  methods: {
    getContainer () {
      return document.getElementById(this.idName)
    },
    handleCancel () {
      this.$emit('change', this.initSelectData)
    },
    handleOk () {
      this.$emit('change', this.selectImgData)
    },
    async init () {
      await this.getGroup()
      if (this.currentGroupId) {
        this.getResourcePage()
      }
    },
    changePage (e) {
      this.current = e
      this.getResourcePage()
    },
    onShowSizeChange (current, pageSize) {
      this.current = current
      this.pageSize = pageSize
      this.getResourcePage()
    },
    async getResourcePage () {
      this.loading = true
      const { data, msg, code, page } = await shop.getResourcePage({
        current: this.current,
        size: this.pageSize,
        groupId: this.currentGroupId,
        officeFlag: this.officeFlag,
      })
      this.loading = false
      if (code === '00000') {
        this.list = data
        this.total = page.total
        this.current = page.pageNo
        this.pageSize = page.pageSize
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    async getGroup () {
      const { data, msg, code } = await shop.getSourceGroup({
        type: 1,
      })
      if (code === '00000') {
        this.groupList = data
        if (!this.currentGroupId && data.length) {
          this.currentGroupId = data[0].id
          this.officeFlag = data[0].officeFlag
        }
      } else {
        this.$message.error(msg || '获取分组数据失败')
      }
    },
    changeTab (id, officeFlag) {
      this.currentGroupId = id
      this.officeFlag = officeFlag
      this.current = 1
      this.getResourcePage()
    },
    onSelectImg (e, obj) {
      if (this.multiple) {
        const index = this.selectImgData.findIndex((x) => x === obj.picPath)
        // 选中
        if (e.target.checked) {
          // 不存在  --> 插入
          if (this.selectImgData.length >= this.max) {
            return this.$message.error(`最多不得超过${this.max}个`)
          }
          this.selectImgData.push(obj.picPath)
        } else {
          this.selectImgData.splice(index, 1)
        }
      } else {
        this.selectImgData = [obj.picPath]
      }
    },
  },
}
</script>

<style lang="less" scoped>
.tab-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 160px);
  .tab-item {
    padding: 0 12px;
    border: 1px solid #999;
    margin: 0 6px 6px;
    line-height: 28px;
    border-radius: 4px;
    color: #999;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.active {
      background: @main-color;
      color: #fff;
      border: 1px solid @main-color;
    }
    &:hover {
      color: @main-color;
      border-color: @main-color;
    }
    &:hover.active {
      background: @main-color;
      color: #fff;
      border: 1px solid @main-color;
    }
  }
}
.pagination-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .select-num-box {
    padding-left: 6px;
    span {
      color: @main-color;
      font-weight: 500;
      padding: 4px;
      font-size: 18px;
    }
  }
  .max-num-box {
    span {
      color: @main-color;
      font-weight: 500;
      padding: 4px;
      font-size: 18px;
    }
  }
}
.img-box {
  margin: 24px 0;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  .img-item {
    position: relative;
    margin: 0px 6px 12px;
    width: 150px;
    .img-name {
      font-size: 12px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      cursor: pointer;
      margin-top: 4px;
    }

    .img {
      width: 100%;
      height: 120px;
      object-fit: contain;
    }
  }
}
.viewer-image {
  display: none;
}
.flex-a-c {
  display: flex;
  align-items: center;
}
.flex-j-b {
  display: flex;
  justify-content: center;
}
// ::v-deep .ant-checkbox-wrapper {
//   display: flex;
//   align-items: center;
//   span{
//     display: block;
//     flex:1;
//   }
//   .ant-checkbox {
//     width: 16px;
//   }
// }
</style>
