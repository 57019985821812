import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/customer',
  component: AppLayout,
  children: [
    {
      path: '',
      component: () => import('@/views/customer/PageIndex.vue'),
      meta: { title: '客户管理', noClose: false, showInMenuFlag: false }
    }
  ]
}
