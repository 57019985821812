import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/goods',
  component: AppLayout,
  children: [
    {
      path: 'list',
      component: () => import('@/views/goods/PageGoodsList.vue'),
      meta: { title: '商品列表', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'tags',
      component: () => import('@/views/goods/PageTags.vue'),
      meta: { title: '商品分组', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'publish/add',
      component: () => import('@/views/goods/PageGoodsPublish.vue'),
      meta: { title: '发布商品', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'publish/edit/:productId',
      props: route => ({
        type: 'edit',
        productId: route.params.productId
      }),
      component: () => import('@/views/goods/PageGoodsPublish.vue'),
      meta: { title: '编辑商品', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'publish/collect/:params',
      props: route => ({
        type: 'collect',
        ...route.params
      }),
      component: () => import('@/views/goods/PageGoodsPublish.vue'),
      meta: { title: '采集商品', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'collect_market',
      component: () => import('@/views/goods/PageCollectMarket.vue'),
      meta: { title: '采集市场', noClose: false, showInMenuFlag: true, noCache: false }
    },
    {
      path: 'goods_collect_detail/:params',
      props: true,
      component: () => import('@/views/goods/PageGoodsCollectDetail.vue'),
      meta: { title: '商品采集详情', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'goods_detail/:productId',
      props: true,
      component: () => import('@/views/goods/PageGoodsDetail.vue'),
      meta: { title: '商品详情', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'shop_goods_list/:shopId',
      props: true,
      component: () => import('@/views/goods/PageShopGoods.vue'),
      meta: { title: '店铺商品列表', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'ranking',
      component: () => import('@/views/goods/PageGoodRanking.vue'),
      meta: { title: '浏览情况分析', noClose: false, showInMenuFlag: true }
    },

  ]
}
