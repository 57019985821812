<template>
  <div class="upload-box">
    <a-spin :spinning="uploading">
      <input
        ref="fileRef"
        class="ipt-file"
        type="file"
        accept="image/*"
        @change="handleFileChange"
      />
      <div class="btn-upload" v-if="!picUrl">
        <div>
          <div class="icon-box">
            <icon-svg class="icon" icon-class="camera" />
          </div>
          <p>上传或拍摄行驶证（正面）自动识别</p>
        </div>
      </div>
      <div class="img-box" v-else>
        <img class="upload-img" :src="picUrl" alt="">
      </div>
      <p class="tip"><icon-svg class="icon" icon-class="warn" />调整拍摄角度，避免反光影响，确保图像清晰，可提高识别率</p>
    </a-spin>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { service } from '@/utils/request'
import { Dialog } from 'vant'
import 'vant/lib/dialog/style'
import ossUpload from '@/utils/oss'

export default {
  name: 'CardVerify',

  setup (props, { root, emit }) {
    const picUrl = ref('')
    const fileRef = ref(null)
    const uploading = ref(false)
    async function handleFileChange () {
      if (!fileRef.value.files.length) return
      const formData = new FormData()
      formData.append('file', fileRef.value.files[0])
      try {
        uploading.value = true
        const path = await ossUpload(fileRef.value.files[0])
        const { code, msg, data } = await service.postForm('/xindlCustomer/driving/license', { path: `${path}/compress` })
        if (code === '00000') {
          picUrl.value = `${process.env.VUE_APP_IMG}${path}`
          emit('change', { ...(data || {}), drivingPic: path })
        } else {
          Dialog.alert({
            message: msg || '识别错误'
          })
        }
      } catch (e) {
        console.error(e)
        root.$message.warn('上传失败')
      } finally {
        uploading.value = false
      }
    }

    return {
      picUrl,
      fileRef,
      uploading,
      handleFileChange
    }
  }
}
</script>

<style lang="less" scoped>
.upload-box {
  position: relative;
  width: 100%;
  overflow: hidden;
  ::v-deep .ant-spin-nested-loading,
  ::v-deep .ant-spin-container {
    width: 100%;
    height: 100%;
  }
  .img-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 164px;
    .upload-img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .btn-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 164px;
    font-size: 15px;
    color: #68A6D2;
    text-align: center;
    line-height: 40px;
    background-color: #DFF2FC;
    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      margin: 0 auto;
      border-radius: 50%;
      background-color: #68A6D2;
      .icon {
        font-size: 24px;
        color: #fff;
      }
    }
  }
  .tip {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 15px;
    font-size: 11px;
    color: #F39800;
    background-color: #FFFBE4;
    .icon {
      margin-right: 5px;
      margin-bottom: 1px;
    }
  }
  .ipt-file {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
</style>
