import { service } from '@/utils/request'

export default {
  getHelpGroup () {
    return service.get('/help/list')
  },
  getHelpDoc (helpClassId) {
    return service.get('/help/doc/list', {
      params: { helpClassId }
    })
  },
  getHelpDocDetail (helpDocId) {
    return service.get('/help/doc', {
      params: { helpDocId }
    })
  }
}
