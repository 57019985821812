/*
 * @Author: your name
 * @Date: 2021-09-01 20:14:14
 * @LastEditTime: 2022-01-05 20:33:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mms\src\router\modules\agent.js
 */
import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/agent',
  component: AppLayout,
  children: [
    {
      path: 'team_level',
      component: () => import('@/views/agent/PageTeamLevelManagement.vue'),
      meta: { title: '团队等级管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'member_level',
      component: () => import('@/views/agent/PageMemberLevelManagement.vue'),
      meta: { title: '会员等级管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'team',
      component: () => import('@/views/agent/PageTeamManagement.vue'),
      meta: { title: '团队管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'commission_log',
      component: () => import('@/views/agent/PageUserCommissionChangeLog.vue'),
      meta: { title: '佣金变动情况', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'withdraw',
      component: () => import('@/views/marketing/PageWithdraw.vue'),
      meta: { title: '提现审核', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'sales',
      component: () => import('@/views/agent/PageTeamSalesManagement.vue'),
      meta: { title: '业绩统计', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'management',
      component: () => import('@/views/agent/PageAgentManagement.vue'),
      meta: { title: '代理管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'parameter',
      component: () => import('@/views/agent/ParameterForm.vue'),
      meta: { title: '系统参数', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'user_card',
      component: () => import('@/views/agent/PageUserCard.vue'),
      meta: { title: '用户证件信息', noClose: false, showInMenuFlag: true }
    },
  ]
}
