import { system } from '@/api'
import asyncRoutes from '@/router/async'
import { SHOPSTAFFID, SHOPID } from '../../constants'
import { getSession } from '../../utils/session'
import { cloneDeep } from 'lodash'

const fallbackRoutes = [
  {
    path: '/404',
    name: 'Page404',
    component: () => import('@/views/error/Page404.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]
export default {
  namespaced: true,
  state: {
    routes: [],
    buttons: [],
    menuRoutes: [],
    initFlag: false
  },
  getters: {
    menus (state) {
      return generateMenus(state.menuRoutes)
    }
  },
  mutations: {
    setInitFlag (state, flag) {
      state.initFlag = flag
    },
    setRoutes (state, asyncRoutes) {
      state.routes = asyncRoutes.concat(fallbackRoutes)
    },
    setMenuRoutes (state, asyncRoutes) {
      state.menuRoutes = asyncRoutes
    },
    setButtons (state, btns) {
      state.buttons = btns
    }
  },
  actions: {
    async initRoutes ({ commit, state }) {
      let { data, code, msg } = await system.getMyMenus({
        shopStaffId: getSession(SHOPSTAFFID)
      })
      if (code !== '00000') throw new Error(msg)
      commit('setInitFlag', true)
      let resolvedRoutes = []
      if (data) {
        let filterdRoutes = data.listMenu
        flat(filterdRoutes)
        resolvedRoutes = computeRoutes(filterdRoutes, asyncRoutes)
      }
      commit('setRoutes', resolvedRoutes)
      commit('setMenuRoutes', resolvedRoutes)
      commit('setButtons', data.buttonMenu.map(item => {
        return {
          name: item.funcPath,
          id: item.mmsFuncPid
        }
      }))
      return state.routes
    }
  }
}
function computeRoutes (serverRoutes, asyncRoutes) {
  serverRoutes = handleCustomerMenu(serverRoutes)
  let result = []
  // 填充asyncRoutes中第一个空的子路由,并设置id供按钮权限使用
  const emptyLocalRoute = asyncRoutes.find(x => !x.path)
  if (emptyLocalRoute) {
    const { meta, ...others } = emptyLocalRoute
    result.push({
      ...others,
      meta: {
        ...meta,
        id: serverRoutes.length ? serverRoutes[0].pid : ''
      }
    })
  }
  for (let serverRoute of serverRoutes) {
    let localRoute = asyncRoutes.find(x => {
      let path = x.path.replace(/:(.+?)(?=$|\/)/g, function (match, first) {
        return '[^/]+?'
      })
      return new RegExp(`/${path[0] === '/' ? path.slice(1) : path}$`).test(serverRoute.path)
    })
    if (!localRoute) continue
    if (serverRoute.children && serverRoute.children.length && localRoute.children && localRoute.children.length) {
      let { children, ...others } = localRoute
      result.push({
        ...others,
        path: localRoute.path,
        children: computeRoutes(serverRoute.children, localRoute.children),
        meta: {
          ...localRoute.meta,
          title: serverRoute.name,
          icon: serverRoute.icon,
          id: serverRoute.id,
          serverPath: serverRoute.path,
          pid: serverRoute.pid
        }
      })
    } else {
      let { ...others } = localRoute
      if (localRoute.children && localRoute.children.length) {
        let meta = Object.assign(localRoute.children[0].meta, {
          ...localRoute.meta,
          title: serverRoute.name,
          icon: serverRoute.icon,
          id: serverRoute.id,
          serverPath: serverRoute.path,
          pid: serverRoute.pid
        })
        localRoute.children[0].meta = meta
      }
      result.push({
        ...others,
        path: localRoute.path,
        meta: {
          ...localRoute.meta,
          title: serverRoute.name,
          icon: serverRoute.icon,
          id: serverRoute.id,
          serverPath: serverRoute.path,
          pid: serverRoute.pid
        }
      })
    }
  }
  return result
}

// 针对指定店铺展示“客户管理”菜单
function handleCustomerMenu (serverRoutes) {
  let newArr = cloneDeep(serverRoutes)
  const hasEmployee = serverRoutes.find(x => x.name === '账号管理')
  if (process.env.VUE_APP_SHOPID && process.env.VUE_APP_SHOPID.indexOf(getSession(SHOPID)) >= 0 && serverRoutes.filter(x => x.pid === '0').length) {
    newArr.splice(hasEmployee ? serverRoutes.findIndex(x => x.name === '账号管理') : serverRoutes.length - 2, 0, {
      icon: 'menu-customer',
      path: '/customer',
      name: '客户管理',
      type: 1,
      id: '123456',
      pid: '0'
    })
    return newArr
  } else {
    return serverRoutes
  }
}

function generateMenus (routes) {
  let menus = []
  for (let route of routes) {
    menus.push({
      ...route.meta,
      children: route.children && route.children.length ? route.children.filter(child => {
        return child.meta.showInMenuFlag
      }).map(child => ({ ...child.meta })) : []
    })
  }
  return menus
}
function flat (routes) {
  for (let route of routes) {
    if (!route.children) continue
    for (let sub of route.children) {
      if (!sub.children) continue
      // level 3
      for (let ss of sub.children) {
        route.children.push(ss)
      }
    }
  }
}
