<template>
  <a-upload
    :class="{'upload-disabled': disabled}"
    :multiple="max !== 1"
    :fileList="fileListUpload"
    :disabled="disabled"
    :beforeUpload="beforeUpload"
    :remove="handleRemove"
    :accept="accept"
  >
    <a-button :loading="uploading || loading" type="primary" v-if="!disabled">{{btnText}}</a-button>
  </a-upload>
</template>

<script>
import { common } from '@/api'
import { reactive, toRefs, computed } from '@vue/composition-api'
import { message } from 'ant-design-vue'

export default {
  name: 'BaseUploadFile',

  props: {
    fileList: Array,
    disabled: Boolean,
    max: {
      type: Number,
      default: 0
    },
    btnText: {
      type: String,
      default: '上传'
    },
    showFiles: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'All'
    }
  },

  setup (props, { emit }) {
    const state = reactive({
      fileListUpload: computed(() => props.showFiles ? props.fileList.map(x => ({
        uid: `${new Date().getTime()}${Math.random()}`,
        url: `${process.env.VUE_APP_IMG}${x.url}`,
        status: 'done',
        name: x.name
      })) : []),
      messageFlag: 0,
      uploading: false
    })

    function beforeUpload (file, fileList) {
      state.messageFlag++
      if (state.messageFlag === fileList.length) {
        if (props.max > 0 && fileList.length + props.fileList.length > props.max) {
          message.warn(`最多允许上传${props.max}个文件`)
        } else {
          uploadFile(fileList)
        }
        state.messageFlag = 0
      }
      return false
    }

    async function uploadFile (fileList) {
      const formData = new FormData()
      fileList.forEach(x => {
        formData.append('file', x)
      })
      state.uploading = true
      let { code, data } = await common.uploadFileMultiple(formData)
      state.uploading = false
      if (code === 0) {
        let uploadFiles = data.map((x, i) => ({
          url: x,
          name: fileList[i].name
        }))
        let newVal = props.max === 1 ? uploadFiles : [...props.fileList, ...uploadFiles]
        emit('update:fileList', newVal)
        emit('change', newVal, props.fileList)
      } else {
        message.warn('上传失败')
      }
    }

    function handleRemove ({ url }) {
      emit('update:fileList', props.fileList.filter(x => url.indexOf(x.url) <= 0))
    }

    return {
      ...toRefs(state),
      beforeUpload,
      handleRemove
    }
  }
}
</script>

<style lang="less" scoped>
.upload-disabled {
  ::v-deep .anticon-close {
    display: none;
  }
}
</style>
