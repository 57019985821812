import { SHOPID } from '@/constants'
import { service } from '@/utils/request'
import { getSession } from '@/utils/session'

export default {
  communityGroupSave (payload) {
    return service.post('/communityGroup/save', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  getCommunityGroupList (payload) {
    return service.get('/communityGroup/list', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  getCommunityGroupProducts (payload) {
    return service.get('/communityGroup/pageProduct', {
      params: payload
    })
  },
  deleteCommunityGroup (payload) {
    return service.postForm('/communityGroup/del', payload)
  },
  getChooseProducts (payload) {
    return service.get('/communityGroup/pageProductForCreate', {
      params: { ...payload, shopId: getSession(SHOPID), }
    })
  },
  bindProduct (payload) {
    return service.postForm('/communityGroup/bindProduct', payload)
  },
  removeProduct (payload) {
    return service.postForm('/communityGroup/removeProduct', payload)
  },
  changeCommunityGroup (payload) {
    return service.postForm('/communityGroup/change', payload)
  }
}
