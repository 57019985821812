export const TOKEN = 'TOKEN'
export const NICKNAME = 'NICKNAME' // 用户名
export const SHOPUSERID = 'SHOPUSERID' // 用户id
export const SHOPID = 'SHOPID' // 当前店铺Id
export const PHONE = 'PHONE' // 用户登录账户

export const SHOPAUTHSTATE = 'SHOPAUTHSTATE' // 当前店铺 状态

export const SHOPSTAFFID = 'SHOPSTAFFID' // 当前用户在 当前店铺的 员工Id
export const SHOPROLEID = 'SHOPROLEID' // 选择店铺后的当前 角色 id

export const NOTEMPLATEINTROFLAG = 'NOTEMPLATEINTROFLAG' // 装修模版使用须知弹框  不再提醒 true(不再提醒)
