<template>
  <div class="select-shop-page">
    <app-header />
    <div class="page-content">
      <ShopInfoForm :shopId="id" />
    </div>
  </div>
</template>
<script>
import AppHeader from '../views/layout/AppHeader'
import ShopInfoForm from '@/components/ShopInfoForm'
export default {
  name: 'CreateShop',
  components: {
    AppHeader,
    ShopInfoForm
  },
  data () {
    return {
      id: ''
    }
  },
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    }
  }
}
</script>

<style lang="less" scoped>
.select-shop-page {
  width: 900px;
  margin: 0 auto;
  ::v-deep .ant-upload {
    padding: 0;
  }
  ::v-deep .header {
    box-shadow: none;
  }
  .page-content {
    min-height: calc(100vh - 96px);
    margin: 0 30px 32px;
    padding: 16px 0;
    box-sizing: border-box;
    border-top: 1px solid #ddd;
  }
}
</style>
