import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/purchase',
  component: AppLayout,
  children: [
    {
      path: 'batch_market',
      component: () => import('@/views/purchase/PageBatchMarket.vue'),
      meta: { title: '批发市场', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'detail/:productId',
      component: () => import('@/views/purchase/PageBatchDetail.vue'),
      props: true,
      meta: { title: '批发详情', noClose: false, showInMenuFlag: false, noCache: false }
    },
    {
      path: 'buy',
      component: () => import('@/views/purchase/PageBatchBuyOrder.vue'),
      meta: { title: '批发进货', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'sell',
      component: () => import('@/views/purchase/PageBatchSellOrder.vue'),
      meta: { title: '批发发货', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'batch_order_detail/:shopOrderId',
      props: true,
      component: () => import('@/views/purchase/PageBatchOrderDetail.vue'),
      meta: { title: '订单详情', noClose: false, showInMenuFlag: false, noCache: true }
    },
    {
      path: 'goods_batch_detail/:productId',
      component: () => import('@/views/purchase/PageBatchDetail.vue'),
      props: true,
      meta: { title: '商品批发详情', noClose: false, showInMenuFlag: false, noCache: false }
    },
    {
      path: 'service_apply/:orderId/:orderItemId',
      props: true,
      component: () => import('@/views/purchase/PageRightProtection'),
      meta: { title: '售后申请', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'service_detail/:returnId/:operationFlag?',
      props: true,
      component: () => import('@/views/purchase/PageRightProtectionDetail'),
      meta: { title: '售后详情', noClose: false, showInMenuFlag: false }
    },

  ]
}
