import router from '../router'
export default {
  install (Vue, options) {
    const bus = new Vue()
    Vue.prototype.$bus = bus
    Vue.prototype.$closeCurrentView = path => {
      bus.$emit('closeCurrentView')
      if (path) {
        setTimeout(() => {
          router.push(path)
        })
      }
    }
  }
}
