import { service } from '@/utils/request'

export default {
  login (payload) {
    return service.post('/login', payload)
  },
  register (payload) {
    return service.post('/register/register', payload)
  },
  forgetPwd (payload) {
    return service.postForm('/login/forgetPwd', payload)
  },
  getVerifyCode () {
    return service.get('/login/captchaImage')
  },
  getUserInfo (payload) {
    return service.get('/login/user', {
      headers: payload.headers,
      params: payload.params
    })
  },
  changePwd (payload) {
    return service.postForm('/login/changePwd', payload)
  },
  getMyShopList () {
    return service.get('/shop/listShop')
  }
}
