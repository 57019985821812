<template>
  <a-modal v-model="visible" title="核销订单" width="500px" :maskClosable="false" :confirm-loading="confirmLoading" @ok="handleOk">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="验证码" prop="verifyCode">
        <a-input v-model="form.verifyCode" placeholder='请输入核销验证码' />
      </a-form-model-item>
      <a-form-model-item label="总核销数量">
        <div>{{orderData.totalWriteOffNum}}</div>
      </a-form-model-item>
      <a-form-model-item label="未核销数量">
        <div>{{orderData.totalWriteOffNum - orderData.writeOffNum}}</div>
      </a-form-model-item>
      <a-form-model-item label="本次核销数量" prop="writeOffNum">
        <a-input v-model="form.writeOffNum" placeholder='请输入核销数量' style='width:100px'/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { ref, computed, watch, reactive, toRefs } from '@vue/composition-api'
import { order } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
const checkInteger = (rule, value, callback) => {
  if (value) {
    const reg = /^[1-9]*[1-9][0-9]*$/
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('正整数'))
    }
  } else {
    callback()
  }
}

export default {
  name: 'DialogWriteOff',

  props: {
    show: Boolean,
    orderData: Object,
  },

  setup (props, { root, emit }) {
    const visible = computed({
      get: () => props.show,
      set: (val) => {
        emit('update:show', val)
      },
    })
    const state = reactive({
      form: {
        verifyCode: '',
        writeOffNum: 1,
      },
      totalWriteOffNum: 0,
      confirmLoading: false,
      rules: {
        verifyCode: [{ required: true, message: '请输入', trigger: 'change' }],
        writeOffNum: [
          { required: true, message: '请输入', trigger: 'change' },
          { validator: checkInteger, trigger: 'blur' },
        ],
      },
    })
    const ruleForm = ref(null)
    watch(() => props.orderData, data => {
      state.form = {
        verifyCode: '',
        writeOffNum: 1,
      }
    }, { immediate: true, deep: true })

    function handleOk () {
      ruleForm.value.validate(async (valid) => {
        if (valid) {
          state.confirmLoading = true
          const { code, msg } = await order.deliveryWriteOff({
            ...state.form,
            shopId: getSession(SHOPID),
            orderInfoId: props.orderData.orderInfoId
          })
          state.confirmLoading = false
          if (code === '00000') {
            root.$message.success('操作成功')
            emit('success')
          } else {
            root.$message.error(msg || '操作失败，请重试')
          }
        }
      })
    }
    return {
      ruleForm,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      visible,
      ...toRefs(state),
      handleOk
    }
  },
}
</script>

<style lang="less" scoped>
::v-deep .ant-form-item {
  margin-bottom: 0px;
}
</style>
