<template>
  <div class="base-tabs">
    <div class="tabs-content">
      <a
        v-for="(tab, i) in tabs"
        :key="tab.value"
        href="javascript:;"
        :class="['tab-item', {active: active ? tab.value === active : !i}]"
        @click="$emit('change', tab.value)"
      >
        {{tab.title}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',

  props: {
    tabs: Array,
    active: [String, Number]
  }
}
</script>

<style lang="less" scoped>
.base-tabs {
  display: flex;
  margin-bottom: 14px;
  border-bottom: 1px solid #DCDFE6;
  .tabs-content {
    border: 1px solid #DCDFE6;
    border-bottom: none;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    clear: both;
    .tab-item {
      position: relative;
      float: left;
      height: 32px;
      padding: 0 30px;
      border-right: 1px solid #DCDFE6;
      font-size: 14px;
      color: #666;
      line-height: 32px;
      background-color: #F3F6F8;
      &:last-child {
        border: none;
      }
      &.active {
        background-color: transparent;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1px;
          z-index: 1;
          height: 1px;
          background-color: #fff;
        }
      }
    }
  }
}
</style>
