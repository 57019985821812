import { shop } from '@/api'
import { SHOPAUTHSTATE, SHOPID, SHOPSTAFFID } from '../../constants'
import { getSession, setSession } from '../../utils/session'
export default {
  namespaced: true,
  state: {
    shopInfo: {}
  },
  getters: {
    getShopInfo (state) {
      return state.shopInfo
    }
  },
  mutations: {
    setShopInfo (state, data) {
      state.shopInfo = data
    }
  },
  actions: {
    async getShopInfoRequest ({ commit }) {
      let { code, data } = await shop.getShopInfo({
        shopId: getSession(SHOPID),
        shopStaffId: getSession(SHOPSTAFFID)
      })
      if (code === '00000') {
        if (data.authState === undefined) {
          data.authState = 0
        }
        setSession(SHOPAUTHSTATE, data.authState)
        commit('setShopInfo', data)
      }
    }
  }
}
