export default {
  functional: true,
  name: 'base-table',
  render: function (createElement, context) {
    let { attrs } = context.data
    let { current, size, ...others } = attrs.pagination
    context.data.attrs = context.data.props = {
      ...attrs,
      pagination: {
        current: current || 1,
        size: 'small',
        showTotal: total => `共 ${total} 条`,
        pageSize: size || 10,
        total: 0,
        ...others
      }
    }
    return createElement('a-table', context.data, context.children)
  }
}
