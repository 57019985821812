import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/logistics',
  component: AppLayout,
  children: [
    {
      path: 'template',
      component: () => import('@/views/shop/PageTemplate.vue'),
      meta: { title: '物流模板', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'template/add',
      component: () => import('@/views/shop/PageTemplateForm.vue'),
      meta: { title: '新建物流模板', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'template/edit/:id',
      component: () => import('@/views/shop/PageTemplateForm.vue'),
      meta: { title: '修改物流模板', noClose: false, showInMenuFlag: false }
    }
  ]
}
