import { service } from '@/utils/request'

export default {
  getCustomerPage (payload) {
    return service.get('/xindlCustomer/page', {
      params: payload
    })
  },
  updateCustomer (payload) {
    return service.post('/xindlCustomer/save', payload)
  },
  deleteCustomer (xindlCustomerId) {
    return service.postForm('/xindlCustomer/delete', { xindlCustomerId })
  },
  getWarrantyCards (payload) {
    return service.get('/xindlCustomer/getWarrantyCards', {
      params: payload
    })
  },
  exportCustomer (payload) {
    return service.postForm('/xindlCustomer/exportExcel', payload, {
      responseType: 'blob'
    })
  }
}
