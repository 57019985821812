<template>
  <div class="table-box">
    <div class="btn-box" v-show="$slots.btns">
      <slot name="btns"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseTableBox'
}
</script>

<style lang="less" scoped>
.table-box {
  border: 1px solid #e8e8e8;
  background-color: #fff;
  .btn-box {
    padding: 10px 20px;
    .ant-btn {
      margin-right: 10px;
    }
  }
}
</style>
