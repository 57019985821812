import { SHOPSTAFFID } from '@/constants'
import { service } from '@/utils/request'
import { getSession } from '@/utils/session'
export default {
  getMySupplerPage (payload) {
    return service.get('/shopFollow/page', {
      params: {
        ...payload,
        shopStaffId: getSession(SHOPSTAFFID)
      }
    })
  },
  unBindRelation (payload) {
    return service.post('/shopFollow/save', payload)
  },
  getPostImg (payload) {
    return service.get('/shopFollow/createActivityQrCode', {
      params: payload
    })
  },
  getLevelData (payload) {
    return service.get('/shopFollow/get', {
      params: payload
    })
  },
  bindRelation (payload) {
    return service.post('/shopFollow/bindRelation', payload)
  },
  registerBindRelation (payload) {
    return service.post('/shopFollow/registerBindRelation', payload)
  },
  supplyLogin (payload) {
    return service.post('/staffDevelop/supplyLogin', payload)
  },
  supplyRegister (payload) {
    return service.post('/staffDevelop/supplyRegister', payload)
  }
}
