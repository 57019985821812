import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/content',
  component: AppLayout,
  children: [
    {
      path: 'community',
      component: () => import('@/views/content/PageCommunityList.vue'),
      meta: { title: '社群管理', noClose: false, showInMenuFlag: true, noCache: false }
    },
    {
      path: 'advert',
      component: () => import('@/views/content/PageAdvert.vue'),
      meta: { title: '弹窗广告', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'resource',
      component: () => import('@/views/content/PageResource.vue'),
      meta: { title: '素材管理', noClose: false, showInMenuFlag: true }
    }
  ]
}
