/*
 * @Author: your name
 * @Date: 2021-09-27 21:19:24
 * @LastEditTime: 2022-01-06 19:58:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \mms\src\api\modules\agent.js
 */
import { service } from '@/utils/request'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  getParameter () {
    return service.get('/bean/param/getParameter', {
      params: {
        shopId: getSession(SHOPID),
      }
    })
  },
  saveParameter (payload) {
    return service.post('/bean/param/saveParameter', {
      shopId: getSession(SHOPID),
      ...payload
    })
  },
  // 会员列表
  getMemberPage (params) {
    return service.get('/user/bean/user/page', {
      params
    })
  },
  // 充值
  async chargeMoney (payload) {
    const { code, msg } = await service.post('/user/bean/recharge', payload)
    if (code !== '00000') throw new Error(msg)
  },
  // 充值变动记录
  getChargeAccountHistoryPage (params) {
    return service.get('/user/bean/page', {
      params
    })
  },
}
